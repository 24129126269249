import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import React, { Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loginpage from './page-login/login';
import Product from './page-product/Product';

function App() {
 

  document.body.dir="rtl";

  
  return (
    <div className="App">
        <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes >
        <Route path="/" element={<Loginpage />}  />
        <Route path="/Product"  element={<Product  />
    
   } />
      </Routes>
    </Suspense>
  </Router>
 </div>
  );
}

export default App;
