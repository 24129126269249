import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { RiUserFill } from "react-icons/ri";
import { RiLockPasswordFill } from "react-icons/ri";
import React, { lazy, useState } from 'react';
import "./login.css";
import axios from "axios";
import { useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import {useRef} from 'react';
import Swal from "sweetalert2";

function Loginpage() {
  //function to remember me 
  const [checked,setChecked]=useState()
  function lsRememberMe(){
    if (ref.current.checked){
      
      localStorage.setItem('user_name',user_name)
      localStorage.setItem('user_Password',password)
      localStorage.setItem('rememberMe',ref.current.checked) 
     // localStorage.setItem('rememberMe',ref) 
       
    }
    else{
      localStorage.clear()
    }
    
  }
  const ref = useRef(null)


  ////to save user in local storage
  useEffect(() => {
   
    setuser_name(localStorage.getItem('user_name'));
    setpassword(localStorage.getItem('user_Password'));
    // settimelogin(localStorage.getItem('timelogin'))
    
    setChecked(localStorage.getItem('rememberMe',ref.current.checked) ) ;
 
     
  }, []);

//user of login
  const LoginUser={
    user_Name:'',
    user_Password:'',
    hoursoflogin:""
  }

  const[user_name,setuser_name]=useState()

  //to input username
const handleChangeusername = event => {
  setuser_name (event.target.value);
};

  //to input password
const[password,setpassword]=useState()
const handleChangepassword = event => {
  setpassword (event.target.value);

};



// const [timelogin, settimelogin] = useState(now);




const[User,setUser]=useState(JSON.parse(localStorage.getItem('User')))

//function of login
const[lodinglogin,setlodinglogin]=useState(false)

  const [ loginperson , Setlogin]=useState();
  const login = () => {
  
    LoginUser.user_Name=user_name;
    LoginUser.user_Password=password;
    const now = new Date();
    LoginUser.hoursoflogin=now
 setlodinglogin(true)

    window.setTimeout(function () { setlodinglogin(false)}, 3000)

    axios.post("https://tijaryapis.azurewebsites.net/api/User/Login",LoginUser)
           .then((response) => {
            Setlogin(response.data);
            console.log(response.data)
            lsRememberMe()

            if(response.data.isSuccess == true){
               localStorage.setItem("User", JSON.stringify(response.data));
              //  console.log("tiiime",timelogin)
              setlodinglogin(false)

              localStorage.setItem("timelogin", JSON.stringify(now));

              window.location.href = '/Product'
            }
          else{
            Swal.fire({
              icon: 'error',
              className:"fontmaaray",
              title: 'خطا في الرقم السري او اسم المستخدم',
              confirmButtonText: false,
              showConfirmButton:false,
              showCancelButton: true,
              cancelButtonText:"اعاده المحاوله",
              customClass:{
                confirmButton:'custombuttonsweet',
                title:'customtitlesweet',
               text:'customtextsweet'
              }
            }) .then(() => {
              window.location.href = "/"
              })
       
          }
      })
      .catch(error => {
        return error;
      });
  
   }
   
    const About = lazy(() => import('../page-product/Product'));
    var eye = document.getElementById("password");
    const [passwordType, setPasswordType] = useState("password");

    function myFunction() {
      if (passwordType === "password") {
        setPasswordType  ("text");
      } else {
        setPasswordType("password");
      }
    }

    const handlePress = e => {
      if(e.key === 'Enter') { 
       console.log("Press Enter",user_name,password)
       login(user_name,password)
      }
     }
    return (
      <div className="container-fluid fontmaaray "  style={{"color": "white" }} onKeyDown={handlePress}  >
       <div className='row justify-content-center'>

       <div className='col-lg-8 col-12 col-md-8  cardoflogin card' style={{"background":"#F8F8F8","color":"black"}}> 
       <div className='col-12 mt-3'>
     <img style={{"height":"100px"}} src={require("../image/tijarylogo.png")}></img>
     </div>
       <div className='row mt-3'>
          <h5 className='text-center fontmaaray' style={{color:"#e99221"}}>
            تسجيل دخول
          </h5>
          
        </div>
        <form className="row">
            <div className='row justify-content-center '>
              {/* <label for="user_name" className='col-12 '>user_name</label> */}
           <RiUserFill className='col-1 iconofuser' style={{color:"rgb(245, 105, 2)"}}> </RiUserFill>
            <input id="user_name" value={user_name} onKeyDown={handlePress} onChange={handleChangeusername} type={'text'} className='col-10 fontmaaray' placeholder='اسم المستخدم' style={{"border":"none","background":"#F8F8F8",    
            "font-size":"15px"}}></input>
            </div>
            <div className='row justify-content-center mt-2'> 
            <hr className='col-11'></hr>
            </div>
           <div className='row justify-content-center mt-2 '>
       
           <RiLockPasswordFill className='col-1 iconofuser'  style={{color:"rgb(245, 105, 2)"}}></RiLockPasswordFill>
            <input value={password} id="password" onKeyDown={handlePress} onChange={handleChangepassword} type={passwordType} className='col-10 col-lg-9 col-md-9 fontmaaray' placeholder='كلمه المرور'style={{"border":"none","background":"#F8F8F8","font-size":"17px"}}></input>
          { passwordType=="password"?           
           <AiFillEyeInvisible  onClick={myFunction} className='col-1  iconofuser eyeofpass'  style={{color:"rgb(245, 105, 2)"}}></AiFillEyeInvisible>
           :<AiFillEye className='col-1 iconofuser eyeofpass'  style={{color:"rgb(245, 105, 2)"}} onClick={myFunction}></AiFillEye>}
           </div>
           <div className='row justify-content-center mt-2'> 
            <hr className='col-11'></hr>
            </div>
            <div className='row justify-content-center'>
     <div className='col-lg-1 col-md-2 col-2 '>
       <input type='checkbox' className="checkboxinput" style={{}}  ref={ref} id="rememberMe"></input>
  </div>
    <div className='col-lg-3 col-md-7 col-7'  style={{margin:"0px",padding:"0px","text-align":"initial"}}>
   <label for='rememberMe' name="rememberMe"> <span className='fontmaaray spantext' >  حفظ بيانات الدخول</span></label>
   </div>
   </div>
           <div className='row justify-content-center card-body'>

           <a onKeyDown={login} onClick={login} className='btn col-6 rounded-5 text-white fontmaaray' style={{background:"#F56902"}} >دخول </a>
           </div>
        </form>
       </div>
       </div>
       {lodinglogin==true? 
           <div className='row justify-content-center sapnnerlogin' >
           <div className='col-lg-8 col-md-8 col-12 card'>
           <div class="loader_body">
        <div class="circle_parent">
            <div class="circle1">
              
            </div>
     
            
            {/* <h4>Loading ....</h4> */}
        </div>
    </div>
           
           </div>
               </div>
       
       
       :""}

      </div>
    );
  }
  
  export default Loginpage;