import axios from "axios";
import Webcam from "react-webcam";
import React,  { useEffect, useRef } from "react";
import { useState } from 'react';
import "./product.css";
import { FiSend } from "react-icons/fi";
import { HiHome } from "react-icons/hi";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { FiLogOut } from "react-icons/fi";
import Popup from 'reactjs-popup';
import { AiOutlineUnorderedList } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import moment from 'moment';
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineInfoCircle} from "react-icons/ai";
import { AiFillPrinter} from "react-icons/ai";
import { RiUserSearchLine } from "react-icons/ri";
import { AiOutlineUserAdd} from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
// import { BiChair } from "react-icons/bi";
import { TbReportSearch } from "react-icons/tb";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdSyncLock } from "react-icons/md";
import Swal from "sweetalert2";
import { IoRefreshOutline } from "react-icons/io5";
import { slide as Menu } from 'react-burger-menu';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineArrowRight} from "react-icons/ai";
import { AiOutlineArrowLeft} from "react-icons/ai";
import { AiOutlineWhatsApp} from "react-icons/ai";
import { AiOutlineMore} from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";

import { AiOutlineMail} from "react-icons/ai";
import { AiOutlineClockCircle} from "react-icons/ai";
import { TbWeight } from "react-icons/tb";
import { BiBarcodeReader } from "react-icons/bi";

// import { BarcodeScanner } from 'react-barcode-detection';
import { BsInfoSquareFill } from "react-icons/bs";
import { BiCommentAdd } from "react-icons/bi";
import { BsQrCodeScan } from "react-icons/bs";
// import QrReader from 'react-qr-scanner'

import { MdTableBar } from "react-icons/md";
import { FaRegCommentDots } from "react-icons/fa";
import MicRecorder from './mic-recorder';
import {FaMicrophone} from "react-icons/fa";
import { BsMic } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { BsTrash3 } from "react-icons/bs";
import { BiMenuAltRight } from "react-icons/bi";

import { BiDownArrow } from "react-icons/bi";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { id } from 'date-fns/locale';

import { useZxing } from "react-zxing";
import { BrowserMultiFormatReader, Result,BarcodeFormat,DecodeHintType } from "@zxing/library";
import { jsPDF } from 'jspdf';
import  html2canvas from 'html2canvas';
import Barcode from 'react-jsbarcode';
import { set } from "date-fns";
import { Alert } from "bootstrap";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
function Product(){
  const [barcode, setbarcode] = useState("");
  const videoConstraints = {
    height: 150,
    facingMode:  { exact: "environment" }
  };

  const webcamRef = useRef(null);

  const [blobURL, setblobURL] = useState();
  const [RecFile, setRecFile] = useState();
  const [isRecording, setisRecording] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const start = () => {
    Mp3Recorder
      .start()
      .then(() => {
        setisRecording(true);
      }).catch((e) => {
    console.log(e);
      });
};

const hints = new Map();
const enabledFormats = [
    // ...ALL_FORMATS_WHICH_YOU_WANT_TO_ENABLE
    BarcodeFormat.UPC_A,
];
hints.set(DecodeHintType.POSSIBLE_FORMATS, enabledFormats);
const scanBarcode = () =>{
  
  const codeReader = new BrowserMultiFormatReader(hints);
  const imageSrc = webcamRef?.current?.getScreenshot();
  if (imageSrc) {
    codeReader.decodeFromImage(undefined, imageSrc)
      .then((result) => {
        setbarcode( result);

      })
      .catch((err) => {
        console.log(err);

      });
  }
}


const stop =() => {
  Mp3Recorder
    .stop()
    .getMp3()
    .then(([buffer, blob]) => {
      const blobURL = URL.createObjectURL(blob)
      setisRecording(false);
      setblobURL(blobURL);
      handleupload(blobURL);
    }).catch((e) => console.log(e));
};

const handleupload= async(blobURL)=>{
  const audioBlob = await fetch(blobURL).then((r) => r.blob());
  var fileuplod=new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' })
   setRecFile(fileuplod)
  console.log(fileuplod)
}
    function resetaudio(){
      const y=document.getElementById('audio')
      if(y.src={blobURL}){
        y.src='none'
      }

    }
    // title: ".شكرا علي تسجيل الرسالة",
    // text: "يمكن الحذف و التعديل قبل الارسال",
    const handleClick = (event) => {
      event.preventDefault();
      setisRecording(true);
      start()
      setIsActive(current => !current);
    };
    function alert(){
      Swal.fire({
        title: '. شكرا علي تسجيل الرسالة ',
        text:"يمكن الحذف والتعديل قبل الأرسال",
        confirmButtonText: 'استمرار',
        showCancelButton: true,
        cancelButtonText:"الغاء",
        customClass:{
          confirmButton:'custombuttonsweet',
          title:'customtitlesweet',
         text:'customtextsweet'
        }
      })

    .then((willDelete) => {
      if (willDelete) {

        stop();
        setisRecording(false);

      } else {
        stop();
        setisRecording(false);
      }
    });
  }

  const handleMessageChange = event => {
    // 👇️ access textarea value
   const x=event.target.value
    // props.setcomment(x);
    console.log(x)
  };

  const now = new Date();
  const hoursAndMinutes =  now.toLocaleTimeString('en-US', {  //date hours , minutes

    hour: '2-digit',
    minute: '2-digit',
  });
  const timetoday =  now.toLocaleTimeString('ar-EG', {  //date hours , minutes

    hour: '2-digit',
    minute: '2-digit',
  })

const toDay= new Date().toISOString().substring(0, 10);// date day , month



const [ exitpopup, setExitPopup]=useState(true) //to show popup
const [DiffTime, setDiffTime]=useState() //to show popup


   const handleClick2 = (e) => {//stop refresh page
    e.preventDefault();
  };

  const [inputofsearch,setinputofsearch]=useState("")
  const handleChangeinputsearch = event => {  // to set the input search of سجل العملاء
 setinputofsearch (event.target.value);
    console.log(event.target.value)

   };
   const[customerdata,setcustomerdata]=useState(false)

  const [customersearch,setcustomersearch]=useState()
  const[loadingsearchcust,setloadingsearchcust]=useState(false)

  const handleSearchcustomer = (inputofsearch,searchcustomer) => {// get item from api by search  سحل العملاء
    setspanner(true)
    setcustomerrecord(true)
    setloadingsearchcust(true)

    axios.get(`https://tijaryapis.azurewebsites.net/api/Customer/getCustomer/${searchcustomer}/${inputofsearch}`,{
        headers: {"TijaryToken" : ` ${User.token}`} })
      .then((response) => {//set data return in itemlist

        setspanner(false)
        setcustomerdata(true)
        setcustomersearch(response.data.data);
        setloadingsearchcust(false)
        console.log("Customer Data",response.data.data)

        if(response.data.data.length==0){
          Swal.fire({
            title: 'لا توجد معلومات',
            confirmButtonText: 'تم'
          })
        }
      

            })
      .catch(error => {
Swal.fire("كم كبير جدا من البيانات")  
setloadingsearchcust(false)     
      });
        }



      // const [tablecustomer,settablecustomer]=useState(false)
      //   const handleOnSelect2 = (result) => {
      //     settablecustomer(true)
      //     handleClick()

      //     // when click to select item
      //     // var index=orderlist.findIndex(result=>result.id === result.id);
      // //     if(index == -1){
      // //   result.quantity=result.quantity
      // //   orderdata(result,'+')
      // //   handleClick()

      // // }

      // // else if(result.itemID === orderlist[index].itemID){

      // //   result.quantity = result.quantity + 1
      // //   orderdata(result,'+')
      // //   handleClick()
      // // }
      // // else{
      // //   result.quantity=0
      // //   result.quantity = result.quantity + 1
      // //   orderdata(result,'+')
      // //   handleClick()
      // // }

      //  }

        // const handleOnFocus2 = () => {// when focus to result of search
        //   // console.log('Focused')
        // }

        // const formatResult2 = (result) => { //that data get by api after search
        //   console.log('formatResult')
        //   console.log(result)

        //   return (

        //     <>

        //       <div className='row'>


        //       <div className='col-9'>
        //        <p style={{ display: 'block', textAlign: 'right' }}>{result.firstName}</p>

        //        </div>
        //       </div>

        //     </>
        //   )
        // }

  const [itemlist,setitemlist]=useState()
  const [message2, setMessage2] = useState('');
  // const[textinpopupextra,settextinpopupextra]=useState('')
  const changetextinpoupextra =event=>{
    setMessage2(event.target.value)
    console.log("textextra",event.target.value)
    axios.get("https://tijaryapis.azurewebsites.net/api/Item/GetItemsByBarCodeOrName/"+event.target.value,{
      headers: {"TijaryToken" : ` ${User.token}`} })
    .then((response) => {//set data return in itemlist

      setitemlist(response.data.data);
      console.log("lenghtformatResult",response.data.data.length)
      setlengthsearchitem(response.data.data.length)

      console.log("searchitem",response.data.data)

          })
    .catch(error => {
    return error;
    });

  }
const[lengthsearchitem,setlengthsearchitem]=useState()
const[loadingsearch,setloadingsearch]=useState(false)
  const handleChangetext = event => {
    setloadingsearch(true)
    setMessage2(event.target.value);
 if(event.target.value.length==0){
  setsearchitempopup(false)
  setlengthsearchitem("")
 }
 else{
  setsearchitempopup(true)
  axios.get("https://tijaryapis.azurewebsites.net/api/Item/GetItemsByBarCodeOrName/"+event.target.value,{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {//set data return in itemlist
    setloadingsearch(false)
    setitemlist(response.data.data);
    console.log("lenghtformatResult",response.data.data.length)
    setlengthsearchitem(response.data.data.length)

    console.log("searchitem",response.data.data)

        })
  .catch(error => {
  return error;
  });
 }



    console.log('value is:', event.target.value);
  };
  // const handleOnSearch = (message2, result) => {// get item from api by search to main page
  //  console.log('value is:',message2);

  //   setsearchitempopup(true)


  //       }

  // const handleOnHover = (result) => {
  //   // the item hovered
  //   console.log("res",result.itemName)

  // }
  // const handleOnSelect2 = (result) => {
  //       settablecustomer(true)
  //       handleClick()

  //       // when click to select item
  //       // var index=orderlist.findIndex(result=>result.id === result.id);
  //   //     if(index == -1){
  //   //   result.quantity=result.quantity
  //   //   orderdata(result,'+')
  //   //   handleClick()

  //   // }

  //   // else if(result.itemID === orderlist[index].itemID){

  //   //   result.quantity = result.quantity + 1
  //   //   orderdata(result,'+')
  //   //   handleClick()
  //   // }
  //   // else{
  //   //   result.quantity=0
  //   //   result.quantity = result.quantity + 1
  //   //   orderdata(result,'+')
  //   //   handleClick()
  //   // }

  //    }
  function handleOnSelect(itemlist,ID) {// when click to select item
    console.log("itemlist",itemlist)
    if(itemlist.itemTypeId != 9){
    var index=orderlist.findIndex(itemlist=>itemlist.itemID === itemlist.itemID);
    if(index == -1){
      itemlist.quantity=itemlist.quantity
  orderdata(itemlist,'+')

}
else if(itemlist.itemID === orderlist[index].itemID){

  itemlist.quantity = itemlist.quantity + 1
  orderdata(itemlist,'+')
}
else{
  itemlist.quantity=0
  itemlist.quantity = itemlist.quantity + 1
  orderdata(itemlist,'+')
}
    }
    else{
      axios.get("https://tijaryapis.azurewebsites.net/api/Item/SelectItemRecipes/"+itemlist.itemID,{
        headers: {"TijaryToken" : ` ${User.token}`} })
      .then((response) => {//set data return in itemlist
      console.log("SelectItemRecipes",response)
      var ItmLst = []
      response.data.forEach((Item, index) => {
        var index=orderlist.findIndex(itemlist=>itemlist.itemID === Item.itemID);
        if(index == -1){
          const objorder={
            'itemID':Item.itemID,
            'quantity':Item.quantity,
          'itemName':Item.itemName,
          'salePrice':Item.salePrice,
          // 'ItemTotal':order.finalPrice,
          'total_Discount': Item.itemDiscountAmount ,
          "total": Item.itemDiscountedPrice,
          "net_Total":Item.finalPrice * Item.quantity  ,
          "ItmEqv":Item.quantity,
          "MultiPackingItemID":null,
          "SerialNumber":null,
          "VATRate":Item.vatRate,
          "SalesTax":Item.vatRate,
           "IMEINumber":null ,
           "unitName":Item.unitName,
           "finalPrice":Item.finalPrice,
          "itemDiscountAmount":Item.itemDiscountAmount,
          "isDecimal":Item.isDecimal,
          "itemComment":itemComment,
          "ItemTotal":Item.finalPrice,
          "itemImage":Item.itemImage
        }
          ItmLst.push(objorder)
    }
    else if(Item.itemID === orderlist[index].itemID){
      orderlist[index].quantity = Item.quantity + 1
      orderdata(Item,'+')
    }
    
      });
      let totalpricedel = 0;
      let totalqty=0 ;
      let totalprice=0;
      let totaldiscount=0;
      let Nettotal=0;
      const NeworderLst =[...orderlist,...ItmLst];
      setorderlist(NeworderLst)
//set te data of neworderlist and loop to that
  NeworderLst.forEach((ele)=>{
    totalqty=parseFloat(totalqty)+ parseFloat (ele.quantity);
    totalpricedel= totalpricedel+parseFloat( ele.salePrice).toFixed(2)  *parseFloat (ele.quantity).toFixed(2);
    totaldiscount=parseFloat(totaldiscount)+parseFloat(Number(ele.itemDiscountAmount) * ele.quantity).toFixed(2);
    totalprice=totalprice+ parseFloat(ele.salePrice).toFixed(2);
    Nettotal=Nettotal+parseFloat(ele.finalPrice).toFixed(2) * parseFloat(ele.quantity).toFixed(2) ;
  })
  const objtotal={
    'totalqut':parseFloat(totalqty) ,
    'totalpricedel':totalpricedel,
  'totaldiscount':totaldiscount,
  'totalprice':totalprice,
  'Nettotal':Nettotal
  }
  settotal(objtotal);
            })
      .catch(error => {
      console.log(error);
      });
    }


 }

  // const handleOnFocus = () => {// when focus to result of search
  //   // console.log('Focused')
  // }

//   const formatResult = (result) => { //that data get by api after search
//     console.log()
//     console.log('formatResult',result)


//     return (

//       <>
//         {result.itemImage =="" || result.itemImage==null ?
//      <div className='row'>
//        <div className='col-8'>
//        <div style={{display:"flex"}}>

// <div style={{width:"25px"}} >
// <img  style={{width:"25px",height:"25px"}} src={require("../image/download.png")} />

// </div>
//  <div >
//  <p style={{ display: 'block', textAlign: 'right' }} className='fontmaaray'>{result.itemName}</p>

//  </div>

//           </div>
//        </div>

//      </div>

//      :
//         <div style={{display:"flex"}}>


//         <img className='w-100' src={result.itemImage} />




//          <p style={{ display: 'block', textAlign: 'right' }} className='fontmaaray'>{result.itemName}</p>







//         </div>}

//       </>
//     )
//   }

    const [item,setitem]=useState();


    const[myData,setMyData]=useState([])

    const[User,setUser]=useState(JSON.parse(localStorage.getItem('User')))//to set token ,username,id of user
    const[timelogin,settimelogin]=useState(JSON.parse(localStorage.getItem('timelogin')))//to set token ,username,id of user
    const[message,setmessage]=useState("")
    const handleChangemessage = event => {// to comment in order
      setmessage (event.target.value);
     };

     const[itemComment,setitemComment]=useState("")
     const handleChangecommentofitem = event => {// to comment in order
      setitemComment(event.target.value);
      console.log(event.target.value)
      };

      const[lastcomment,setlastcomment]=useState(false)
      const [viewlastcomment,setviewlastcomment]=useState()

      const handleChangelastcomment =(idofitemcomment)=> {
    // to comment in order
       console.log(idofitemcomment)

       let index = orderlist.findIndex(orderlist => orderlist.itemID === idofitemcomment);

       console.log("insas",index)
       console.log(orderlist[index].itemComment)
       setitemComment(orderlist[index].itemComment)
        setlastcomment(true)
        setpopupofcommentitem(false)
        setidofitemcomment(idofitemcomment)
        };

      const[surecomment,setsurecomment]=useState("")
      const [idofitemcomment,setidofitemcomment]=useState("")
      const handleChangesurecomment =(idofitemcomment)=> {// to comment in order
        let index = orderlist.findIndex(orderlist => orderlist.itemID === idofitemcomment);
        console.log("in",index)

        orderlist[index].itemComment=itemComment

        console.log("order",orderlist[index])



       };
       const handleclosepopup =()=> {// to comment in order

        setpopupofcommentitem(false)

        setlastcomment(false)



        };
        const handledeletecommentofitem =(idofitemcomment)=> {// to comment in order
          console.log("a",idofitemcomment)

          let index = orderlist.findIndex(orderlist => orderlist.itemID === idofitemcomment);
          console.log("as",orderlist)
          orderlist[index].itemComment = " "

          console.log(idofitemcomment)



          };
       console.log(itemComment)




   const [orderlist, setorderlist] = React.useState([]);
   let index = orderlist.findIndex(orderlist => orderlist.itemID === idofitemcomment);

   console.log(orderlist)
//object of total
   const objtotal={
    'totalqut':0,
    'totalpricedel':0,
  'totaldiscount':0,
  'totalprice':0,
  'Nettotal':0
  }
    const [total, settotal] = React.useState(objtotal);
    //////////////////////function to increase button and update the value of quantity /////////////////
   /// to button + of item in category
    const ChangeQty = (Item,Sign) => {
      console.log("this is item",Item)
      // Update state with incremented value
      if(Sign == '+')
      var quantity = Item.quantity + 1;
     if(Sign == '-'){
      if(Item.quantity<1){
        var quantity = 0;
      }
      else{
        var quantity = (Item.quantity - 1).toFixed(2);
      }


     }
      else if(quantity<0){
        alert("الحد الادني")
        quantity =0;
      }

      Item.quantity = quantity;
      orderdata(Item,Sign);
    }


    ////////////////order list /////////////////
    /////////use order for orderdata////

    const orderdata = (order,Sign,commentofitem)=>{
      ///define object of order and set the value

      // onject set value of order
      const objorder={
        'itemID':order.itemID,
        'quantity':order.quantity,
      'itemName':order.itemName,
      'salePrice':order.salePrice,
      // 'ItemTotal':order.finalPrice,
      'total_Discount': order.itemDiscountAmount ,
      "total": order.itemDiscountedPrice,
      "net_Total":order.finalPrice * order.quantity  ,
      "ItmEqv":order.quantity,
      "MultiPackingItemID":null,
      "SerialNumber":null,
      "VATRate":order.vatRate,
      "SalesTax":order.vatRate,
       "IMEINumber":null ,
       "unitName":order.unitName,
       "finalPrice":order.finalPrice,
      "itemDiscountAmount":order.itemDiscountAmount,
      "isDecimal":order.isDecimal,
      "itemComment":"",
      "ItemTotal":order.finalPrice,
      "itemImage":order.itemImage


    }
    //define total to use it in final of table
    let totalpricedel = 0;
    let totalqty=0 ;
    let totalprice=0;
    let totaldiscount=0;
    let Nettotal=0;
      if(order.quantity !=0)//condition of quantaity
      {
//set the order in temporary array and slice orderlist
        let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
        if (index !== -1) {

          let temporaryarray = orderlist.slice();
//asign temporary arry of index to orderdata
          temporaryarray[index]["quantity"] = parseFloat(order.quantity);
          temporaryarray[index]["salePrice"] = order.finalPrice ;
          temporaryarray[index]["ItemTotal"] = order.finalPrice * order.quantity;
          temporaryarray[index]["total"] = order.finalPrice * order.quantity;
          temporaryarray[index]["net_Total"] = order.finalPrice * order.quantity;
          temporaryarray[index]["finalPrice"] = order.finalPrice;
          temporaryarray[index]["itemComment"] = itemComment;


//loop for temporary array to show the data
           temporaryarray.forEach((ele)=>{
        totalqty=parseFloat (totalqty)+parseFloat(ele.quantity);
        totalpricedel= totalpricedel+ parseFloat(ele.salePrice).toFixed(2)  * parseFloat(ele.quantity).toFixed(2);
        totaldiscount =parseFloat(totaldiscount)+ parseFloat(Number(ele.itemDiscountAmount) * ele.quantity).toFixed(2);
        totalprice=totalprice+parseFloat(ele.finalPrice).toFixed(2);
        Nettotal=Nettotal+parseFloat(ele.finalPrice).toFixed(2) * parseFloat(ele.quantity).toFixed(2) ;
      })
// if quantaity !=-1
      } else {
          const NeworderLst =[...orderlist,objorder];
          setorderlist(NeworderLst)
//set te data of neworderlist and loop to that
      NeworderLst.forEach((ele)=>{
        totalqty=parseFloat (totalqty)+ parseFloat (ele.quantity);
        totalpricedel= totalpricedel+parseFloat( ele.salePrice).toFixed(2)  *parseFloat (ele.quantity).toFixed(2);
        totaldiscount=parseFloat(totaldiscount)+parseFloat(Number(ele.itemDiscountAmount) * ele.quantity).toFixed(2);
        totalprice=totalprice+ parseFloat(ele.salePrice).toFixed(2);
        Nettotal=Nettotal+parseFloat(ele.finalPrice).toFixed(2) * parseFloat(ele.quantity).toFixed(2) ;
      })

      }
//create object ototal and  set total to object total
      const objtotal={
        'totalqut':parseFloat(totalqty) ,
        'totalpricedel':totalpricedel,
      'totaldiscount':totaldiscount,
      'totalprice':totalprice,
      'Nettotal':Nettotal
      }
      settotal(objtotal);
      }
      else // when quantity = 0
      {
             let copy = [...orderlist]
             copy =copy.filter((Item,id) => order.itemID != Item.itemID )
             setorderlist(copy)
         copy.forEach((ele)=>{
           totalqty=parseFloat(totalqty)+parseFloat (ele.quantity);
           totalpricedel= totalpricedel+parseFloat (ele.salePrice).toFixed(2) * parseFloat (ele.quantity).toFixed(2) ;
           totaldiscount =parseFloat(totaldiscount) +parseFloat(ele.itemDiscountAmount).toFixed(2) * ele.quantity;
           totalprice=totalprice+parseFloat(ele.salePrice).toFixed(2);
           Nettotal= Nettotal+parseFloat(ele.finalPrice).toFixed(2)  * parseFloat(ele.quantity).toFixed(2);
         })
         const objtotal={
          'totalqut':parseFloat (totalqty),
          'totalpricedel':totalpricedel,
        'totaldiscount':totaldiscount * totalqty,
        'totalprice':totalprice,
        'Nettotal':Nettotal
        }
        settotal(objtotal);
        }
    }

    const btnCategoryAdd =(Item,Sign) =>{
      if(Item.itemTypeId != 9){
      let index = orderlist.findIndex(orderlist => orderlist.itemID === Item.itemID);
      Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'تم اضافه المنتج',
        showConfirmButton: false,
        timer: 900
      })
      if(index > -1)
      {
        if(Sign == '+'){
          orderlist[index].quantity = orderlist[index].quantity + 1;
        }
        else  if(Sign == '-'){
          orderlist[index].quantity = orderlist[index].quantity - 1;
        }
        Item.quantity = orderlist[index].quantity;
      }
      else{
        Item.quantity = 1;
      }
       orderdata(Item,Sign);
    }
else{
  axios.get("https://tijaryapis.azurewebsites.net/api/Item/SelectItemRecipes/"+Item.itemID,{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {//set data return in itemlist
  console.log("SelectItemRecipes",response)
        })
  .catch(error => {
  console.log(error);
  });
}


     }


    /////////////function button change quntaty////////////////
   const btnonclick=(Item,Sign) =>{
    ChangeQty(Item,Sign)
   }

////////section id ////


// payment method
const [payment,setPayment]=useState(3);
//to payment method :visa
function visapayment(){
  setPayment(1)
  document.getElementById("cash").style.background="";
  document.getElementById("cash").style.border="";

  document.getElementById("visa").style.background="#E8E8E8";
  document.getElementById("visa").style.border="1px solid rgb(245, 105, 2)"

}
//to payment method:cash
function cashpayment(){
  setPayment(3)
  document.getElementById("cash").style.background="#E8E8E8";
  document.getElementById("cash").style.border="1px solid rgb(245, 105, 2)"

  document.getElementById("visa").style.background="";
  document.getElementById("visa").style.border="";


}
/// when select the customer of search
const [customer,setcustomer]=useState("")
function passthename(Cust){
  console.log(Cust)
  setshowclient(true)
 setcustomer(Cust)
// customer.id = Cust.id
Swal.fire({
  title: 'تم اختيار العميل',
  icon: 'success',
  timer: 2000,
  confirmButtonText: 'تم'
})

setcustomerrecord(false)
}
function passthename2(Cust){
  console.log(Cust)

 setcustomer(Cust)
// customer.id =  Cust.id
Swal.fire({
  title: 'تم اضافه العميل',
  icon: 'success',
  timer: 2000,
  confirmButtonText: 'تم'
})

setnewuserpopup(false)
}



const [newuser, SetNewUser]=useState();
const [errorfsname,seterrorfsname]=useState()
const [errorlsname,seterrorlsname]=useState()
const [errorPhonenumber,seterrorPhonenumber]=useState()
const [errorAdderess,seterrorAdderess]=useState()

//add new user
const subnewuser = () => {
 if(newuserobj.phoneNumber==undefined){
    Swal.fire(
      'من فضلك ادخل الرقم',
   
    )
  }
  else if(newuserobj.customerAddresses[0].address== undefined){
  Swal.fire(
    'من فضلك ادخل العنوان',
 
  )
}

else{
  axios.post("https://tijaryapis.azurewebsites.net/api/Customer/addCustomer",newuserobj,{
    headers: {"TijaryToken" : ` ${User.token}`} })

    .then((response) => {

      console.log(response)
      if(response.data.isSuccess==true){
        Swal.fire({
          title: 'تم الأضافه',
          icon: 'success',
          timer: 4000,
          confirmButtonText: 'تم'
      })
      }
      else{
        Swal.fire({
          title: response.data.msg,
          icon: 'error',
          timer: 4000,
          confirmButtonText: 'تم'
      })
      }



            setshowclient(true)
            SetNewUser(response.data.data);
           setcustomer(response.data.data.id , response.data.data.firstName +response.data.data.lastName)

           passthename2(response.data.data)




    })

    .catch(error => {

      return error;
    });
}


 



 }

 ///to fetch tables
 const [tables,settables]=useState()
 const [sectionid,setsectionid]=useState()

 const fetchtables = (ID) => {// get item from api by search
  setsectionid(ID)

   axios.get("https://tijaryapis.azurewebsites.net/api/Tables/GetAllTablesBySectionId/"+ ID,{

     headers: {"TijaryToken" : ` ${User.token}`} })

   .then((response) => {//set data return in itemlist
     const length2 = response.data.length;

    // var index=response.data.findIndex(result=>result.id === result.id);

    //  if(response.data[index].underProcessingTableOrderCount == 1 ){
    //   const x=document.getElementsByClassName("changecolor")
    //   x.className="bg-success"
    //  }
    // else if(response.data[index].underProcessingTableOrderCount > 1 ){
    //   const x=document.getElementsByClassName("changecolor")
    //   x.style.background="red"
    //  }


//  console.log("index", response.data[index + 2].underProcessingTableOrderCount)


 setshowsection(true)
 setshowtable(true)
 if(showtable==true){

  setshowtable(true)


 }
 else{
  setshowtable(true)

 }
 console.log(response.data)

 settables(response.data)


         })
   .catch(error => {
   return error;
   });
     }
     //// when click of tables
     const [tableNo,setTableNo]=useState()
     const tablesclick = (Id) => {
      setTableNo(Id)
      setshowItable(true)
      console.log("tableiddd",Id)
    if(popupdiv==false){
      setpopupdiv(true)
    }
    else{
      setpopupdiv(false)

    }



}
function closepopoupofordertable(){
  setpopupdiv(true)


}


//////fetch opening balance
const[openingBalance,setopeningBalance]=useState()
const fetchOpeningBalance=(id)=>{
  axios.get("https://tijaryapis.azurewebsites.net/api/Waiter/GetOpeningBalance/"+id,{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {//set data return in itemlist
    setopeningBalance(response.data.id)
console.log("fsas",response.data)

})
  .catch(error => {
  return error;
  });

}
const[thenumoforderintable,setthenumoforderintable]=useState()

///// that data send when submit order
const datafrom={
    CashDiscount:total.totaldiscount,
    UserId:User.id,
    // customer.id
    CustomerID: null||customer.id,
    DeliveryEmloyeeID:null,
    WaiterID:User.id,
    TableNo:null || tableNo ,
    Notes:message,
    // commentitem:commentofitem,
    items:orderlist,
    Nettotal:total.Nettotal,
    PaymentMethod:payment,
    ItemTotal:total.Nettotal,
    total:total.Nettotal,
    openingBalId:openingBalance,
    record:blobURL,
    id:thenumoforderintable
  }

const [returnsuborder,setreturnsuborder]=  useState()
const [Numoforder,setNumoforder]=useState()
const [LoadingSubmitOrder,setLoadingSubmitOrder]=useState(false)
//// function to submit order
  const suborder = () => {
    setLoadingSubmitOrder(true);
    console.log(datafrom)
console.log(datafrom.items.length)
if(datafrom.items.length == 0 )
{
  setLoadingSubmitOrder(false);
  Swal.fire({
    title: 'يجب اختيار منتج علي الاقل',

    confirmButtonText: 'موافق',
    showCancelButton: false,

    customClass:{
      confirmButton:'custombuttonsweet',
      title:'customtitlesweet',
     text:'customtextsweet'
    }
  })
}
// else if(datafrom.CustomerID==undefined){
//   Swal.fire({
//     title: 'يجب اختيار عميل',

//     confirmButtonText: 'موافق',
//     showCancelButton: false,

//     customClass:{
//       confirmButton:'custombuttonsweet',
//       title:'customtitlesweet',
//      text:'customtextsweet'
//     }
//   })


// }
// else if(datafrom.TableNo==undefined){
//   Swal.fire({
//     title: 'يجب اختيار طاوله',

//     confirmButtonText: 'موافق',
//     showCancelButton: false,

//     customClass:{
//       confirmButton:'custombuttonsweet',
//       title:'customtitlesweet',f
//      text:'customtextsweet'
//     }
//   })


// }
else{
  if(datafrom.id==undefined && datafrom.TableNo==undefined ){
    datafrom.id=null
    datafrom.TableNo=null
  }

  if( datafrom.CustomerID == undefined){
    datafrom.CustomerID = null
  }

  const formData = new FormData();

  formData.append("order", JSON.stringify(datafrom));
  formData.append("file", RecFile);
  console.log("formData",JSON.stringify(datafrom))
      axios.post("https://tijaryapis.azurewebsites.net/api/Waiter/SubmitOrder",formData,{
      headers: {"TijaryToken" : `${User.token}`} })

      .then((response) => {
        console.log(response)
        setreturnsuborder(response.data.data.purchaseOrderDs[0].id)
        setmaindiv(false)
        setshoworderprint(true)
        setNumoforder('*'+'22' + response.data.id+'*')
        // Setorderdet(response.data.data.purchaseOrderDs);
        Swal.fire({
          title: 'تم الأرسال',
          html:`<div className="container-fluid" >
          <div className="alertofsuborder" style=display:flex >
            <p> رقم الاودر </p>
            <p> ${response.data.id}</p>

          </div>
          </div>`,
          icon: 'success',
          didClose: () => {

            window.print()


            window.location.reload()

     },


          // timer: 2000,
          confirmButtonText: 'تم',





      }).then(()=> {




      })
        console.log(response)

      })

      .catch(error => {
        console.log(error);
        return error;
      })
      .finally(function () {
        setLoadingSubmitOrder(false);
      });
}

   }


// const category = (name,ID) =>
// {
//   const nameofcat = document.getElementById("showcat");
//   nameofcat.innerHTML = `${name}  >>`;
// }

// const subcategory = (name,ID) =>
// {
//   const nameofcatsub=document.getElementById("showsubcat");
//   nameofcatsub.innerHTML=name;

// }

//// fetch the المجموعات
const [Department, setDepartment] = useState();
const fetchHeader = () => {

  return axios.get('https://tijaryapis.azurewebsites.net/api/Item/SelectDepartment',{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {
    setDepartment(response.data);
     console.log(response.data);
     setShowDep(true)
     setchilditem(false)
     setShowTree(false)
     setshowChild(false)
     setShowItem(true)
     setofferitem(false)
     setLoading(false)
     const group=document.getElementById("group")
     group.style.background="RGB(25, 135, 84)"
     const tree=document.getElementById("tree")
     tree.style.background="rgb(245, 105, 2)"
     //offer 1 id in pc responsive
     const offer1 =document.getElementById("offer1")
     const offer =document.getElementById("offer")


     offer1.style.background="rgb(245, 105, 2)"
     offer.style.background="rgb(245, 105, 2)"



     if(showDep==false){
      myData.splice(myData.key);
    }



  })
  }

  const enterPressorderrecord = e => {
    if(e.key === 'Enter') {
     console.log("Press Enter",User.id,datetto,dateefrom)
     fetchdetailsorder(User.id,datetto,dateefrom)
        }}
// function pressenter(){

//      window.addEventListener('keydown', e => {
//       if(e.key === 'Enter'){
//         fetchdetailsorder(User.id,datetto,dateefrom)

//       }
//      })
// }


const handlePresscustomrecord = e => {
  if(e.key === 'Enter') {
   console.log("Press Enter",inputofsearch,searchcustomer)
   handleSearchcustomer(inputofsearch,searchcustomer)
  }}


const handlePress = e => {
  if(e.key === 'Enter') {
   console.log("Press Enter",inputofdetails)
   handleSearchdetails(inputofdetails)
  }}
// function pressenter3(inputofdetails){

//   window.addEventListener('keydown', e => {
//    if(e.key === 'Enter'){
//     console.log(inputofdetails)
//     handleSearchdetails(inputofdetails)

//    }
//   })
// }

const handlePresssubnewuser = e => {
  if(e.key === 'Enter') {
   console.log("Press Enter",)
   subnewuser()  }}
// function pressenter4(){
// console.log(newuserobj)
//   window.addEventListener('keydown', e  => {
//    if(e.key === 'Enter'){

//     subnewuser()

//    }
//   })
// }

const [finishStatus, setfinishStatus] = useState(false);

const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
        if (window.confirm("Do you want to go back ?")) {
            setfinishStatus(true)
            // your logic
           window.history.push("/");
        } else {
            window.history.pushState(null, null, window.location.pathname);
            setfinishStatus(false)
        }
    }
}

  useEffect(()  => {
          //     // var index=orderlist.findIndex(result=>result.id === result.id);


   fetchOpeningBalance(User.id)
    fetchsections();
    fetchtables(1);
    console.log(sections)
    localStorage.getItem('user_name')
    console.log( localStorage)
    fetchHeader();
   },[])

const [subcat,setsubcat]=useState();
//function to get category
const[lensubcat,setlensubcat]=useState();
const[childCount,setchildCount]=useState()
const fetcSubcat = (name,ID,count) => {
           title(name,ID,false,count);
           setShowDep(false);
  setShowCat(true);
  console.log(User.token)
  return axios.get('https://tijaryapis.azurewebsites.net/api/Item/SelectCategory/'+ID ,{
     headers: {"TijaryToken" : ` ${User.token}`} })
         .then((response) => {
          setsubcat(response.data);
          console.log(response.data.length)

          setlensubcat(response.data.length)
          const x=document.getElementById("IconHome");
          x.style.display="block";
            console.log(response.data);
            setShowDep(false);
            setShowCat(true);

         })

 }


//// function to logout
 const surelogout = () => {
setexit(true)
 };

 const logout=()=>{
  localStorage.removeItem("LoginUser")
  localStorage.removeItem('user_name')
  localStorage.removeItem('user_Password')
  localStorage.removeItem('rememberMe') 
  console.log(localStorage)
  window.location.href=("/")
 }
 const nologout=()=>{
  setexit(false)
 }
 const [loading, setLoading] = useState(false);

//// get item of المجموعات
      const fetcitem = (name,ID,childCount) => {
              title(name,ID,true,childCount);
              setShowDep(false);
              setShowCat(false);
              setspanner(true)
              setLoading(true);
            return  axios.get("https://tijaryapis.azurewebsites.net/api/Item/SelectItem/"+ID ,{
              headers: {"TijaryToken" : ` ${User.token}`} })
                  .then((response) => {
                    setitem(response.data);
                      console.log(response.data);
                      setspanner(false)
                    setShowItem(false);
                    setLoading(false);
              })

                      }

 const [showDep, setShowDep] = useState(true);
 const [showCat, setShowCat] = useState(false);
 const [showItem, setShowItem] = useState(false);

 //function to show home page
    function homepage(){

setLoading(false)
if(showDep==false){
  setShowItem(true)
  setShowTree(false)
  setShowDep(true)
  setshowChild(false)
  setshowitemchild(false)
  const group=document.getElementById("group")
  group.style.background="RGB(25, 135, 84)"
  const tree=document.getElementById("tree")
  tree.style.background="rgb(245, 105, 2)"
  const offer =document.getElementById("offer")
  offer.style.background="rgb(245, 105, 2)"
}
    let copy = [...myData]
    copy =copy.filter((order,id) => order.key != "showsubcat")
    copy.splice((order,id) => order.key != "showsubcat")
    setMyData(copy)

    }


function clickshowdep(Keyid){
  setLoading(false)
  if(showTree || showChild)  {
    let copy = [...myData]
   let index = copy.indexOf(Keyid)
  if(index > -1){
    copy =copy.filter((order,x) => x <= index)
    setMyData(copy)
    console.log(copy)
    fetchChild(Keyid["Length"],Keyid["IndexName"],Keyid["key"],true);
  }
    }
  else{
    setShowItem(true)
    setShowCat(true)
    setShowDep(false)

    let copy = [...myData]
    copy =copy.filter((order,id) => order.key != "showsubcat")
    setMyData(copy)
    console.log(copy)
    }

}

//// to show button to return back
function title(name,id,cat,count){
  const NewChild={
    key:cat&&!showTree?'showsubcat':id,
    name:name ,
    IndexName: name,
    cat: !showTree ? cat: false,
    Length:count,
}
  const NewDataLst =[...myData,NewChild];

  setMyData(NewDataLst);
}

const [showTree, setShowTree] = useState(false);
const [tree,setTree]=useState()

//function of tree to show tree
const fetchtree = () => {
  console.log(User.token)
  setLoading(false)
  setofferitem(false)
  return axios.get('https://tijaryapis.azurewebsites.net/api/Item/GetGrpItem',{
    headers: {"TijaryToken" : User.token} })
  .then((response) => {
    setTree(response.data.data);
     console.log(response);
     setShowDep(false)
     setShowTree(true)
     setShowCat(false)
     setShowItem(true)
     setshowitemchild(false)
     setshowChild(false)

     const group=document.getElementById("group")
    const tree=document.getElementById("tree")
    const offer1 =document.getElementById("offer1")
    const offer =document.getElementById("offer")

group.style.background="rgb(245, 105, 2)"
tree.style.background="RGB(25, 135, 84)"

offer1.style.background="rgb(245, 105, 2)"
offer.style.background="rgb(245, 105, 2)"

     if(myData.length>0){
      myData.splice(myData.key);
    }
  })
  }
  /////////////////////////////////
  const[offerdsicount,setofferdiscount]=useState()
  const[offeritem,setofferitem]=useState(false)

  const fetchofferdiscount = () => {
    setLoading(false)
    setShowTree(false)
    setShowCat(false)
    setShowDep(false)
    setofferitem(true)
     setShowItem(true)
    setshowitemchild(false)
    setshowChild(false)

    const group=document.getElementById("group")
    const tree=document.getElementById("tree")
    const offer1 =document.getElementById("offer1")

    const offer =document.getElementById("offer")

group.style.background="rgb(245, 105, 2)"
tree.style.background="rgb(245, 105, 2)"

offer.style.background="rgb(25, 135, 84)"
offer1.style.background="rgb(25, 135, 84)"

return axios.get('https://tijaryapis.azurewebsites.net/api/Item/DiscoutItems',{
      headers: {"TijaryToken" : User.token} })
    .then((response) => {
      console.log(response.data)
      setofferdiscount(response.data);
      document.getElementById("homepage").style.display="none"




    })
    }


  const [child,setchild]=useState()
  const[childitem,setchilditem]=useState()
const [showChild, setshowChild] = useState(false);

const [showitemchild, setshowitemchild] = useState(false);
 const [titleitem,settitleitem]=useState(false)


 // to show the item of tree
  const fetchChild = (count,name,id,Fromclickshowdep) => {

    axios.all([
     axios.get('https://tijaryapis.azurewebsites.net/api/Item/GetGrpItem/'+ id,{
        headers: {"TijaryToken" : ` ${User.token}`} })
      .then((response) => {
     console.log(response.data.data)
        setchild(response.data.data);
        if(!Fromclickshowdep)
            title(name,id,false,count);
        setshowChild(true)
        if(showChild==false){
          myData.splice(myData.key);
        }


  }),
      axios.get('https://tijaryapis.azurewebsites.net/api/Item/GetItemByGrpItem/'+ id,{
        headers: {"TijaryToken" : ` ${User.token}`} })
      .then((response) => {
        if(!Fromclickshowdep)
            title(name,id,false,count);
            console.log(response.data)
        setchilditem(response.data.data);
        console.log("childitem",response.data.data)
        setShowTree(false)
        setshowitemchild(true)

    if(response.data.length> 0){
      settitleitem(true)

    }else{
      settitleitem(false)

    }
      })
    ])
    }

    function hidepopup(){
      setExitPopup(false)
    }
    function showpopup(e){
      setExitPopup(true)
     e.preventDefault();
    }
// to set date to
    const[datetto,setdateto]=useState(toDay)
    const handleChangedateto = event => {// to comment
      setdateto (event.target.value);
      console.log(event.target.value)
     }
// to set date from
     const[dateefrom,setdatefrom]=useState(toDay)
     const handleChangedatefrom = event => {// to comment
       setdatefrom (event.target.value);
       console.log(event.target.value)

      }
/// to set states of dropdown
      const [status,setstatus]=useState("underprocessing")

      function statcancel(){/// to set states of dropdown cancel

        setstatus("canceled")


           const x= document.getElementById("dropdown-basic")
           x.innerHTML="ملغي"


      }
      function statcollected(){ /// to set states of dropdown collected
        setstatus("collected")

          const x= document.getElementById("dropdown-basic")
          x.innerHTML="مغلق"



      }

      function statunderprocessing(){/// to set states of dropdown underprogress
        setstatus("underprocessing")




           const x= document.getElementById("dropdown-basic")
           x.innerHTML="مفتوح"

      }
    ////////////////////////

    const [searchcustomer,setsearchcustomer]=useState("All")
    function searchallcustomer(){/// to set states of dropdown cancel
        setsearchcustomer("All")
       const x= document.getElementById("dropdown-basic")
         x.innerHTML="الكل"


    }
    function searchnamecustomer(){/// to set states of dropdown cancel

      setsearchcustomer("name")


         const x= document.getElementById("dropdown-basic")
         x.innerHTML="اسم"


    }
    function searchcodecustomer(){ /// to set states of dropdown collected
      setsearchcustomer("code")

        const x= document.getElementById("dropdown-basic")
        x.innerHTML="كود"



    }

    function searchphonecustomer(){/// to set states of dropdown underprogress
      setsearchcustomer("phone")
       const x= document.getElementById("dropdown-basic")
         x.innerHTML=" تليفون"

    }
    function searchallcustomermop(){/// to set states of dropdown cancel
      setsearchcustomer("All")
     const x= document.getElementById("dropdown-basicmop")
       x.innerHTML="الكل"


  }
  function searchnamecustomermop(){/// to set states of dropdown cancel

    setsearchcustomer("name")


       const x= document.getElementById("dropdown-basicmop")
       x.innerHTML="اسم"


  }
  function searchcodecustomermop(){ /// to set states of dropdown collected
    setsearchcustomer("code")

      const x= document.getElementById("dropdown-basicmop")
      x.innerHTML="كود"



  }

  function searchphonecustomermop(){/// to set states of dropdown underprogress
    setsearchcustomer("phone")
     const x= document.getElementById("dropdown-basicmop")
       x.innerHTML=" تليفون"

  }
  /////////////////////////////////
  function searchcodecustomer(){ /// to set states of dropdown collected
    setsearchcustomer("code")

      const x= document.getElementById("dropdown-basic")
      x.innerHTML="كود"



  }
//////////////////////////////////
function settoday(){ /// to set states of dropdown collected
  setdateto(toDay)
  setdatefrom (toDay);
  const to=document.getElementById("todayto2")
  const from=document.getElementById("todayfrom")
  to.value=toDay
  from.value=toDay
  const x= document.getElementById("dropdown-basic")
  x.innerHTML="اليوم"

}
function settoday2(){ /// to set states of dropdown collected
  setdateto(toDay)
  setdatefrom (toDay);
  const to=document.getElementById("todayto")
  const from=document.getElementById("todayfrom2")
  to.value=toDay
  from.value=toDay
  const x= document.getElementById("dropdown-basic2")
  x.innerHTML="اليوم"

}


function setyesterday(){ /// to set states of dropdown collected
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const dateofY=yesterday.toISOString().substring(0, 10)

console.log(yesterday.toISOString().substring(0, 10));
  const to=document.getElementById("todayto2")
  const from=document.getElementById("todayfrom2")
  setdateto(dateofY)
  setdatefrom(toDay)
  to.value=dateofY
  from.value=toDay
  const x= document.getElementById("dropdown-basic")
  x.innerHTML="أمس"
}
function setyesterday2(){ /// to set states of dropdown collected
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const dateofY=yesterday.toISOString().substring(0, 10)

  console.log(yesterday.toISOString().substring(0, 10));
    const to=document.getElementById("todayto")
    const from=document.getElementById("todayfrom")
    setdateto(dateofY)
    setdatefrom(toDay)
    to.value=dateofY
    from.value=toDay
    const x= document.getElementById("dropdown-basic2")
    x.innerHTML="أمس"
  }
function setlast7day(){ /// to set states of dropdown collected
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 7);

  const dateofY=yesterday.toISOString().substring(0, 10)

  console.log(yesterday.toISOString().substring(0, 10));
    const to=document.getElementById("todayto2")
    const from=document.getElementById("todayfrom2")
    setdateto(dateofY)
    setdatefrom(toDay)
    to.value=dateofY
    from.value=toDay
    const x= document.getElementById("dropdown-basic")
    x.innerHTML="اسبوع"
  }
  function setlast7day2(){ /// to set states of dropdown collected
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 7);

    const dateofY=yesterday.toISOString().substring(0, 10)

    console.log(yesterday.toISOString().substring(0, 10));
      const to=document.getElementById("todayto")
      const from=document.getElementById("todayfrom")
      setdateto(dateofY)
      setdatefrom(toDay)
      to.value=dateofY
      from.value=toDay
      const x= document.getElementById("dropdown-basic2")
      x.innerHTML="اسبوع"
    }

  function setlastmonth(){ /// to set states of dropdown collected
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() -30);

    const dateofY=yesterday.toISOString().substring(0, 10)

    console.log(yesterday.toISOString().substring(0, 10));
      const to=document.getElementById("todayto2")
      const from=document.getElementById("todayfrom2")
      setdateto(dateofY)
      setdatefrom(toDay)
      to.value=dateofY
      from.value=toDay
      const x= document.getElementById("dropdown-basic")
      x.innerHTML="شهر"
    }
    function setlastmonth2(){ /// to set states of dropdown collected
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() -30);

      const dateofY=yesterday.toISOString().substring(0, 10)

      console.log(yesterday.toISOString().substring(0, 10));
        const to=document.getElementById("todayto")
        const from=document.getElementById("todayfrom")
        setdateto(dateofY)
        setdatefrom(toDay)
        to.value=dateofY
        from.value=toDay
        const x= document.getElementById("dropdown-basic2")
        x.innerHTML="شهر"
      }



      ///// to get order in سجل العمليات
  const [orderdetails,setorederdetail]=useState([])
  const[lengthofdetailorder,setlengthofdetailorder]=useState("")
  const fetchdetailsorder = (id,datetto,dateefrom) => {
    setspanner(true)
        axios.get( `https://tijaryapis.azurewebsites.net/api/User/GetAllHistoryOrders/${id}/${datetto}/${dateefrom}/${status}`,{
          headers: {"TijaryToken" : ` ${User.token}`} })
        .then((response) => {
          setspanner(false)

        console.log("GetAllHistoryOrders",response)
          setorederdetail(response.data.data);
          console.log(response.data.data.length);
          setlengthofdetailorder()
          if(response.data.data.length==0){
            Swal.fire({
                title: 'لا يوجد معلومات',
                // timer: 1000,
                confirmButtonText: 'تم'
              })
          }


     })

      }


      // const [totalofdetail,settotalofdetail]=useState([])

      const [orderitemdetail,setorderitemdetail]=useState([])
      const [orderitemdetailNum,setorderitemdetailNum]=useState()


      const fetchorderitemdetail = (id) => {   // to set the order that get in سجل العمليات to order and edit in this order
        setorderitemdetailNum(id)
            axios.get( `https://tijaryapis.azurewebsites.net/api/Waiter/GetOrder/${id}`,{
              headers: {"TijaryToken" : ` ${User.token}`} })
            .then((response) => {
            console.log("GetOrder",response)
            if(response.data.data.tableNo != null){
            setTableNo(response.data.data.tableNo)
             setshowItable(true)
            }
            else{
              setTableNo(null)
               setshowItable(false)
            }
console.log(customersearch)
setthenumoforderintable(id)

let  totalpricedel =response.data.data.totaldiscount + response.data.data.nettotal;
            let totalqty=parseFloat (response.data.data.totalqut);
            let totalprice=response.data.data.total
            let totaldiscount=response.data.data.totaldiscount ;
            let Nettotal=response.data.data.nettotal;
            try{let itemComment =response.data.data.items[0].itemComment;}catch{ }

            const objtotal={
              'totalqut':parseFloat (totalqty),
              'totalpricedel':totalpricedel,
            'totaldiscount':totaldiscount,
            'totalprice':totalprice,
            'Nettotal':Nettotal
            }

            setorderlist(response.data.data.items) ;
            settotal(objtotal)

              // console.log(response.data.data.items);
              console.log(response.data.data.customerObj)
              setcustomer(response.data.data.customerObj)
              setshowclient(true)

         })
        //  Swal.fire({
        //   title: 'تم اختيار العميل',
        //   icon: 'success',
        //   timer: 2000,
        //   confirmButtonText: 'تم'
        // })

          }

     const [quantity,setquantaity]=useState()
     const [step, setStep] = useState(0.01);


     const handlechangeqty = (Qty) => {// to set quantity in input
      setStep(Qty)

      setquantaity (Qty);
      console.log(Qty)
     }

     const ChangeQty2 = (Item,quantity) => {
      let index = orderlist.findIndex(orderlist => orderlist.itemID === Item.itemID);
      if(index > -1){
       if(quantity==0.1){
      orderlist[index].quantity =  (parseFloat(orderlist[index].quantity) + parseFloat(0.1)).toFixed(2);


      Item.quantity = orderlist[index].quantity;
    }
    else if(quantity==1){
      orderlist[index].quantity = (parseFloat( orderlist[index].quantity) + parseFloat(1.0)).toFixed(2);


      Item.quantity = orderlist[index].quantity;
    }
    else if(quantity==0.25){
      orderlist[index].quantity = (parseFloat( orderlist[index].quantity )+ parseFloat(0.25)).toFixed(2);


      Item.quantity = orderlist[index].quantity;
    }
    else if(quantity==0.33){
      orderlist[index].quantity = (parseFloat( orderlist[index].quantity) + parseFloat(0.33)).toFixed(2);


      Item.quantity = orderlist[index].quantity;
    }
    else if(quantity==0.5){
      orderlist[index].quantity = (parseFloat( orderlist[index].quantity) + parseFloat( 0.5)).toFixed(2);


      Item.quantity = orderlist[index].quantity;
    }
    else if(quantity==0.75){
      orderlist[index].quantity =  (parseFloat(orderlist[index].quantity) + parseFloat(0.75)).toFixed(2);


      Item.quantity = orderlist[index].quantity;
    }
    else if(quantity>0.75)
{
   orderlist[index].quantity = parseFloat(quantity);
  Item.quantity = orderlist[index].quantity;

}
else if(quantity==0)
{
   orderlist[index].quantity = parseFloat(quantity);
  Item.quantity = orderlist[index].quantity;

}
else if(quantity<0.000 || quantity== "")
{
  Swal.fire({
    title: 'يجب ان تكون الكميه اكبر من 0 ',
    icon: 'success',
    timer: 4000,
    confirmButtonText: 'تم'
})

}
      }
      else
      {
       if(quantity==0.1){
        Item.quantity =  parseFloat(0.1) ;
       }
       else if(quantity==1){
        Item.quantity = parseFloat(1.0) ;
       }
       else if(quantity==0.25){
        Item.quantity = parseFloat(0.25);
       }
       else if(quantity==0.33){
        Item.quantity =parseFloat(0.33) ;
       }
       else if(quantity==0.5){
        Item.quantity =parseFloat(0.5) ;
       }
       else if(quantity==0.75){
        Item.quantity =  parseFloat(0.75);
       }
       else if(quantity>0.75)
       {
        orderlist[index].quantity = parseFloat( orderlist[index].quantity) +parseFloat (quantity);


         Item.quantity = orderlist[index].quantity ;

       }
       else if(quantity==0)
       {
        orderlist[index].quantity = parseFloat( orderlist[index].quantity) +parseFloat (quantity);


         Item.quantity = orderlist[index].quantity ;

       }
       else if(quantity<0.000 || quantity== ""){
        Swal.fire({
          title: 'يجب ان تكون الكميه اكبر من 0 ',
          icon: 'success',
          timer: 4000,
          confirmButtonText: 'تم'
      })
       }

      }

      orderdata(Item,quantity);
    }
    //to set quantity .1,1/4
  function handlechange(id,quantity){
    var index=item.findIndex(item => item.itemID === id);



      // ChangeQty(item[index],'+')
      if(index > -1){
        if(item[index].isDecimal==true){
          ChangeQty2(item[index],parseFloat(quantity))
             Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'تم اضافه المنتج',
        showConfirmButton: false,
        timer: 900
      })
        }
        else{
          Swal.fire("هذا الصنف لا يقبل علامه عشريه")
          // ChangeQty2(item[index],parseInt(quantity))
        }



      }
      else{
        var index=orderlist.findIndex(orderlist => orderlist.itemID === id);
        if(orderlist[index].isDecimal==true){
          ChangeQty3(orderlist[index],parseFloat(quantity))
        }
        else{
          ChangeQty3(orderlist[index],parseInt(quantity))
        }
   Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'تم اضافه المنتج',
        showConfirmButton: false,
        timer: 900
      })

      }


    }

    const ChangeQty3 = (childitem,quantity) => {
      let index = orderlist.findIndex(orderlist => orderlist.itemID === childitem.itemID);
      if(index > -1){

        if(quantity==0.1){
       orderlist[index].quantity = parseFloat(orderlist[index].quantity)  + parseFloat(0.1) ;


       childitem.quantity = orderlist[index].quantity;
     }
     else if(quantity==1){
       orderlist[index].quantity = parseFloat (orderlist[index].quantity) + parseFloat (1.0);


       childitem.quantity = orderlist[index].quantity;
     }
     else if(quantity==0.25){
       orderlist[index].quantity =parseFloat (orderlist[index].quantity) + parseFloat (0.25);


       childitem.quantity = orderlist[index].quantity;
     }
     else if(quantity==0.33){
      orderlist[index].quantity = parseFloat(orderlist[index].quantity) + parseFloat(0.33);


      childitem.quantity = orderlist[index].quantity;
    }
     else if(quantity==0.5){
       orderlist[index].quantity = parseFloat (orderlist[index].quantity) + parseFloat (0.5);


       childitem.quantity = orderlist[index].quantity;
     }
     else if(quantity==0.75){
       orderlist[index].quantity =parseFloat (orderlist[index].quantity) + parseFloat (0.75);


       childitem.quantity = orderlist[index].quantity;
     }
     else if(quantity>0.75){
      orderlist[index].quantity =parseFloat (orderlist[index].quantity) + parseFloat (quantity);


      childitem.quantity =  quantity;
    }

    else if(quantity==0){
      orderlist[index].quantity =parseFloat (orderlist[index].quantity) + parseFloat (quantity);


      childitem.quantity =  quantity;
    }
      else if(quantity < 0.000 || quantity== ""){
        Swal.fire({
          title: 'يجب ان تكون الكميه اكبر من 0 ',
          icon: 'success',
          timer: 4000,
          confirmButtonText: 'تم'
      })
      }
       }
       else
       {
        if(quantity==0.1){
          childitem.quantity = parseFloat (0.1);
        }
        else if(quantity==1){
          childitem.quantity =parseFloat (1.0);
        }
        else if(quantity==0.25){
          childitem.quantity =parseFloat (0.25);
        }
        else if(quantity==0.33){
          childitem.quantity =parseFloat (0.33);
        }
        else if(quantity==0.5){
          childitem.quantity =parseFloat (0.5);
        }
        else if(quantity==0.75){
          childitem.quantity =parseFloat (0.75);
        }
        else if(quantity>0.75){
          childitem.quantity =parseFloat (quantity);
        }
        else if(quantity == 0){
          childitem.quantity =parseFloat (quantity);
        }
        else if(quantity<0.000 || quantity== ""){
          Swal.fire({
            title: 'يجب ان تكون الكميه اكبر من 0 ',
            icon: 'success',
            timer: 4000,
            confirmButtonText: 'تم'
        })
        }
       }

      orderdata(childitem,quantity);

   
    }

  function handlechange3(id,quantity){

    var index=childitem.findIndex(childitem => childitem.itemID === id);

    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'تم اضافه المنتج',
      showConfirmButton: false,
      timer: 900
    })

      // ChangeQty(item[index],'+')
      ChangeQty3(childitem[index],parseFloat(quantity))


    }


    function handlechange2(id,quantity){

      Swal.fire({
        position: 'top',
        icon: 'success',
        title: 'تم اضافه المنتج',
        showConfirmButton: false,
        timer: 900
      })
      var index=offerdsicount.findIndex(offerdsicount => offerdsicount.itemID === id);

        // ChangeQty(item[index],'+')
        ChangeQty3(offerdsicount[index],parseFloat(quantity))


      }



    const [inputofdetails,setinputofdetails]=useState("")
  const handleChangeinputofdetails = event => {
    setinputofdetails (event.target.value);
    console.log(event.target.value)
   };


   const [detailsitemsearch,setdetailsitemsearch]=useState()
   const handleSearchdetails = (inputofdetails) => {// get item from api by search in استعلام عن صنف
    showinfoinitempopup(false)
    setinfoepopup(true)
    settablepopup(false)
    setOrderrecord(false)
    setcustomerrecord(false)
    setnewuserpopup(false)
    setxredpoup(false)
    setinfoinitem(false)
  setisopen(false)
  setspanner(true)
  console.log(User.token)
    axios.get("https://tijaryapis.azurewebsites.net/api/Item/SearchItem/"+inputofdetails,{
         headers: {"TijaryToken" : ` ${User.token}`} })
       .then((response) => {//set data return in itemlist

        setspanner(false)
setdetailsitemsearch("");

 if(response.data.length==0){
  Swal.fire({
    title: ' هذا الكود خطأ ',
    confirmButtonText: 'استمرار',
    showCancelButton: false,


  })


 }
 else{
  console.log("error",response.data)

  setdetailsitemsearch(response.data);
  console.log(response.data)

 }




             })
       .catch(error => {
        console.log("error",error)

       return error;
       });
         }

         ///////////////////////////////////
         const [firstname,setFirstname] =useState()
         const handleSetfirstname = event => {// to FIRSTNAME
          setFirstname (event.target.value);
          console.log(event.target.value)

         }
         const [lastname,setLastname] =useState()
         const handleSetLastname = event => {// to LASTNAME
          setLastname (event.target.value);
          console.log(event.target.value)

         }
         const [phonenumber,setPhonenumber] =useState()
         const handlePhoneNumber = event => {// to phonenumber
          setPhonenumber (event.target.value);
          console.log(event.target.value)

         }
         const [Address,setAddress] =useState()
         const handleAddress = event => {// to address
          setAddress (event.target.value);
          console.log(event.target.value)

         }

         const newuserobj={
          firstName:firstname,
          lastName:"",
          phoneNumber:phonenumber,
          customerAddresses:[
            {

              "address": Address,
              "type": null,
              "region": null,
              "isDefault": true,
              "country": null,
              "governorate":null,
              "city":null,
                }]
                        }


function reload(){ //function to reload
  setshowpopupneworder(true)
  settablepopup(false)
  setinfoepopup(false)
  setOrderrecord(false)
  setcustomerrecord(false)
  setnewuserpopup(false)
  setxredpoup(false)
  setinfoinitem(false)
  setisopen(false)
}
function deletebutton(id,Item){ // function of delete item by icon

  var index=orderlist.findIndex(result=>result.id === result.id);
  Item.quantity =0
  orderdata(Item);
}
const [lenghtogtable,setlengthoftable]=useState()
// function to get section in section
const [sections,setsections]=useState()
const fetchsections = () => {// get item from api by search
  axios.get("https://tijaryapis.azurewebsites.net/api/Section/SelectSections",{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {//set data return in itemlist
    const length = response.data.length;

console.log("fetch",response.data)
console.log("lenght",length)
if(length==0){
  setshowsection(false)
setshowtable(false)
}


setshowsection(true)
setshowtable(false)
setsections(response.data)



        })
  .catch(error => {
  return error;
  });
    }

 //////////////////////////
 const [showsection,setshowsection]=useState(false)
 const[showtable,setshowtable]=useState(false)

const [showclient,setshowclient]=useState(false)
const [showItable,setshowItable]=useState(false)
const[Xred,setXred]=useState()

// fetch x-report
const fetchXRed=(id)=>{
  console.log("id",id)
  axios.get("https://tijaryapis.azurewebsites.net/api/Waiter/GetXRead/"+id,{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {//set data return in itemlist

console.log("data",response.data)
setXred(response.data)
})
  .catch(error => {
  return error;
  });

}

//fetch opening balance
const[exitopenbal,setexitopenbal]=useState(false)
const sureclosopenbal=()=>{
  setexitopenbal(true)
}
const cancelclosopenbal=()=>{
  setexitopenbal(false)
}
const fetchcloseopening=(id)=>{
  setexitopenbal(true)
  axios.post("https://tijaryapis.azurewebsites.net/api/Waiter/CloseOpening/",{UserId:`${id}` },{
    headers: {"TijaryToken" : `${User.token}`} })
  .then((response) => {//set data return in itemlist
  console.log("close bal",response.data)
  setexitopenbal(false)

    logout()
})
  .catch(error => {
  return error;
  });

}


const[maindiv,setmaindiv]=useState(true)
const[showorderprint,setshoworderprint]=useState(false)
const [showxred,setshowxred]=useState(false)
//to ptintx-reprot
const  print= ()=>{
  setshowxred(true)
  setshoworderprint(false)
  setmaindiv(false)

  window.setTimeout('window.print()',1000);
  // window.setTimeout(function () {afterprint()}, 2000);
}

function afterprint(){
  setshowxred(false)

  setshoworderprint(false)
  setmaindiv(true)
}


function getHoursDiff(){
  var today = new Date();
 var Logindate = new Date(timelogin);
var diffMs = (today - Logindate); // milliseconds between now & Christmas
var diffDays = Math.floor(diffMs / 86400000); // days
var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
if(diffHrs == 0 && diffMins > 0){
  setDiffTime(diffMins.toLocaleString('ar-u-nu-arab') + " " + "د")

}
else if(diffHrs > 0  && diffMins > 0){
setDiffTime(diffHrs.toLocaleString('ar-u-nu-arab') + " " + "س: "  + diffMins.toLocaleString('ar-u-nu-arab') + " " + "د")

}
else if(diffHrs > 0 && diffMins == 0){
  setDiffTime(diffHrs.toLocaleString('ar-u-nu-arab') + " " + "س: "  )

}

}
// function showSettings (event) {
//   event.preventDefault();

// }


const [isopen,setisopen]=useState(false)
function closesidebar(){
  if(isopen==true){
    setisopen(false)
     const x=document.getElementById("mainburgericon")
    x.style.display="block"
    x.style.marginRight= "28%"
    x.style.marginTop= "9%"
  }

}
function opensidebar(){// to open sidebar
  getHoursDiff()
  if(isopen == false){

    setisopen(true)
    const x=document.getElementById("mainburgericon")
    x.style.display="none"



 }
  else if(isopen == true){
    setisopen(false)
    const x=document.getElementById("mainburgericon")
    x.style.display="block"
    x.style.marginRight= "28%"
    x.style.marginTop= "9%"

  }


}

const length = orderlist.length;

const [orderintable,setorderintable]=useState()

function setidoforder(id){
  setorderintable(id)
}



const [orderoftable,setorderoftable]=useState()
const [numoforderintable ,setnumoforderintable]=useState()
const [numberid,setnumberid]=useState()
const fetchorderoftable = (ID) => {// get item from api by search

  axios.get("https://tijaryapis.azurewebsites.net/api/Tables/GetAllOrdersByTableNo/"+ ID,{

    headers: {"TijaryToken" : ` ${User.token}`} })

  .then((response) => {//set data return in itemlist
    // var index=sections.findIndex(result=>result.id === result.id);
console.log(response.data)
const length = response.data.length;
console.log("thismyid",ID)
setnumberid(ID)
setpopupdiv(false)
setorderoftable(response.data)
setnumoforderintable(length)
console.log(response.data)



        })
  .catch(error => {
  return error;
  });
    }

    const[popupdiv,setpopupdiv]=useState(false)
    var Logindate = new Date(timelogin);

    const hoursoflogin =  Logindate.toLocaleTimeString('ar-EG', {  //date hours , minutes
      hour12:true,
      hour: 'numeric',

      minute: 'numeric',


});
const [text,settext]=useState("")

console.log("sss",Logindate.getHours())
console.log("hoursAndMinutes",hoursoflogin)

const[popupofcommentitem,setpopupofcommentitem]=useState(false)

const [popuptijary,setpopuptijary]=useState(false)
function showpopuptijary(){
  setpopuptijary(true)
}
function closepopuptijary(){
  setpopuptijary(false)
}
function changeBackground(e) {
  e.target.style.background = 'rgb(25, 135, 84)';

}
function changeBackG(e) {
  e.target.style.background = '';


}

const [showpopupneworder,setshowpopupneworder]=useState(false)

function neworder(){
  window.location.reload()
}
const cancelneworder = () => {

  setshowpopupneworder(false)

}


function showdecimalbutton(id){

  const x =document.getElementById(id)
if(x.style.display == "none"){
  x.style.display="flex"
}
else {
  x.style.display="none"
}
}
const [decimalbuttontree,setdecimalbuttontree] =useState(false)
function showdecimalbuttontree(id){

  const x =document.getElementById(id)
if(x.style.display == "none"){
  x.style.display="flex"
}
else {
  x.style.display="none"
}




}

const [popupchoosecust,setpopupchoosecust]=useState(false)
function showpopupchoosecust(){
  setpopupchoosecust(true)
}
function closepopupchoosecust(){
  setpopupchoosecust(false)
}

const[spanner,setspanner]=useState(false)

const[exit,setexit]=useState(false)
const [info,setinfoepopup] =useState(false)
function showinfopopup(){
  setinfoepopup(true)
  settablepopup(false)
  setOrderrecord(false)
  setcustomerrecord(false)
  setnewuserpopup(false)
  setxredpoup(false)
  setinfoinitem(false)
setisopen(false)
setpoppofextraitem(false)






}
function hideinfopopup(){
  setinfoepopup(false)
}
//////////////////
const [tablepopup,settablepopup] =useState(false)
function showtablepopup(){
  settablepopup(true)
  setinfoepopup(false)
  setOrderrecord(false)
  setcustomerrecord(false)
  setnewuserpopup(false)
  setxredpoup(false)
  setinfoinitem(false)
setisopen(false)


}
function hidetablepopup(){
  settablepopup(false)
}
///////////////////////////
const [Orderrecord,setOrderrecord] =useState(false)
function showorderecordpopup(){

  settablepopup(false)
  setinfoepopup(false)
  setOrderrecord(true)
  setcustomerrecord(false)
  setnewuserpopup(false)
  setxredpoup(false)
  setinfoinitem(false)
setisopen(false)


}
function hideorderecordpopup(){
  setOrderrecord(false)
}
/////////////////
const [customerrecord,setcustomerrecord] =useState(false)
function showcustomerpopup(){

  settablepopup(false)
  setinfoepopup(false)
  setOrderrecord(false)
  setcustomerrecord(true)
  setnewuserpopup(false)
  setxredpoup(false)
  setinfoinitem(false)
  setisopen(false)
  setshowclient(true)


}
function hidecustomerpopup(){
  setcustomerrecord(false)
  setcustomerdata(false)
  if(customer == undefined || ""){
    setshowclient(true)

  }
  else{
    setshowclient(false)

  }
  if(customer != undefined && customer != "" && customer != null)
      setcustomer()
}
///////////////////////
const [newuserpopup,setnewuserpopup] =useState(false)
function shownewuserpopup(){

  settablepopup(false)
  setinfoepopup(false)
  setOrderrecord(false)
  setcustomerrecord(false)
  setnewuserpopup(true)
  setxredpoup(false)
  setinfoinitem(false)
  setisopen(false)
  const cursor= document.getElementById("nameofaddcustomer")
}
function hidenewuserpopup(){
  setnewuserpopup(false)
}
//////////////////////////
const [xredpoup,setxredpoup] =useState(false)
function showxredpopup(){

  settablepopup(false)
  setinfoepopup(false)
  setOrderrecord(false)
  setcustomerrecord(false)
  setnewuserpopup(false)
  setxredpoup(true)
  setinfoinitem(false)
  setisopen(false)
}
function hidexredpopup(){
  setxredpoup(false)
}
const [infoinitem,setinfoinitem] =useState(false)
function showinfoinitempopup(){
 settablepopup(false)
  setinfoepopup(false)
  setOrderrecord(false)
  setcustomerrecord(false)
  setnewuserpopup(false)
  setxredpoup(false)
  setinfoinitem(true)
  setisopen(false)
}
function hideinfoinitempopup(){
  setinfoinitem(false)
}
console.log("tok",User)
function clear(){
  document.getElementById("inputcustomerrecord").value=""
}
const [poppofextraitem,setpoppofextraitem]=useState(false)
function hidepopupextraitem(){
  setpoppofextraitem(false)
}
function showpopupextraitem(){
  setpoppofextraitem(true)
  setsearchitempopup(false)
  axios.get("https://tijaryapis.azurewebsites.net/api/Item/GetItemsByBarCodeOrName/"+message2,{
    headers: {"TijaryToken" : ` ${User.token}`} })
  .then((response) => {//set data return in itemlist

    setitemlist(response.data.data);
    console.log("extraitem",response.data.data)



        })
  .catch(error => {
  return error;
  });

}

function clearsearchtext(){
  document.getElementById("searchtext").value=""
  setitemlist("")
  setsearchitempopup(false)
  setlengthsearchitem("")


}
function clearsearchtextpopupextraitem(){
  document.getElementById("textsearchpopupextraitem").value=""
  setitemlist("")
  setsearchitempopup(false)

}



function clearinfotext(){
  document.getElementById("infotextsearch").value=""
}
function cleartextordercust(){
  document.getElementById("inputcustomerrecord").value=""
}

const[scanpopupitem,setscanpopupitem]=useState(false)
function showscanpopupitem(){
  setscanpopupitem(true)
  setcustomerrecord(false)
  setpoppofextraitem(false)
  setshowclient(false)



}
function hidescanpopupitem(){
  setscanpopupitem(false)


}

const[scanpopupcust,setscanpopupcust]=useState(false)
function showscanpopupcust(){
  setscanpopupcust(true)
  setinfoepopup(false)


}
function hidescanpopupcust(){
  setscanpopupcust(false)


}
// const [result, setResult] = useState("");
// const { ref } = useZxing({
//   onResult(result) {
//     setResult(result.getText());
//   }})
  const[searchbybarcode,setsearchbybarcode]=useState(false)
  function showpopupsearchbybarcode(){
    setsearchbybarcode(true)
    setpoppofextraitem(false)
  }

  function hidepopupsearchbybarcode(){
    setsearchbybarcode(false)
  }

const[searchitempopup,setsearchitempopup]=useState(false)

// const serchitem = (e) => {//stop refresh page
//   e.preventDefault();
//   console.log("search",  e.preventDefault())
// };
const [result, setResult] = React.useState('No result');
  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  };

  const createScreenshotPDF = () => {
    const MARGIN = 2;
    // get the content element
    document.getElementById('printsuborder').style.display="block"
    const content = document.getElementById('printsuborder');

  console.log(content.offsetWidth)

  console.log(content.clientWidth)

    // get content size
    const width = 397 ;
    const height = content.clientHeight;

    // call html2canvas to save the screenshot to a pdf file
    html2canvas(content, {
      scrollX: 0, // scroll to X origin
      scrollY: 5, // scroll to Y origin
      scale: 5, // 5 is quality scale
    }).then(function (canvas) {
      // convert the canvas conent to a Data URI/URL
      var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px', // set pixel as unit
        format: [width + MARGIN * 1, height + MARGIN * 1],
      });

      // add canvas as an image
      doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

      // save the content to screenshot.pdf
      doc.save('الطلبيه.pdf');
      content.style.display="none"
    });
  };
  const[barcodeScan,setsearchbybarcodeScan]=useState("5rem")
function tablenotavalabile(){
  Swal.fire(
    'لا يمكن اضافه اوردر علي هذه الطاوله'
  )
}
return(

        <div className='container-fluid fontmaaray'>


    {maindiv==true?
    <div className='row'  >

    <div className='col-lg-9 col-md-6 col-12 mt-2 border rounded themaindivhavetree' onClick={closesidebar}  isOpen={isopen}  id='divfornew'  >




    <div className='row ' style={{"background":"rgb(239 237 237)"}}>
    <div className='col-lg-1 col-md-2 col-1 fs-4 ' style={{padding:"0px",marginTop:"1%"}}>
    <BiMenuAltRight style={{fontSize:"30px",color:"#ea8f1a"}} id='mainburgericon' onClick={opensidebar} ></BiMenuAltRight>
    {isopen == true ?
    <Menu id='menu' disableCloseOnEsc right  isOpen={isopen} customBurgerIcon={false}>


    <button className='btn text-white w-100 iconinsidebar fs-4 ' style={{"margin-right":"-10px"}} onClick={opensidebar} isOpen={isopen}  >
    <AiOutlineClockCircle  className='fs-5 clockicon' style={{}} onClick={opensidebar} />


    </button>
    <button className='btn text-white w-100 iconinsidebar fs-4' style={{"margin-right":"188px","margin-top":"-67px"}} onClick={opensidebar} isOpen={isopen}  >
    <MdKeyboardDoubleArrowRight className='fs-2'  onClick={opensidebar} />


    </button>



    <p className='text-white fontmaaray' style={{whiteSpace:"nowrap",fontSize:"15px","margin-right":"28px", "margin-top":"-33px"}}>
    منذ  &nbsp;{ hoursoflogin.replace("ص", "صباحا").replace("م", "مساءا")} {text} </p>

    <p className='text-white fontmaaray'  style={{whiteSpace:"nowrap",fontSize:"14px","margin-top": "-36px","margin-right":"132px"}}>
    ({DiffTime})
    </p>
    <a  style={{"text-decoration": "none"}} className="menu-item fs-4 fontmaaray " onClick={showinfopopup}   >
    <button className='btn text-white btnofinfoinsidebar' > <AiOutlineInfoCircle className='fs-3'  /> <br></br> استعلام  </button>
    </a>
    <a style={{"text-decoration": "none"}} className="menu-item fs-4 fontmaaray"  onClick={showpopup}  >
    <button  className='btn   text-white btntableinsidebar' onClick={showtablepopup}  > <MdTableBar className='fs-3'></MdTableBar><br></br> الطاولات</button>
    </a>
    <a style={{"text-decoration": "none"}}  className="menu-item fs-4  fontmaaray"  >
    <button onClick={showorderecordpopup} className='btn text-white btnrecorderorderinsidebar' > <AiOutlineUnorderedList className='fs-3'/> <br></br> سجل الاوردارات</button>
    </a>





    <a style={{"text-decoration": "none"}}  className="menu-item fs-4  fontmaaray"  >
    <button onClick={showcustomerpopup} className='btn text-white btnrecordcustinsidebar'  > <AiOutlineUser className='fs-3'/> <br></br>قائمه العملاء </button>
    </a>


    <a  style={{"text-decoration": "none"}} className="menu-item fs-4 fontmaaray"  >
    <button onClick={shownewuserpopup} className='btn  text-white btnnewcustomerinsidebar' > <AiOutlineUser className='fs-3 '/> <br></br> عميل جديد </button>

   </a>

{/* <a className='fontmaaray fs-4 btn '  onClick={() => fetchXRed(openingBalance)}>
     <button      onClick={showxredpopup} className='btn   text-white fontmaaray btnxredinsidebar' style={{"margin-right":"104px"}} > <TbReportSearch className="fs-3"  ></TbReportSearch> <br></br> تقرير X
    </button>
</a> */}


    {/* <button className='btn' style={{"margin-right":" -165%"}}> <a  style={{"text-decoration": "none",whiteSpace:"nowrap" ,"font-size": "18px"}} className="menu-item   text-white" onClick={reload} >
    <IoRefreshOutline className='fs-3 text-white '></IoRefreshOutline> اوردر جديد</a></button> */}
   <a  style={{"text-decoration": "none"}} className=' fontmaaray  menu-item' onClick={() => fetchXRed(openingBalance)}>
    <button  onClick={showxredpopup}  className="text-white fontmaaray btnxredinsidebar btn"  >
    <TbReportSearch className='fs-3 text-white '></TbReportSearch><br></br> تقرير X </button>
    </a>





    <button className='btn fontmaaray btncloseorderinsidebar' onClick={sureclosopenbal}>
    <a  className="menu-item   text-white fontmaaray btncloseorder "  >
    <MdSyncLock className='fs-3 text-white '></MdSyncLock><br></br> غلق الورديه</a>
    </button>



    <button className='btn fontmaaray btncloseinsidebar'   onClick={() => surelogout()}>
    <a  className="menu-item  text-white fontmaaray btnclosewebsite"  >
    <FiLogOut className='fs-3 text-white' ></FiLogOut> <br></br> الخروج</a>
    </button>


    <a id='logotijary' onClick={showpopuptijary} style={{cursor:"pointer",width:"237px","margin-top":"9px"}}>
 <button className='btn btnimagetijary'  >
 <img className='imgtijary' src={require("../image/tijary.store--BigWhite.png")}></img>

 </button>
    </a>

    <button className="btn imagebedab" >
    <a href="http://bedab.com/" target="_blank" style={{textDecoration:"none"}}>  <img className="imgbedabinsidebar" src={require("../image/Bedab-Logo-White.png")}  />
    <br></br>

    <h5 style={{fontSize:"11px",marginTop:"5px"}} className="text-white"> software</h5>

    </a>
    </button>


    </Menu>:""}


    </div>
    <div className='col-lg-1 col-md-1 col-2 mt-3'style={{backgroundImage:`url(${require("../image/tijarylogo.png")})`,  backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        "max-height": "35px","margin-top": "1.5%",padding:"0px",marginRight:"16px"      }}>

    </div>
      <div className='col-lg-2 col-md-6 col-7 '>
        <div className='row ' style={{"justify-content": "right"}}>
          <div className='col-lg-3 col-md-12 col-sm-12 mt-4 textofwelcome'>
          {/* {"#a5cd39"}style={{color:"green"}} */}
            <p className='fontmaaray helloinmob' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}> أهلا   <span style={{color:"rgb(25 135 84)"}}>{User.userName}</span></p>
          </div>



      </div>
    </div>

    <div className='col-lg-7 col-md-12 col-12 searchinmaindiv' >
    <div className='row ' style={{marginTop:"0.6%"}} >
    {/* <div class="input-group mb-3 col-12" >
    <div class="input-group-prepend col-10" >
    <input type="search" class="form-control" placeholder="بحث"  />

    </div>
    <z class="btn bg-white col-2" style={{borderLeft:"none"}}><BsSearch></BsSearch></button>
    </div> */}
    <div className='row stylesearchinmob' >
      <div className='col-12 fontmaaray reactsearch' style={{direction:"ltr","margin-top":"1.5%"}}   >
     <div className='row' style={{"margin-top":"-4px"}} >
<div className='col-1' onClick={showpopupsearchbybarcode}>
{/* <BiBarcodeReader className="iconofsearchinput" style={{}} ></BiBarcodeReader> */}


</div>
<div className='col-lg-11 col-md-11 col-11 maindivforsearch' style={{height:"50px"}}>


<div className='col-12' style={{display:"flex",height:"100%",backgroundColor:"white",borderRadius:"5px"}}>
  {lengthsearchitem > 9?
  <p className='textnumoflistitemsearch' data-bs-toggle="tooltip" data-bs-placement="top" title="عدد الاصناف" onClick={showpopupextraitem}  >{lengthsearchitem}</p>


  :
  <p className='textnumoflistitemsearch2' data-bs-toggle="tooltip" data-bs-placement="top" title="عدد الاصناف" >{lengthsearchitem}</p>
}

<input className='w-100 h-100 fontmaaray'
style={{textAlign:"right" ,direction:"rtl",border:"none",borderRadius:"5px",fontSize:"17px"}}
        type="text"
        id="searchtext"
        name="message"
        onChange={handleChangetext}
        onClick={handleChangetext}
        placeholder='بحث عن صنف'
      />
      <RxCross2 onClick={clearsearchtext} style={{marginRight: "1px",
    marginTop:"19px",marginTop:"14px",fontSize:"25px"}} className='text-danger'></RxCross2>
</div>



{/* <ReactSearchAutocomplete

            showIcon={false}
            placeholder="بحث عن صنف"
            items={itemlist}
            className="fontmaaray searchofiteminsearch"
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            clearOnBlur={true}
            value={null}
            blurOnSelect={true}
            autoFocus
            fuseOptions={{ keys: ["itemName","itemID"] }}
            formatResult={formatResult}

          /> */}
</div>
     </div>

      </div>


    </div>


    </div>


    </div>




    </div>
          {/* <h2 className='fontmaaray' style={{fontStyle:"italic"}}>الاصناف</h2> */}
        <div className='row mt-2' style={{"margin-right": "0%"}}>
        <div className='col-lg-2 col-md-4 col-3' style={{padding:"0px"}}>
          <button className='btn  text-white mt-3 fontmaaray shadow' id='group'  onClick={fetchHeader}>
         <div>
         <img style={{"height":"35px","width":"35px","margin-right":"-11px" ,marginTop:"-2px"}} src={require("../image/Groups.png")} />
         </div>
         <div style={{marginRight:"10px"}}>مجموعات</div>
         </button>

          </div>

          <div className='col-lg-2 col-md-4 col-3 treeinmob' >
          <button className='btn  text-white mt-3 fontmaaray shadow btntreemob' id='tree' onClick={fetchtree}>
         <div>
         <img style={{"height":"35px","width":"35px","margin-right":"-11px" ,marginTop:"-2px"}} src={require("../image/Tree.png")} />
         </div>
         <div style={{marginRight:"10px"}}>
         شجره
         </div>
         </button>

          </div>
          <div className='col-lg-4 col-md-4 col-6 offersrespMob  ' onClick={fetchofferdiscount}  style={{padding:"0px"}}>
          <button className='btn  text-white mt-3 fontmaaray shadow offersresp' id='offer' style={{width:"98%"}}  >
         <div className="row">
<div className="col-md-3 col-12" >
<img style={{"height":"35px","width":"35px","margin-right":"-11px" ,marginTop:"-2px"}} src={require("../image/Offers.png")} />

</div>
<div className="col-md-6 col-12" >
<p style={{margin:"0px",fontSize:"15px"}} >       عروض
</p>
</div>

         </div>

           </button>

          </div>
          <div className='col-lg-2 col-md-5 col-5 divofferstab' onClick={fetchofferdiscount}  >
          <button className='btn  text-white mt-3 fontmaaray shadow offersrespTab' style={{height:"40px",width:"185px","margin-right":"0%","white-space":"nowrap"}} id='offer1'  >
          <div>

          <img style={{"height":"35px","width":"35px","margin-right":"-11px" ,marginTop:"-2px"}} src={require("../image/Offers.png")} />
          </div>
          <div className='textofferanddisc' style={{marginRight:"10px"}}>
          عروض وتخفيضات
          </div>

          </button>

          </div>
          {showItable==true?
                        <div className='col-lg-3   col-md-5 col-11'style={{padding:"0px","margin-top": "3%"}}>
                        {tableNo == undefined?
                        <div>

                        </div>
                        :
                        <button className='row maindivtocattree w-100 ' onClick={showtablepopup}>
                            <div className='col-lg-3 mt-1 col-md-3 col-2 p-0 '>
                            <button   className='btn p-0 '  style={{whiteSpace:"nowrap"}}> <img style={{"width":"35px","margin-top":"-5px"}} src={require("../image/dining-table.png")} /> </button>
                            </div>


                            <div className='col-lg-6 col-md-7 mt-1 col-6 textofNumTable p-0'  >
                              <p className='text-right fontmaaray' style={{"text-shadow":"2px 4px 4px #bfbfbf",color:"#595959"}}> طاوله رقم</p>
                            </div>



                            <div className='col-lg-2 col-md-2 mt-1 col-3 NumOfTable p-0' >
                            <p className='text-center fontmaaray' style={{"text-shadow":"2px 4px 4px #bfbfbf",color:"#595959"}}> {tableNo}</p>
                            </div>
                        </button>}




                          </div>
                      :
                      <div className='col-lg-3 marginoficontable col-md-4 col-7 'style={{padding:"0px"}}>
                      {tableNo == undefined?

                        <button  onClick={showtablepopup} className='btn fontmaaray shadow w-100 ' id='tablebtn'  style={{whiteSpace:"nowrap","margin-left":"52%",marginTop:"6.2%", "border":"1px solid #e7e6e6",background:"rgb(239, 237, 237)","color":"black",height:"42px"}}>
                        <div className='row'>
                          <div className='col-lg-3 col-md-3 col-3' style={{padding:"0px"}}>
                          <img style={{"height":"35px","width":"35px","margin-top":"-3px"}} src={require("../image/dining-table.png")}/>
                          </div>
                            <div className='col-lg-6 col-md-8 col-8' style={{padding:"0px"}}>
                            <p style={{"margin-top":"2px",color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontWeight:"bold"}}>
                            الطاولات
                            </p>
                          </div>
                        </div>
                        </button>:""}
                      </div>}
          <div className='col-lg-2 col-md-5 divoficontocustomer  col-5' >
          {showclient==true?
           <div className='row' style={{border:"1px dashed",height: "40px", borderRadius: "5px",
           "margin-top":"8px"}}>
<div className='col-2 p-0' style={{"font-size":"22px","margin-top":"1%",marginRight:"8px"}}>
<AiOutlineUser style={{color:"rgb(245, 105, 2)"}}></AiOutlineUser>
</div>
<div className='col-9 p-0'>
<p id='nameofcustomer fontmaaray marginiconofuser ' style={{cursor:"pointer",marginTop:"5%",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",textAlign:"right"}} onClick={showcustomerpopup}>

{console.log("cuasda",customer)}

العميل:
{ customer && customer.firstName != undefined||null   ?

customer.firstName  +  ' ' + customer.lastName : ""}
</p>
</div>
           </div>
          :
          <div className='row marginiconofuser '>
          <div className='col-lg-5 col-md-6 col-4 divofchoosebtn' >
          <button className='btn fontmaaray shadow choosebtn' style={{backgroundColor:"#efeded",height:"42"}} id='choosebtn'  data-toggle="tooltip" data-placement="top" title="بحث عن عميل "  onClick={showcustomerpopup}>
          <img src={require("../image/search.png")} style={{height:"30px"}}  id='notchangecolor'/> </button>

          </div>

          <div className='col-lg-5 col-md-5 col-4 divnewcustomerbtn' data-toggle="tooltip" data-placement="top" title="اضافه عميل جديد">
          <button   onClick={shownewuserpopup} className='btn shadow fontmaaray newcustomerbtn' id='newcustomerbtn'    > <AiOutlineUserAdd  style={{color:"rgba(234,143,26,255)",fontSize:"32px"}}></AiOutlineUserAdd> </button>

          </div>

            </div>}



          </div>



        </div>

           <div className='row justify-content-center'  id='item'>
            {offeritem==true?
             <div className='col-12' style={{"padding-right": "18px",}}>
      {offerdsicount ?  <div className='row g-0 gx-3' style={{"font-weight": "bold"}}>
    {offerdsicount.map((offeritem) => (

<div className='col-lg-2  col-md-6 col-6 mt-2'>
<div className=' card mt-2 shadow' id="cardofofferitem" style={{"justify-content": "center","background":"#e7e6e6", position:"relative" }} >
{offeritem.itemName=="" ?
<div className='col-12'  >

        { offeritem.itemDiscountAmount==0 ?
          <div  style={{"margin-top":'-10%','margin-left': '-9%'}}>

            </div>
            :
          <div className='flag row' style={{'position':'absolute','background-color':"#D6AD60","color":"rgb(245, 105, 2)"}}>
         {offeritem.itemDiscountAmount.toFixed(2) } جم
            </div>  }
  </div> :
  <div className='col-12'>
            <BsInfoSquareFill className='infoiconinitem' onClick={() => {handleSearchdetails(offeritem.itemID);showinfoinitempopup()}}  ></BsInfoSquareFill>
{offeritem.itemImage==""?

<img  onClick={() => btnCategoryAdd(offeritem,'+')} src={require("../image/download.png")} style={{width:"100px",marginTop:"-11px"}}>

</img>
:


<img  onClick={() => btnCategoryAdd(offeritem,'+')} src={offeritem.itemName} style={{width:"100px",height:"100px",height:"25px",marginTop:"-11px"}}>

</img>
}
        {/*   */}



        { offeritem.itemDiscountAmount==0 ?
          <div  style={{'margin-left': '-9%'}}>

            </div>
            :
          <div className='flag ' style={{'position':'absolute','background-color':"#D6AD60","color":"white"}}> {offeritem.itemDiscountAmount } جم
            </div>  }
  </div>}
  <div className='col-12' style={{height:"51px",overflowX: "hidden",
overflowY: "scroll"}}>

  {offeritem.itemName.length < 16?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px",fontWeight:"bold"}}>{offeritem.itemName}</p>

  :""}
   {offeritem.itemName.length >=16 && offeritem.itemName.length < 21   ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"14px",fontWeight:"bold"}}>{offeritem.itemName}</p>

  :""}
    {offeritem.itemName.length >= 21 && offeritem.itemName.length < 24  ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{offeritem.itemName}</p>

  :""}
       {offeritem.itemName.length >=24 && offeritem.itemName.length <= 28  ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{offeritem.itemName}</p>

  :""}
        {offeritem.itemName.length >28  ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{offeritem.itemName}</p>

  :""}

   {/* {itemchild.itemName.length<=51 ?
             <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"13px",fontWeight:"bold"}}>{itemchild.itemName}</p>

  :""} */}
    {/* {itemchild.itemName.length>51  ?
             <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"11px",fontWeight:"bold"}}>{itemchild.itemName}</p>

  :""} */}
      {/* {console.log("ls",itemchild.itemName.length)} */}
  </div>
  <div className='col-12'>
{offeritem.itemDiscountAmount  == 0 ?
    <div className='divbody '>
      <h4 className='price  fontmaaray' style={{color:"rgb(181 75 0)",fontSize:"25px",fontWeight:"bold",textAlign:"center"}}  dir='rtl'>{offeritem.finalPrice.toFixed(2)} <span style={{"font-size":"12px"}}>جم</span> </h4>
    </div>
:   <div className='divbody '>
     <div className='col-6'>
<h4 className='price  fontmaaray' style={{color:"rgb(181 75 0)",fontSize:"25px",fontWeight:"bold",textAlign:"center"}}  dir='rtl'>{offeritem.finalPrice.toFixed(2)} <span style={{"font-size":"12px"}}>جم</span></h4>

</div>
<div className='col-6'>
<del className='fontsize fontmaaray' id='oldprice' dir='rtl' style={{"font-size":"15px","color":"#686767",textAlign:"center"}} >{offeritem.salePrice.toFixed(2)} جم</del>

</div>
        <div>

    </div>
</div>}

  </div>
  <div className='col-12' style={{marginBottom:"5px"}}>
  {offeritem.isDecimal==true?
      <div  style={{display:"flex"}}>

<div className='col-lg-8 col-md-8 col-8' style={{height:"57px",marginRight:"3px"}}>
<button className='btn  w-100  text-white  btnright fontmaaray' style={{"background":"#ff6a00",height:"64%","white-space":"nowrap",marginTop:"15px"}}  onClick={() => btnCategoryAdd(offeritem,'+')} >
<div className='row'>
<div className='col-4' style={{"margin-top":"-18px"}}>
<span className='margespan' style={{fontSize:"35px"}} >&#43;</span>

</div>
<div className='col-4' style={{marginRight:"-7px"}}>
<p>            اضافه
</p>
</div>
</div>
        {/* <span  id='quantity' className='mx-2' >{itemObj.quantity}</span> */}
      </button>
</div>

    <div className='col-lg-2 col-md-2 col-2 buttonshowdecimal' style={{height:"37px"}} >

  <button   onClick={() => showdecimalbutton(offeritem.itemID)} className='btn w-100 ' style={{"height":"100%","background":"rgb(255 106 0)"}}>
    <TbWeight  className='text-white tbweight'></TbWeight>
</button>
</div>

        {/* <div className='col-3' style={{"margin-right": "-10%"}}>
        <button className='btn  text-white mt-3 borderbtn' style={{"background":"#F56902"}}  onClick={() => btnonclick(itemObj,'-')}>&#8722;</button>
        </div> */}
</div>:
<div className='row justify-content-center '>
<div className='col-11' style={{height:"55px"}}>
<button className='btn  w-100  text-white  btnright2 fontmaaray' style={{"background":"#ff6a00",height:"69%","white-space":"nowrap",marginTop:"15px"}}  onClick={() => btnCategoryAdd(offeritem,'+')} >
<div className='row'>
<div className='col-4' style={{"margin-top":"-17px"}}>
<span className='margespan' style={{fontSize:"35px"}} >&#43;</span>

</div>
<div className='col-4'>
<p>            اضافه
</p>
</div>
</div>
        {/* <span  id='quantity' className='mx-2' >{itemObj.quantity}</span> */}
      </button>
</div>
</div>
}
{offeritem.isDecimal==true?


<div className='   btnofquantityitemchildtree' style={{display:"none",marginBottom:"5%"}} id={offeritem.itemID}>
<div className='col-3 mx-1' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange2(offeritem.itemID,0.25);}}
  style={{ padding: 0,background:"#ff6a00",color:"white"}}>1/4 </button>
</div>
<div className='col-3  mx-1 ' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray btnof3mob '  onClick={() =>{ handlechange2(offeritem.itemID,0.33);}}   style={{ padding: 0,background:"#ff6a00",color:"white"}}>1/3 </button>
</div>
<div className='col-3 mx-1' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange2(offeritem.itemID,0.5);}}   style={{ padding: 0,background:"#ff6a00",color:"white",whiteSpace:"nowrap"}}>1/2 </button>
</div>
<div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
        {/* parseFloat((itemObj.quantity + 0.1).toFixed(2)) */}

<button className='btn w-100 fontmaaray' onClick={() =>{ handlechange2(offeritem.itemID,0.100);}}  style={{ padding: 0,background:"#ff6a00",color:"white"}}>0.1 </button>
</div>




<div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
<button className='btn  w-100 fontmaaray btnof3mob'  onClick={() =>{ handlechange2(offeritem.itemID,(0.75));}}   style={{ padding: 0,background:"#ff6a00",color:"white",whiteSpace:"nowrap"}}>3/4</button>
</div>
<div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange2(offeritem.itemID,1.0);}} style={{ padding: 0,background:"#ff6a00",color:"white"}}>1</button>
</div>


</div>


:""}
  </div>

</div>

</div>
    ))}

    </div>:""}

    {/* <div className='col-12'>
    <div className='row'>
    <Popup   trigger={<button className='btn btn-primary mt-3'> المزيد </button>}
    modal nested>
    {
    close => (<div className='col-12' style={{"height":"600px","overflow":"scroll","background": "gray","width": "100%"}}>
    <div className='row justify-content-center'>
    {item.map((itemObj) => (
    <div className='col-2 'style={{"min-height":"210px"}}>
    <div className='row card h-100 mt-2' style={{"justify-content": "center","max-width": "150px"}} >
      <div className='col-12'style={{backgroundImage:`url(${itemObj.itemImage})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height:"90px",
      //    width:'150px',
          backgroundPosition: 'center'}} >
              <div className='flag ' style={{'position':'absolute','background-color':"red","margin-top": "0%"}}>sale <br></br> {itemObj.itemDiscountAmount } جم
    </div>
      </div>
      <div className='col-12'>
          <h5 className='card-title'>{itemObj.itemName}</h5>
      </div>
      <div className='col-12'>
      <div className='divbody'>
          <del className='fontsize col-4' id='oldprice' dir='rtl' style={{"font-size":"8px","margin-left": "13%"}} >{itemObj.salePrice.toFixed(2)} جم</del>
          <h4 className='price col-8' style={{"margin-right": "-21%"}}  dir='rtl'>{itemObj.itemDiscountedPrice} جم</h4>

      </div>

      </div>
      <div className='col-12'>
          <div className='row card-title'>
    <div className='col-8'>
    <button className='btn  w-100  btn-primary text-white mt-3 btnright'  onClick={() => btnonclick(itemObj,'+')} >
            <span className='margespan'>&#43;</span>
            <span  id='quantity' >{itemObj.quantity}</span>
          </button>
    </div>
            <div className='col-3' style={{"margin-right": "-10%"}}>
            <button className='btn btn-primary  mt-3 borderbtn'  onClick={() => btnonclick(itemObj,'-')}>


              &#8722;</button>
            </div>


    </div>
      </div>
    </div>

    </div>
    ))}

    </div>
    <div className='row justify-content-center mt-3'>
        <div className='col-4'>
        <button className='btn btn-primary' onClick=
            {() => close()}>
              اغلاق
        </button>
        </div>
    </div>
    </div>
    )}
    </Popup></div>
    </div> */}
    </div>:""}

    </div>
        <div className='row'>
        <div className='col-12 mt-3'>
          {showDep ==false && showTree==false ?
            //     <div className=' row'  id='homepage'>

          //     <div className='col-1 ' id='IconHome'>
          //           <HiHome onClick={homepage}></HiHome>
          //      </div>
          //     {myData.map((val) => (
          //       <div className='col-lg-3 col-md-4 mt-2'>
          //       <button style={{padding:"0px"}} className='btn' id={val["key"]} onClick={val["cat"] ? '' : clickshowdep}>{val["name"]}</button>
          //       </div>))}
          //     {/* <button id='showcat' onClick={clickshowdep} className=' btn '  style={{"font-size":"14px"}}></button>

          //     <button  id='showsubcat' className='  btn ' style={{"font-size":"14px"}}></button> */}

          //  </div>
    <div className='name row ' style={{"display":"flex"}} id='homepage'>

    {/* <div className='col-1 ' id='IconHome'>
          <HiHome className='fs-3' onClick={homepage}></HiHome>
      </div> */}
      <div className='col-10'>
      <div style={{flexDirection:"row",display:"flex",flexWrap: "wrap" ,cursor:"pointer"}}>
      <HiHome className='fs-3' style={{color:"rgb(245, 105, 2)"}} onClick={homepage}></HiHome>
    {myData.map((val) => (

        <div className=''  style={{padding:"0px"}} >

        <button style={{ "font-size": "20px","color":"rgb(116 113 113)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px",whiteSpace:"nowrap",textAlign:"right"}}  className='btn  fontmaaray p-0' id={val["key"]} onClick={val["cat"] ? '' : () => clickshowdep(val)}>{val["name"]+ `(${val["Length"]})`} <span style={{color:"rgb(245, 105, 2)"}}>{">"}</span>  &nbsp; </button>

        </div>

      ))}
      </div>
      </div>
    {/* <button id='showcat' onClick={clickshowdep} className=' btn '  style={{"font-size":"14px"}}></button>

    <button  id='showsubcat' className='  btn ' style={{"font-size":"14px"}}></button> */}

    </div>
    :null}

          </div>
        </div>

          {Department  ?

    <div className='container-fluid  p-0'>

    {showTree ==true ?
            <div className='row' >

            {tree.map((tree)=>(


          <div className='col-lg-3 col-md-4 col-6 mt-2'  onClick={() => fetchChild(tree.childCount,tree.name,tree.id,false)} >
              <div className='row g-3'>
                <button  className='btn gx-2 fontmaaray shadow' style={{ background:"rgb(239, 237, 237)","border-radius": "5px 5px 5px 5px"}}>{tree.name}</button>
                </div>

          </div>
            ))}


            </div> :<></>}
    {showChild==true ?
            <div className='row gx-2' >

            {child.map((child)=>(
          <div className='col-lg-3 col-md-4 col-6 mt-2'  onClick={() => fetchChild(child.childCount,child.name,child.id,false )} >

                <button  className='btn gx-2 w-100 fontmaaray shadow' style={{ background:"rgb(239, 237, 237)","border-radius": "5px 5px 5px 5px"}}>{child.name}</button>




          </div>
            ))}


            </div>:""}
          {childitem ?

    <div className='row gx-2' id='department'>
    {showitemchild==true ?
    <>
    {titleitem==true ?
      <div className='row justify-content-center'>
      <h3 className='fontmaaray'>الاصناف</h3>
    </div>
    :""}

      {childitem.map((itemchild)=>(
   <div className='col-lg-2 col-md-6 col-6 mt-2'>
   <div className=' card mt-2 shadow' style={{"justify-content": "center","background":"#e7e6e6", position:"relative" }} >
   {itemchild.itemName=="" ?
   <div className='col-12'  >

           { itemchild.itemDiscountAmount==0 ?
             <div  style={{"margin-top":'-10%','margin-left': '-9%'}}>

               </div>
               :
             <div className='flag row' style={{'position':'absolute','background-color':"#D6AD60","color":"rgb(245, 105, 2)"}}>
            {itemchild.itemDiscountAmount ? itemchild.itemDiscountAmount.toFixed(2) : 0 } جم
               </div>  }
     </div> :
     <div className='col-12'>
               <BsInfoSquareFill className='infoiconinitem' onClick={() => {handleSearchdetails(itemchild.itemID);showinfoinitempopup()}}  ></BsInfoSquareFill>

               {itemchild.itemImage == "" ?
      <img  onClick={() => btnCategoryAdd(itemchild,'+')} src={require("../image/download.png")} style={{width:"100px",marginTop:"-11px"}} />
:
<img  onClick={() => btnCategoryAdd(itemchild,'+')} src={itemchild.itemImage} style={{width:"147px",height:"100px",maxWidth:"147px",marginTop:"-11px"}} />
}
           { itemchild.itemDiscountAmount==0 ?
             <div  style={{'margin-left': '-9%'}}>

               </div>
               :
             <div className='flag ' style={{'position':'absolute','background-color':"#D6AD60","color":"white"}}> {itemchild.itemDiscountAmount } جم
               </div>  }
     </div>}
     <div className='col-12' style={{height:"51px",overflowX: "hidden",
   overflowY: "scroll"}}>

     {itemchild.itemName.length < 16?
     <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""}
      {itemchild.itemName.length >=16 && itemchild.itemName.length < 21   ?
     <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"14px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""}
       {itemchild.itemName.length >= 21 && itemchild.itemName.length < 24  ?
     <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""}
          {itemchild.itemName.length >=24 && itemchild.itemName.length <= 28  ?
     <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""}
           {itemchild.itemName.length >28  ?
     <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""}

      {/* {itemchild.itemName.length<=51 ?
                <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"13px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""} */}
       {/* {itemchild.itemName.length>51  ?
                <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"11px",fontWeight:"bold"}}>{itemchild.itemName}</p>

     :""} */}
         {/* {console.log("ls",itemchild.itemName.length)} */}
     </div>
     <div className='col-12'>
   {itemchild.itemDiscountAmount  == 0 ?
       <div className='divbody '>
         <h4 className='price  fontmaaray' style={{color:"rgb(181 75 0)",fontSize:"25px",fontWeight:"bold",textAlign:"center"}}  dir='rtl'>{itemchild.finalPrice.toFixed(2)} <span style={{"font-size":"12px"}}>جم</span> </h4>
       </div>
   :   <div className='divbody '>
        <div className='col-6'>
   <h4 className='price  fontmaaray' style={{color:"rgb(181 75 0)",fontSize:"25px",fontWeight:"bold",textAlign:"center"}}  dir='rtl'>{itemchild.finalPrice?itemchild.finalPrice.toFixed(2):0} <span style={{"font-size":"12px"}}>جم</span></h4>

   </div>
   <div className='col-6'>
   <del className='fontsize fontmaaray' id='oldprice' dir='rtl' style={{"font-size":"15px","color":"#686767",textAlign:"center"}} >{itemchild.salePrice.toFixed(2)} جم</del>

   </div>
           <div>

       </div>
   </div>}

     </div>
     <div className='col-12' style={{marginBottom:"5px"}}>
     {itemchild.isDecimal==true?
         <div  style={{display:"flex"}}>

   <div className='col-lg-8 col-md-8 col-8' style={{height:"57px",marginRight:"3px"}}>
   <button className='btn  w-100  text-white  btnright fontmaaray' style={{"background":"#ff6a00",height:"64%","white-space":"nowrap",marginTop:"15px"}}  onClick={() => btnCategoryAdd(itemchild,'+')} >
   <div className='row'>
   <div className='col-4' style={{"margin-top":"-18px"}}>
   <span className='margespan' style={{fontSize:"35px"}} >&#43;</span>

   </div>
   <div className='col-4' style={{marginRight:"-7px"}}>
   <p>            اضافه
   </p>
   </div>
   </div>
           {/* <span  id='quantity' className='mx-2' >{itemObj.quantity}</span> */}
         </button>
   </div>

       <div className='col-lg-2 col-md-2 col-2 buttonshowdecimal' style={{height:"37px"}} >

     <button   onClick={() => showdecimalbutton(itemchild.itemID)} className='btn w-100 ' style={{"height":"100%","background":"rgb(255 106 0)"}}>
       <TbWeight  className='text-white tbweight'></TbWeight>
   </button>
   </div>

           {/* <div className='col-3' style={{"margin-right": "-10%"}}>
           <button className='btn  text-white mt-3 borderbtn' style={{"background":"#F56902"}}  onClick={() => btnonclick(itemObj,'-')}>&#8722;</button>
           </div> */}
   </div>:
   <div className='row justify-content-center '>
   <div className='col-11' style={{height:"55px"}}>
   <button className='btn  w-100  text-white  btnright2 fontmaaray' style={{"background":"#ff6a00",height:"69%","white-space":"nowrap",marginTop:"15px"}}  onClick={() => btnCategoryAdd(itemchild,'+')} >
   <div className='row'>
   <div className='col-4' style={{"margin-top":"-17px"}}>
   <span className='margespan' style={{fontSize:"35px"}} >&#43;</span>

   </div>
   <div className='col-4'>
   <p>            اضافه
   </p>
   </div>
   </div>
           {/* <span  id='quantity' className='mx-2' >{itemObj.quantity}</span> */}
         </button>
   </div>
   </div>
   }
   {itemchild.isDecimal==true?


   <div className=' btnofquantityitemchildtree' style={{display:"none",marginBottom:"5%"}} id={itemchild.itemID}>
   <div className='col-3 mx-2' style={{ padding: 0}}>
   <button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange3(itemchild.itemID,0.25);}}  style={{ padding: 0,background:"#ff6a00",color:"white"}}>1/4 </button>
   </div>
   <div className='col-3  mx-1' style={{ padding: 0}}>
   <button className='btn w-100 fontmaaray btnof3mob'  onClick={() =>{ handlechange3(itemchild.itemID,0.33);}}   style={{ padding: 0,background:"#ff6a00",color:"white"}}>1/3 </button>
   </div>
   <div className='col-3 mx-1' style={{ padding: 0}}>
   <button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange3(itemchild.itemID,0.5);}}   style={{ padding: 0,background:"#ff6a00",color:"white",whiteSpace:"nowrap"}}>1/2 </button>
   </div>
   <div className='col-3 mt-2 mx-2' style={{ padding: 0}}>
           {/* parseFloat((itemObj.quantity + 0.1).toFixed(2)) */}

   <button className='btn w-100 fontmaaray' onClick={() =>{ handlechange3(itemchild.itemID,0.100);}}  style={{ padding: 0,background:"#ff6a00",color:"white"}}>0.1 </button>
   </div>




   <div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
   <button className='btn  w-100 fontmaaray btnof3mob'  onClick={() =>{ handlechange3(itemchild.itemID,(0.75));}}   style={{ padding: 0,background:"#ff6a00",color:"white",whiteSpace:"nowrap"}}>3/4</button>
   </div>
   <div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
   <button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange3(itemchild.itemID,1.0);}} style={{ padding: 0,background:"#ff6a00",color:"white"}}>1</button>
   </div>


   </div>


   :""}
     </div>

   </div>

   </div>
    ))}</>
    :""}



    </div> : null }









    { showDep == true ?
    <div className='row gx-2' id='department'>

    {Department.map((dep)=>(
    <div className='col-lg-2 col-md-4 col-6 mt-2' >
        <div className='col-12 card shadow' id={dep.id}  onClick={() => fetcSubcat(dep.name,dep.id,dep.childCount)}>
    {dep.image==null || dep.image ==undefined ?
      <div className='col-12' style={{backgroundImage:`url(${require("../image/default-department.jpg")})`,  backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',  height:"90px", cursor:'pointer',

          backgroundPosition: 'center'}}>

    </div>              :
      <div className='col-12' style={{backgroundImage:`url(${dep.image})`,  backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',  height:"90px",cursor:'pointer',

                        backgroundPosition: 'center'}}>

        </div>}

        <div className='row'>
          <h5 className='card-title fontmaaray' style={{"color":"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}}>{dep.name}</h5>
        </div>

      </div>
      <div className='col-1'></div>
    </div>
    ))}


    </div> : null }





    </div>


          : ''}

    {subcat && showDep == false && showCat == true ?
    <div className='container-fluid' >

    <div className='row gx-2 gy-1' id='subcat' >

    {subcat.map((cat)=>(

    <div className='col-lg-2 mx-2 col-md-4 col-5 card mt-2 shadow'  onClick={() => fetcitem(cat.name,cat.id,cat.childCount)}>
    {cat.itemImage =="" || cat.itemImage==undefined ?
    <div className='col-12' style={{backgroundImage:`url(${require("../image/menu.png")})`,  backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',  height:"90px", cursor: "pointer",

          backgroundPosition: 'center'}}>

    </div>  :  <div className='col-12 shadow' style={{backgroundImage:`url(${cat.itemImage})`,  backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',  height:"90px",cursor:'pointer',

                        backgroundPosition: 'center'}}>

        </div>}
    <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{cat.name}</p>
    </div>
    ))}
    </div>

    </div>
    :  null}


    <div className='row justify-content-center'  id='item'>
  {loading ==true ? (
        <Spinner animation="border" style={{marginRight:"40%"}} role="status">

        </Spinner>
      )

:






    <div className='col-12' style={{"padding-right": "18px",}}>
   {item  && showItem == false?
    <div className='row g-0 gx-3' style={{"font-weight": "bold"}}>
      {item.length > 0 ?
    item.map((itemObj) => (

<div className='col-lg-2 col-md-6 col-6 p-0 mt-2'>
<div className=' card mt-2 shadow' style={{"justify-content": "center","background":"#e7e6e6", position:"relative" }} >
{itemObj.itemName=="" ?
""
  
  :
  <div className='col-12'>
            <BsInfoSquareFill className='infoiconinitem' onClick={() => {handleSearchdetails(itemObj.itemID);showinfoinitempopup()}}  ></BsInfoSquareFill>
{itemObj.itemImage == "" ?
<img  onClick={() => btnCategoryAdd(itemObj,'+')} src={require("../image/download.png")} style={{width:"100px",marginTop:"-11px"}}>

 </img>
 :
 <img  onClick={() => btnCategoryAdd(itemObj,'+')} src={itemObj.itemImage} style={{width:"147px",height:"100px",maxWidth:"147px",marginTop:"-11px"}}>

 </img>

 }

        {/*   */}



        { itemObj.itemDiscountAmount==0 ?
          <div  style={{'margin-left': '-9%'}}>

            </div>
            :
          <div className='flag ' style={{'position':'absolute','background-color':"#D6AD60","color":"white"}}> {itemObj.itemDiscountAmount } جم
            </div>  }
  </div>}
  <div className='col-12' style={{height:"51px",overflowX: "hidden",
overflowY: "scroll"}}>

  {itemObj.itemName.length < 16?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px",fontWeight:"bold"}}>{itemObj.itemName}</p>

  :""}
   {itemObj.itemName.length >=16 && itemObj.itemName.length < 21   ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"14px",fontWeight:"bold"}}>{itemObj.itemName}</p>

  :""}
    {itemObj.itemName.length >= 21 && itemObj.itemName.length < 24  ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{itemObj.itemName}</p>

  :""}
       {itemObj.itemName.length >=24 && itemObj.itemName.length <= 28  ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{itemObj.itemName}</p>

  :""}
        {itemObj.itemName.length >28  ?
  <p className='card-title  fontmaaray mt-2'  style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px",fontWeight:"bold"}}>{itemObj.itemName}</p>

  :""}
  </div>
  <div className='col-12'>
{itemObj.itemDiscountAmount  == 0 ?
    <div className='divbody '>
      <h4 className='price  fontmaaray' style={{color:"rgb(181 75 0)",fontSize:"25px",fontWeight:"bold",textAlign:"center"}}  dir='rtl'>{itemObj.finalPrice.toFixed(2)} <span style={{"font-size":"12px"}}>جم</span> </h4>
    </div>
:   <div className='divbody '>
     <div className='col-6'>
<h4 className='price  fontmaaray' style={{color:"rgb(181 75 0)",fontSize:"25px",fontWeight:"bold",textAlign:"center"}}  dir='rtl'>{itemObj.finalPrice} <span style={{"font-size":"12px"}}>جم</span></h4>

</div>
<div className='col-6'>
<del className='fontsize fontmaaray' id='oldprice' dir='rtl' style={{"font-size":"15px","color":"#686767",textAlign:"center"}} >{itemObj.salePrice.toFixed(2)} جم</del>

</div>
        <div>

    </div>
</div>}

  </div>
  <div className='col-12' style={{marginBottom:"5px"}}>
  {itemObj.isDecimal==true?
      <div  style={{display:"flex"}}>

<div className='col-lg-8 col-md-8 col-8' style={{height:"57px",marginRight:"3px"}}>
<button className='btn  w-100  text-white  btnright fontmaaray' style={{"background":"#ff6a00",height:"64%","white-space":"nowrap",marginTop:"15px"}}  onClick={() => btnCategoryAdd(itemObj,'+')} >
<div className='row'>
<div className='col-4' style={{"margin-top":"-18px"}}>
<span className='margespan' style={{fontSize:"35px"}} >&#43;</span>

</div>
<div className='col-4' style={{marginRight:"-7px"}}>
<p>            اضافه
</p>
</div>
</div>
        {/* <span  id='quantity' className='mx-2' >{itemObj.quantity}</span> */}
      </button>
</div>

    <div className='col-lg-2 col-md-2 col-2 buttonshowdecimal' style={{height:"37px"}} >

  <button   onClick={() => showdecimalbutton(itemObj.itemID)} className='btn w-100 ' style={{"height":"100%","background":"rgb(255 106 0)"}}>
    <TbWeight  className='text-white tbweight'></TbWeight>
</button>
</div>

        {/* <div className='col-3' style={{"margin-right": "-10%"}}>
        <button className='btn  text-white mt-3 borderbtn' style={{"background":"#F56902"}}  onClick={() => btnonclick(itemObj,'-')}>&#8722;</button>
        </div> */}
</div>:
<div className='row justify-content-center '>
<div className='col-11' style={{height:"55px"}}>
<button className='btn  w-100  text-white  btnright2 fontmaaray' style={{"background":"#ff6a00",height:"69%","white-space":"nowrap",marginTop:"15px"}}  onClick={() => btnCategoryAdd(itemObj,'+')} >
<div className='row'>
<div className='col-4' style={{"margin-top":"-17px"}}>
<span className='margespan' style={{fontSize:"35px"}} >&#43;</span>

</div>
<div className='col-4'>
<p>            اضافه
</p>
</div>
</div>
        {/* <span  id='quantity' className='mx-2' >{itemObj.quantity}</span> */}
      </button>
</div>
</div>
}
{itemObj.isDecimal==true?
<div className="col-12">
<div className='  btnofquantityitemchildtree' style={{display:"none",marginBottom:"5%"}} id={itemObj.itemID}>
<div className='col-3 mx-2' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange(itemObj.itemID,0.25.toFixed(2));}}  style={{ padding: 0,background:"#ff6a00",color:"white"}}>1/4 </button>
</div>
<div className='col-3  mx-1' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray btnof3mob'  onClick={() =>{ handlechange(itemObj.itemID,0.33.toFixed(2));}}   style={{ padding: 0,background:"#ff6a00",color:"white"}}>1/3 </button>
</div>
<div className='col-3 mx-1' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange(itemObj.itemID,0.5.toFixed(2));}}   style={{ padding: 0,background:"#ff6a00",color:"white",whiteSpace:"nowrap"}}>1/2 </button>
</div>
<div className='col-3 mt-2 mx-2' style={{ padding: 0}}>
        {/* parseFloat((itemObj.quantity + 0.1).toFixed(2)) */}

<button className='btn w-100 fontmaaray' onClick={() =>{ handlechange(itemObj.itemID,0.100.toFixed(2));}}  style={{ padding: 0,background:"#ff6a00",color:"white"}}>0.1 </button>
</div>




<div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
<button className='btn  w-100 fontmaaray btnof3mob'  onClick={() =>{ handlechange(itemObj.itemID,0.75.toFixed(2));}}   style={{ padding: 0,background:"#ff6a00",color:"white",whiteSpace:"nowrap"}}>3/4</button>
</div>
<div className='col-3 mt-2 mx-1' style={{ padding: 0}}>
<button className='btn w-100 fontmaaray '  onClick={() =>{ handlechange(itemObj.itemID,1.0.toFixed(2));}} style={{ padding: 0,background:"#ff6a00",color:"white"}}>1</button>
</div>


</div>
</div>




:""}
  </div>

</div>

</div>
    ))
    :<div>
    <p> لايوجد اصناف
    </p>
    </div>}

    </div>:""}
    {/* <div className='col-12'>
    <div className='row'>
    <Popup   trigger={<button className='btn btn-primary mt-3'> المزيد </button>}
    modal nested>
    {
    close => (<div className='col-12' style={{"height":"600px","overflow":"scroll","background": "gray","width": "100%"}}>
    <div className='row justify-content-center'>
    {item.map((itemObj) => (
    <div className='col-2 'style={{"min-height":"210px"}}>
    <div className='row card h-100 mt-2' style={{"justify-content": "center","max-width": "150px"}} >
      <div className='col-12'style={{backgroundImage:`url(${itemObj.itemImage})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height:"90px",
      //    width:'150px',
          backgroundPosition: 'center'}} >
              <div className='flag ' style={{'position':'absolute','background-color':"red","margin-top": "0%"}}>sale <br></br> {itemObj.itemDiscountAmount } جم
    </div>
      </div>
      <div className='col-12'>
          <h5 className='card-title'>{itemObj.itemName}</h5>
      </div>
      <div className='col-12'>
      <div className='divbody'>
          <del className='fontsize col-4' id='oldprice' dir='rtl' style={{"font-size":"8px","margin-left": "13%"}} >{itemObj.salePrice.toFixed(2)} جم</del>
          <h4 className='price col-8' style={{"margin-right": "-21%"}}  dir='rtl'>{itemObj.itemDiscountedPrice} جم</h4>

      </div>

      </div>
      <div className='col-12'>
          <div className='row card-title'>
    <div className='col-8'>
    <button className='btn  w-100  btn-primary text-white mt-3 btnright'  onClick={() => btnonclick(itemObj,'+')} >
            <span className='margespan'>&#43;</span>
            <span  id='quantity' >{itemObj.quantity}</span>
          </button>
    </div>
            <div className='col-3' style={{"margin-right": "-10%"}}>
            <button className='btn btn-primary  mt-3 borderbtn'  onClick={() => btnonclick(itemObj,'-')}>


              &#8722;</button>
            </div>


    </div>
      </div>
    </div>

    </div>
    ))}

    </div>
    <div className='row justify-content-center mt-3'>
        <div className='col-4'>
        <button className='btn btn-primary' onClick=
            {() => close()}>
              اغلاق
        </button>
        </div>
    </div>
    </div>
    )}
    </Popup></div>
    </div> */}
    </div>}
    </div>




 


    {popupchoosecust==true?
    <div className='col-lg-9 col-md-9 card shadow Popupcustomerstylemain' style={{backgroundColor:"rgb(239, 237, 237)"}}>
    <div className='row card-title' style={{"background":"rgb(239, 237, 237)"}} >
    <div className='col-12'>
    <div className='row '>
    <div className='col-9'>
    <h1 className='text-center fontmaaray'>قائمه العملاء</h1>

    </div>
    {/* <div className='col-2'>

    <select name="الحاله" id="status" defaultValue={status}>
    {console.log(status)}
    <option onClick={statunderprocessing} value="مفتوح">مفتوح</option>
    <option onClick={statcollected} value="مغلق">مغلق</option>
    <option onClick={statcancel} value="ملغي">ملغي</option>

    </select>
    </div> */}
    <div className='col-2' style={{textAlign:"end"}}>
    <button  className='col-1 btn  close text-danger'  onClick={closepopupchoosecust} ><RxCross2 className='fs-3'></RxCross2> </button>

    </div>
    </div>

    </div>
    <div className='col-12' onKeyDown={handlePresscustomrecord}>
    <div className='row justify-content-end'>
    <div className='col-12'>
    </div>
    <div className='col-12'>
    <div className='row'>
    <div className='col-3 '>

    <Dropdown className=' mx-5'>
    <Dropdown.Toggle  className='fontmaaray'  variant="success" id="dropdown-basic">
    الحاله
    </Dropdown.Toggle>

    <Dropdown.Menu >
    <Dropdown.Item onClick={searchallcustomer} className='fontmaaray' >الكل</Dropdown.Item>

    <Dropdown.Item onClick={searchnamecustomer} className='fontmaaray'>اسم</Dropdown.Item>

    <Dropdown.Item onClick={searchcodecustomer} className='fontmaaray'>كود</Dropdown.Item>
    <Dropdown.Item onClick={searchphonecustomer} className='fontmaaray'> تليفون</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>
    </div>
    <div className='col-6'>

    <input onKeyDown={handlePresscustomrecord} className='w-100 fontmaaray' style={{"margin-right": "7%","height": "37px",fontSize:"large"}} placeholder=' ادخل الاسم او الرقم او الكود' onChange={handleChangeinputsearch} type={'text'}></input>

    </div>

    <div className='col-2'>
    <div className='row searchofclient'>
    <div className='col-4'>
    <button  className='btn btn-success fontmaaray'   onClick={() => handleSearchcustomer(inputofsearch,searchcustomer)} >عرض</button>
    </div>
    </div>

    </div>
    </div>
    </div>



    </div>

    </div>
    <div className='container-fluid'>
    {/* justify-content-center */}
    <div className='row mt-2 ' style={{background:"rgb(213, 213, 213"}}>
    <div className='col-3'>
    <p className='fontmaaray'>اسم العميل </p>

    </div>
    <div className='col-3'>
    <p className='fontmaaray'>الكود</p>
    </div>
    <div className='col-3'>
    <p className='fontmaaray'>رقم التليفون</p>
    </div>
    </div>
    </div>
    {customersearch ?
    // justify-content-center
    <div className='row' style={{"overflow-x": "hidden",
    "overflow-y": "scroll",
    "max-height": "400px"}}>

    {customersearch.map((customer) => (
    <div className='col-12'  onClick={() => passthename(customer)}>
    <div className='row' >
    <div className='col-3' style={{cursor:"pointer"}}>
    <p className='fontmaaray'>{customer.firstName + customer.lastName }</p>
    </div>
    <div className='col-3' style={{cursor:"pointer"}}>
    <p className='fontmaaray'>{customer.id}</p>
    </div>
    <div className='col-3' style={{cursor:"pointer"}}>
    <p className='fontmaaray'>{customer.phoneNumber}</p>
    </div>
    </div>



    </div>

    ) )}
    </div>
    :""}
    </div>

    </div>



    :""}



          </div>



      <div className='col-lg-3 col-md-6 col-sm-12  mt-2' style={{"height":"calc(100vh - 20px)","overflow-y":"scroll","overflow-x": "hidden"}} >
      <div className='row justify-content-center roundedtitle' >
    <div  className='col-lg-12 col-sm-12 col-md-12 '>
    <div className='container-fluid ' style={{"border":"1px solid rgb(235 235 235)",borderRadius:"5px"}} >

    <div className='row shadow justify-content-center  roundedtitle maintitleoforder' >
    {/* <h1 className='col-1'>  <BsCart2  className='fs-1 mt-2' />  </h1> */}
    <div className='col-4' style={{height:"110px","border-left":"1px solid rgb(239, 237, 237)"}}>
    <div className='row' style={{"margin-top":"16%"}}>
      <img  onClick={reload} style={{width:"60px","margin-right":"20px",cursor:"pointer"}} src={require("../image/7091122.png")} />
    </div>
    <div className='row'>
    <p className='fontmaaray' style={{color:"#595959"}}> اوردر جديد  </p>
    </div>


    </div>
    <div className='col-4' style={{height:"110px"}}>
      <div className='col-12'>
      <h1 className='text-center  fontmaaray ' style={{"margin-top":"20%",color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"19px",fontWeight:"bold"}}>اوردر </h1>

      </div>
      {orderitemdetailNum==""||orderitemdetailNum==undefined ?
<div className='col-12'>
<p style={{"margin-bottom":"0px",fontSize:"19px",textAlign:"center",color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontWeight:"bold"}}> جديد</p>

</div>
:
     <div style={{display:"flex",justifyContent:"center"}}>
     <p style={{"margin-bottom":"0px",fontSize:"19px",textAlign:"right",color:"#7f7f7f","text-shadow":"2px 4px 4px #bfbfbf"}}>رقم</p>
     <p style={{"margin-bottom":"0px",fontSize:"19px",textAlign:"right",color:"#198754","text-shadow":"2px 4px 4px #bfbfbf"}}> {orderitemdetailNum} </p>
      </div>}


    </div>
    <div className='col-4 ' style={{padding:"0px","border-right":" 1px solid rgb(239, 237, 237)",height:"110px"}}>
    <div  style={{padding:"0px","margin-top":"17%",display:"flex"}}>
    <div className='col-6' style={{marginRight:"2px",paddingLeft:"0px"}}>
<p className='fontmaaray text-center' style={{color:"#595959",fontSize:"14px"}}> الاصناف</p>

</div>
       <div className='col-6'>
       <p className='fontmaaray' style={{color:"#e99221",fontSize:"14px"}}>{length}</p>

       </div>

        </div>
    <div  style={{padding:"0px","margin-top":"-10px",display:"flex"}}>
    <div className='col-6' style={{marginRight:"2px",paddingLeft:"0px"}}>
<p className='fontmaaray text-center' style={{color:"#595959",fontSize:"14px"}}> مطلوب</p>

</div>
<div className='col-6'>
       <p className='fontmaaray' style={{color:"#e99221",fontSize:"14px"}}>{Number(total.Nettotal).toFixed(2)}</p>

       </div>
    </div>

    </div>
    </div>
    {/*table of order item */}


    {/* <h6  className='col-1  fontmaaray'>كميه</h6>
    <h6  className='col-1  fontmaaray'>وحده</h6>
    <h6  className='col-3   fontmaaray'>صنف</h6>
    <h6  className='col-2   fontmaaray'>خصم</h6>
    <h6  className='col-3   fontmaaray'>سعر</h6>
    <h6  className='col-2   fontmaaray'>  إجمالي </h6> */}

    {/*maping from list to show order details [name, quntity,total discount,total ] */}
    {orderlist.map(order => (

    <div className='row justify-content-center fontmaaray' id="orderprint">

    <div className='col-12 card mt-2 shadow' style={{backgroundColor:"#f5f2df"}}>

  <div className='row' style={{borderBottom:"1px solid #ced1d2"}}>


  <div className='col-1'   style={{padding:"0px",marginRight:"7px"}} onClick={() => {handleSearchdetails(order.itemID);showinfoinitempopup()}}    >
   {/* <img src={require("../image/about.png")} style={{width:"77%"}}></img> */}
   <BsInfoSquareFill style={{fontWeight:"bold",fontSize:"18px",color:"#198553"}}></BsInfoSquareFill>
    </div>

    <div className='col-9 p-0' style={{"margin-right":"7px"}}  onClick={() => handleChangelastcomment(order.itemID) }>
{/* <img src={require("../image/chat.png")} style={{width:"100%"}}></img> */}
<div  className='row' style={{height:"25px"}}>
<div className='col-1'>
<BiCommentAdd style={{marginRight:"61",color:"#595959"}}></BiCommentAdd>

</div>
<div className='col-10'  onClick={() => handleChangelastcomment(order.itemID) }>
<p style={{color:"#595959",
    fontSize:"11px",
    marginTop: "4px",
    fontWeight: "bold"}}>اضافه ملاحظات </p>

</div>
</div>
</div>
<div className='col-1' style={{padding:"0px",marginRight:"7px"}} onClick={() => deletebutton(order.itemID,order) } >
    <BsTrash  style={{color:"rgb(245, 105, 2)",fontSize:"18px"}} ></BsTrash>
    </div>
  </div>

    <div className='row'>
    {/* style={{"border-left":"1px solid #ced1d2"}} */}
    <div className='col-4 p-0' >

<div className='row' style={{borderLeft:"1px solid rgb(206 209 210)",height:"139px",marginTop: "9px"}}>
    {  order.itemImage=="" || order.itemImage ==undefined ?
    <div className='col-12' onClick={() => {handleChangelastcomment(order.itemID);}}>
    <img src={require("../image/download.png")} style={{width:"80px"}}></img>

    </div>              :

    <div className='col-12' onClick={() => {handleChangelastcomment(order.itemID);}}>
        <img src={order.itemImage} style={{width:"80px",height:"80px"}}></img>
    </div>}
    <div className='col-12' style={{height: "18px"}}>
    <p className='text-center fontmaaray'  style={{padding: 0,fontSize: "12px","color": "rgb(124, 123, 123)","text-shadow":"rgb(141, 141, 141) 2px 4px 4px"}}> {order.unitName}</p>

    </div>
    <div className='col-9' style={{marginRight:"0.5px"}}>
    <div className='row text-white  btnright'  style={{background:"rgb(234, 143, 26)",padding:"0px",marginRight:"4%",borderRadius:"5px"}}  >
     <div className='col-2' style={{padding: 0,marginRight:"5px"}} >
            <button className='buttonitemcategory w-100 btn text-white' style={{paddingTop:"5px","margin-top":"-11px","margin-right":"-14px",borderRadius:"5px"}} onClick={() => btnonclick(order,'+')}><div style={{ fontSize:"24px"}}>&#43;</div></button>
            </div>
            <div className='col-8 p-0'>
              
            <input placeholder='ادخل الكميه ' dir="ltr"  step={0.01} onFocus={(event)=>{event.target.select();}} id='inputqty'  value = {Number(order.quantity)} className='w-100 fontmaaray inputquntinorder' onChange={(e) =>{handlechangeqty(e.target.value); handlechange(order.itemID,e.target.value);}} type={'number'}></input>

            </div>
            <div className='col-1 p-0' >
              {/* ,"width":"30px" */}
            <button className='btn  text-white  borderbtn'style={{background:"rgb(234, 143, 26)",fontSize:"17px"}}  onClick={() => btnonclick(order,'-')}><span>&#8722;</span></button>
            </div>
          </div>
    </div>
</div>
    </div>

    <div className='col-8' >
      <div className='row'>
      <p className='fontmaaray' style={{"text-align": "right","color": "darkred","text-shadow":"rgb(141, 141, 141) 2px 4px 4px","margin-right":"4%",fontSize:"22px"}}>{order.itemName}</p>

      </div>

    <div className='row' >
<div className='col-5 p-0' style={{marginRight:"24px"}}>
<div className='row' >
<div className='col-6 p-0'>
  <div className='row'>
<div className='col-10' style={{"height":"23px","margin-top":"10px","margin-right":"4px"}}>
<p style={{textAlign:"center",color:"#7c7b7b","text-shadow":"2px 4px 4px #8d8d8d",fontSize:"12px"}}>قبل</p>
</div>
<div className='col-10' style={{"border-left": "1px solid #c0c0c1",marginBottom:"11px",height:"24px"}}>
<del className=' fontmaaray' style={{"font-size": "17px","white-space":"nowrap",textAlign: "right",padding:"0px",color:"#c10a0a","text-shadow":"2px 4px 4px #bfbfbf",marginRight:"5px"}}>{Number(order.itemDiscountAmount).toFixed(2)} </del>

</div>
  </div>

</div>
<div className='col-5 p-0'>
<div className='row'>
<div className='col-12'style={{"height":"23px","margin-top":"10px"}}>
<p style={{textAlign:"center",color:"#7c7b7b","text-shadow":"2px 4px 4px #8d8d8d",fontSize:"12px"}}>بعد</p>

</div>
<div className='col-12 p-0' style={{"border-left": "1px solid #c0c0c1"}}>
<h6 className=' fontmaaray ' style={{color:"#198754",marginTop:"2px","margin-bottom":"0px",textAlign:"right",marginRight:"15px",fontSize:"19px"}} > {Number(order.finalPrice).toFixed(2)}</h6>

</div>
</div>
</div>
{/* style={{textAlign:"right"}} */}
{/* <div className='col-12 p-0' >
  <div className='row'>
  <div className='col-5 p-0' style={{marginRight:"13px",marginTop:"-7px"}}>
<del className=' fontmaaray' style={{"font-size": "12px","white-space":"nowrap",textAlign: "right",padding:"0px",color:"#c10a0a","text-shadow":"2px 4px 4px #bfbfbf"}}>{Number(order.itemDiscountAmount).toFixed(2)} </del>

</div>
<div className='col-5 p-0'>
<h6 className=' fontmaaray ' style={{"margin-bottom":"0px","margin-top":"-4px",textAlign:"center"}} >{Number(order.finalPrice).toFixed(2)}</h6>

</div>

  </div>


</div> */}
</div>

</div>
<div className='col-5 p-0' >
<div style={{"width":"60px","margin-right":"16px",marginTop:"9px",textAlign:"right"}}>
<div className='col-12' style={{height:"24px"}}>
<p style={{color:"#7c7b7b","text-shadow":"2px 4px 4px #8d8d8d",textAlign:"center","margin-top":"10px",fontSize:"12px"}}>اجمالي</p>
</div>

<div className='col-11'>
<p className='fontmaaray' style={{marginRight:"2px","font-size": "19px","text-align": "center","font-weight":"bold", "white-space":"nowrap",padding:"0px",color:"#198855","text-shadow":"2px 4px 4px #bfbfbf","margin-top":"-2px","margin-bottom":"0px"}}>{parseFloat(order.net_Total).toFixed(2)} </p>
</div>
</div>
</div>
</div>
    </div>


    </div>
    <div className='row'>

    </div>

    <div className='row'>

    </div>
    <div className='row'>
    <div className='col-12'>
    <div className='row' >
    {/* <div className='col-12'>
    <p className='fontmaaray' style={{"text-align": "right","color": "rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}}>{order.itemName}</p>

    </div> */}

    {/* <div className='row ' style={{padding:"0px",marginRight:"1%"}}>
    <p className='col-4'>{order.Total_Discount}</p>
    <p className='col-5 fontmaaray' style={{"font-size": "16px","text-align": "right","font-weight":"bold", "white-space":"nowrap",padding:"0px",color:"#198855","text-shadow":"2px 4px 4px #bfbfbf"}}>{Number(order.finalPrice).toFixed(2)} جم</p>

    <del className='col-4 fontmaaray' style={{"font-size": "16px","white-space":"nowrap",textAlign: "right",padding:"0px",marginTop:"1%",color:"#c10a0a","text-shadow":"2px 4px 4px #bfbfbf"}}>{Number(order.itemDiscountAmount).toFixed(2)} جم</del>


    </div> */}
    {/* <div className='row ' style={{padding: 0,marginRight:"0%",height:"20px"}}>
      <div className='col-3'>
        <p style={{fontSize:"12px"}}>جم</p>
      </div>
      <div className='col-5'>

      </div>
      <div className='col-4'>
        <p style={{fontSize: "12px"}}>اجمالي</p>
      </div>

    </div> */}
    {/* <div className='row' style={{"padding":"0px","margin-right":"0%"}}>
      <div className="col-3">
      <p className='fontmaaray' style={{"font-size": "16px","text-align": "right","font-weight":"bold", "white-space":"nowrap",padding:"0px",color:"#198855","text-shadow":"2px 4px 4px #bfbfbf","margin-top":"6px","margin-bottom":"0px"}}>{Number(order.finalPrice).toFixed(2)} </p>

      </div>
     <div className='col-5 p-0'>

     </div>
     <div className='col-4'>
      <p style={{"margin-bottom":"11px",marginTop:"5px"}}>
      {Number(order.finalPrice).toFixed(2)* (order.quantity)}
      </p>
      </div>
    <div className='col-lg-7 col-md-6 col-9' style={{padding:"0px","margin-right":"14%"}}>
            <div className='row text-white  btnright'  style={{background:"rgb(234, 143, 26)",padding:"0px",margin:"0px"}}  >
            <div className='col-2' style={{padding: 0,margin: 0}} >
            <button className='buttonitemcategory w-100 btn text-white' style={{padding:"0px",margin:"0px"}} onClick={() => btnonclick(order,'+')}>&#43;</button>
            </div>
            <div className='col-8'>
            <input placeholder='ادخل الكميه ' onFocus={(event)=>{event.target.select();}} id='inputqty' style={{"text-align": "center","backgroundColor":"rgb(234, 143, 26)","border":"none" ,"color":"white"}}  value = {parseFloat(order.quantity).toFixed(2)} className='w-100 fontmaaray' onChange={(e) =>{handlechangeqty(e.target.value); handlechange(order.itemID,e.target.value);   }} type={'number'}></input>

            </div>
          </div>

      </div>
      <div className='col-lg-1 col-md-2 col-2' >
            <button className='btn  text-white  borderbtn'style={{background:"rgb(234, 143, 26)","width":"30px"}}  onClick={() => btnonclick(order,'-')}>&#8722;</button>
            </div>

    </div> */}
    {/* <div className='row'>
    <div className='col-8 inputtextqty fontmaaray'>
            <input value = {order.quantity} className='w-100' onChange={(e) =>{handlechangeqty(e.target.value); handlechange(order.itemID,e.target.value);   }} type={'number'}></input>
            </div>
    </div> */}





    </div>
    </div>
    </div>
    </div>
    {lastcomment==true?
    <div className='col-lg-5 col-md-12 col-11 card showlastcomment'>
      {console.log("s",order)}
    <div className='row'  >
    <div className='col-12'>
    <div className='row justify-content-end'>
    <div className='col-lg-9 col-md-10 col-10'>
    <h1 className='text-center fontmaaray'> ملاحظات عن الصنف </h1>

    </div>

    <div className='col-2'>
    <button className='w-100 fs-4 close text-danger btn' onClick={handleclosepopup}><RxCross2></RxCross2> </button>

    </div>
    </div>

    </div>

    <div className='col-12'>
    <div className='row justify-content-center'>
    <div className='col-lg-6 col-md-10 col-11'>

  <textarea value={itemComment} onChange={handleChangecommentofitem} placeholder="ملاحظات و تعليقات" className="w-100 mt-2 fontmaaray placecommentarea"  style={{"outline": "none","height": "80px"}}></textarea>

    </div>
    </div>
    {/* <div className='row'>
    <div className='col-3'>
    <p>التعليق القديم</p>
    </div>
    <div className='col-6'>
    <p> {commentofitem} </p>
    </div>
    </div> */}
    <div className='row justify-content-center '>
    <div className='col-3'>
        <button className='btn btn-success fontmaaray'     onClick={() =>{{handleChangesurecomment(idofitemcomment); setpopupofcommentitem(false);setlastcomment(false)}}}>تعديل</button>
    </div>
    <div className='col-3'>
    <button  className='btn btn-danger fontmaaray' onClick={() =>{handledeletecommentofitem(idofitemcomment);  setpopupofcommentitem(false); setlastcomment(false)}}> حذف</button>
    </div>
    </div>
    </div>



    </div>
    </div>
    :""}
       {popupofcommentitem==true?
    <div className='col-lg-5 col-md-12 card showpopupofcomment' >
    <div className='row'  >
    <div className='col-12'>
    <div className='row justify-content-end'>
    <div className='col-lg-8 col-md-10 col-10'>
    <h1 className='text-center fontmaaray'> ملاحظات عن الصنف </h1>

    </div>

    <div className='col-2'>
    <button  onClick={handleclosepopup}  className=' fs-4 close text-danger btn'><RxCross2></RxCross2> </button>

    </div>
    </div>

    </div>
    <div className='col-12'>
    <div className='row justify-content-center'>
    <div className='col-lg-6 col-md-10 col-10'>
    <textarea onChange={handleChangecommentofitem} value={itemComment} placeholder="ملاحظات و تعليقات" className="w-100 mt-2 fontmaaray"  style={{"outline": "none","height": "80px"}}></textarea>
    </div>
    </div>
    <div className='row justify-content-center '>
    <div className='col-lg-3 col-md-3 col-5'>
    <button className='btn btn-success fontmaaray w-100' onClick={() =>{{handleChangesurecomment(order.itemID); setpopupofcommentitem(false);setlastcomment(false)}}}>تاكيد</button>
    </div>
    <div className='col-lg-3 col-md-3 col-5'>
    <button  className='btn btn-danger fontmaaray w-100'  onClick={() =>{handledeletecommentofitem(order.itemID);  setpopupofcommentitem(false); setlastcomment(false)}}> حذف</button>
    </div>
    </div>
    </div>



    </div>
    </div>
    :""}
    

    {/* <h6 className='col-1 fontmaaray ' >{order.quantity}</h6>
    <h6 className='col-1 fontmaaray ' >{order.unitName}</h6>
    <h6 className='col-3 fontmaaray ' >{order.itemName}</h6>
    <h6 className='col-2 fontmaaray  ' >{Number(order.Total_Discount).toFixed(2)}  </h6>
    <h6 className='col-3 fontmaaray '  >{Number(order.salePrice).toFixed(2)}</h6>
    <h6 className='col-2 fontmaaray '  >{Number(order.salePrice - (order.Total_Discount * order.quantity )).toFixed(2) }</h6> */}
    </div>


    ))}


    {/*total of order  */}




    <div className='row  fontmaaray justify-content-center' id='myTable'>

    {/* <h6  className='col-1  fontmaaray'>كميه</h6>
    <h6  className='col-1  fontmaaray'>وحده</h6>
    <h6  className='col-3   fontmaaray'>صنف</h6>
    <h6  className='col-2   fontmaaray'>خصم</h6>
    <h6  className='col-3   fontmaaray'>سعر</h6>
    <h6  className='col-2   fontmaaray'>  إجمالي </h6> */}

    {/*maping from list to show order details [name, quntity,total discount,total ] */}
    {orderitemdetail.map(orderitemdetail => (

    <div className='row justify-content-center fontmaaray'>
    <div className='col-12 card mt-2'>
    <div className='row'>
    <div className='col-4'>
    {  orderitemdetail.itemImage=="" || orderitemdetail.itemImage ==undefined ?
    <div className='col-12' style={{backgroundImage:`url(${require("../image/download.png")})`,  backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',  height:"100%",

    backgroundPosition: 'center'}}>

    </div>              :
    <div className='col-12' style={{backgroundImage:`url(${orderitemdetail.itemImage})`,  backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',  height:"100%",

                backgroundPosition: 'center'}}>

    </div>}
    </div>
    <div className='col-8'>
    <div className='row'>
    <p className='col-12' style={{"text-align": "right"}}>{orderitemdetail.itemName}</p>
    <div className='row'>
    {/* <p className='col-4'>{order.Total_Discount}</p> */}
    <del className='col-6 fontmaaray' style={{"font-size": "small","white-space":"nowrap"}}>{Number(orderitemdetail.itemDiscountAmount).toFixed(2)} جم</del>
    <p className='col-6 fontmaaray' style={{"text-align": "end","font-size":"medium","white-space":"nowrap"}}>{Number(orderitemdetail.finalPrice).toFixed(2)} جم</p>

    </div>

    <div className='row justify-content-center'>
    <div className='col-lg-8 col-md-6 col-sm-4'>
            <button className='btn  w-100  text-white  btnright'  style={{background:"rgb(245, 105, 2)"}} onClick={() => btnonclick(orderitemdetail,'+')} >
            <span className='margespan '>&#43;</span>
            <span  id='quantity' className=' fontmaaray' >{orderitemdetail.quantity}</span>
          </button>
      </div>
      <div className='col-2' style={{"margin-right": "-10%"}}>
            <button className='btn  text-white  borderbtn fontmaaray'style={{background:"rgb(245, 105, 2)"}}  onClick={() => btnonclick(orderitemdetail,'-')}>&#8722;</button>
            </div>
    </div>

    <div className='row justify-content-end' style={{padding: 0,}}>
    <p className='col-8 text-start fontmaaray'  style={{padding: 0,}}> {orderitemdetail.unitName}</p>
    </div>




    </div>
    </div>
    </div>
    </div>

    {/* <h6 className='col-1 fontmaaray ' >{order.quantity}</h6>
    <h6 className='col-1 fontmaaray ' >{order.unitName}</h6>
    <h6 className='col-3 fontmaaray ' >{order.itemName}</h6>
    <h6 className='col-2 fontmaaray  ' >{Number(order.Total_Discount).toFixed(2)}  </h6>
    <h6 className='col-3 fontmaaray '  >{Number(order.salePrice).toFixed(2)}</h6>
    <h6 className='col-2 fontmaaray '  >{Number(order.salePrice - (order.Total_Discount * order.quantity )).toFixed(2) }</h6> */}
    </div>


    ))}


    {/*total of order  */}


    </div>

    </div>
    <div className='container-fluid  mt-3' style={{"border":"1px solid rgb(235 235 235)",borderRadius:"5px"}}>
    <div className='row  shadow' >

    <div className='row' style={{marginTop:"7px"}}>
<div className='col-8'>
<h6 className=' fontmaaray' style={{marginRight:"5px",textAlign:"right",color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold"}}>  الكميه</h6>

</div>
<div className='col-4'>
<h6 className='  fontmaaray ' style={{'text-align':'right',color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold" }}>({ Number(total.totalqut).toFixed(2)})</h6>

</div>
    </div>
    <div className='row' style={{marginTop:"-4px"}}>
<div className='col-8'>
<h6 className=' fontmaaray' style={{marginRight:"5px",textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold"}} > اجمالي</h6>

</div>
<div className='col-4'>
<h6 className=' fontmaaray'  style={{'text-align':'right',color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold" }}> {Number(total.Nettotal ).toFixed(2)}</h6>

</div>
    </div>
    <div className='row' style={{marginTop:"-4px"}}>
<div className='col-8'>
<h6 className=' fontmaaray'  style={{marginRight:"5px",'text-align':'right',color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold" }}> الوفر</h6>

</div>
<div className='col-4'>
<h6 className=' fontmaaray'  style={{'text-align':'right',color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold" }}> {parseFloat(total.totaldiscount).toFixed(2)}</h6>

</div>
    </div>

    <div className='row' style={{marginTop:"-4px"}}>
<div className='col-8'>
<h6 className='  fontmaaray' style={{marginRight:"5px",'text-align':'right' ,'white-space':'nowrap',color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold"}}> المطلوب </h6>

</div>
<div className='col-4'>
<h6 className=' fontmaaray'  style={{'text-align':'right',color:"rgb(25 135 84)","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold"}}>{parseFloat(total.Nettotal).toFixed(2)}</h6>

</div>


    </div>
    <div className='row' style={{marginTop:"-4px"}}>
   <div className='col-8'>
   <h6 className=' fontmaaray' style={{marginRight:"5px",'text-align':'right',color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold"}}>  عدد الأصناف </h6>

   </div>
<div className='col-4'>
<h6 className=' fontmaaray' style={{'text-align':'right',color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px",fontWeight:"bold"}}>{length}</h6>

</div>
    </div>

    </div>


    </div>

    <div className='container-fluid mt-3' style={{"border":"1px solid rgb(235 235 235)",borderRadius:"5px"}} >
    <div className='row shadow'>


    <div className="col-lg-12 col-md-12 col-sm-12 mt-2 fontmaaray shadow" >


    <textarea onChange={handleChangemessage} placeholder="ملاحظات و تعليقات"  className='placecommentarea'  style={{"outline": "none","height": "80px",width:"100%",border:"1px solid #d9d9d9",fontSize:"15px"}}></textarea>


    </div>
    </div>


    </div>
    <div className="container-fluid  widthborder  mt-3 "  style={{"border":"1px solid rgb(235 235 235)",borderRadius:"5px"}}>
    <div className="row shadow">


    <h5 className="text-success fontmaaray mt-2 " style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} dir="rtl">إرسال رسالة صوتية</h5>
    <div className="container-fluid">
    <div className="row  rounded-1">
    {/* div have btn stop record and start record and sure of record */}



    <div className="col-1  mt-3" style={{"padding":"0px","margin-right":"6%"}} >
    {isRecording ? <button className="btn btn-danger" onClick={alert}>انهاء</button> : <BsMic    className="fs-4 h-75" id="btnwave" onClick={handleClick} style={{
    color: 'green'}}/>}
    </div>


    <div className='col-7 ' style={{"padding":"0px","margin-right":"5%"}}>

    {isRecording ? <img className="heightvoicerecord" style={{height:"70px",marginRight:"11%"}} src={require("../image/wave-record.gif")} id="wave"></img>
    : <audio style={{width:"100%"}} src={blobURL}  controls id="audio" className="mt-2 heightvoicerecord "/>}

    </div>
    <div className="col-1" style={{padding:"0px","margin-right":"9%"}} >
    <BsTrash3 className="fs-5 deletecolorbutton h-100 text-danger"style={{marginTop:"4px"}} onClick={resetaudio}></BsTrash3>
    </div>


    </div>
    </div>
    </div>
    </div>
<div className='container-fluid mt-3'  style={{"border":"1px solid rgb(235 235 235)",borderRadius:"5px"}}>
<div className='row  shadow' >
<div className='col-12'>
{payment==3?
  <h5 style={{"text-align":"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} className='mt-2'>اختر طريقة الدفع <span style={{color:"rgb(25, 135, 84)"}}>( &nbsp; كاش &nbsp; )</span></h5>


:""}
{payment== 1 ?
  <h5 style={{"text-align":"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} className='mt-2'>اختر طريقة الدفع <span style={{color:"rgb(25, 135, 84)"}}>( &nbsp; فيزا &nbsp; )</span></h5>


:""}
</div>
    <div className='col-4  mt-2' style={{"margin-bottom":"5px"}}>
    <button className='paymentbutton btn h-100 w-100' id='cash' style={{"border":"1px solid rgb(245, 105, 2)" , "background":"#E8E8E8"}}  onClick={cashpayment} name={'visa'} >
   <img src={require("../image/Money.png")}></img>
    </button>
    </div>
    <div className='col-4  mt-2' style={{"margin-bottom":"5px"}}>
    <button className='paymentbutton btn' id='visa'   onClick={visapayment}  name={'visa'} >
    <img className='w-100' src={require("../image/visa.png")}></img>
    </button>

    </div>

    </div>
</div>
    </div>
      </div>


      <div className='row justify-content-center' style={{marginBottom:"8px"}}>
      <div className='col-12'>
        {LoadingSubmitOrder == false ?  <button  type='submit ' style={{background:"rgb(245, 105, 2)",fontWeight:"bold"}} className="btn h-100 w-100  mt-3 fs-5 text-white  submitheight fontmaaray" onClick={suborder}><FiSend className='fs-5 iconsend mt-2'>
        </FiSend>ارسل  </button>
     : <button  type='submit ' style={{background:"rgb(245, 105, 2)",fontWeight:"bold"}} className="btn h-100 w-100  mt-3 fs-5 text-white  submitheight fontmaaray"><FiSend className='fs-5 iconsend mt-2'>
</FiSend>جاري الارسال  </button>}
     
      </div>

      </div>
      <div className="row justify-content-center" style={{marginTop:"29px",marginBottom:"8px"}}>
<div className="col-lg-6 col-md-6 col-3 ">
  <div className="row heightofimglogo" style={{}} onClick={showpopuptijary} >
<img style={{height:"100%",width:"auto",marginLeft:"3%"}} src={require("../image/logotijary.png")}></img>
  </div>
{/* <div className="row">
<p style={{whiteSpace:"nowrap",margin:"0px"}}>devleop by </p>

</div>
<div className="row">
<a href="http://bedab.com/contact.html" target="_blank">  <img style={{    width: "60%"}} src={require("../image/Bedab-Logo-Blue.png")}  />
</a>
</div> */}
</div>
</div>

    <div>


    </div>






      </div>
{poppofextraitem==true?
<div className='col-lg-8 col-md-8 col-12 card popupextraitemsearch' >
{/* <div>
      <QrReader  onError={(err) => console.log(err)} onScan={handleScan} style={{ width: '100%' ,zIndex:"900000000000000"}} />
      <p>{result}</p>
    </div> */}
<div className='row card-header'>
<div className='col-lg-11 col-md-11 col-10'>
<h2 style={{textShadow:"rgb(191, 191, 191) 2px 4px 4px",color:"rgb(89, 89, 89)"}}> قائمه الاصناف </h2>
</div>
<div className='col-lg-1 col-md-1 col-1 ' onClick={hidepopupextraitem}>
<RxCross2 className='text-danger fs-4' style={{marginTop:"26%"}}>

</RxCross2>
</div>
</div>
<div className='row justify-content-center'>
<div className='col-lg-8 col-md-8 col-11'>
<div className='row' style={{height:"45px",backgroundColor:"white"}}>
<div className='row ' style={{marginTop:"0.6%"}} >
    {/* <div class="input-group mb-3 col-12" >
    <div class="input-group-prepend col-10" >
    <input type="search" class="form-control" placeholder="بحث"  />

    </div>
    <z class="btn bg-white col-2" style={{borderLeft:"none"}}><BsSearch></BsSearch></button>
    </div> */}
    <div className='row'>
      <div className='col-12 fontmaaray reactsearch' style={{direction:"ltr","margin-top":"1.5%"}}   >
     <div className='row shadow ' style={{borderRadius:"5px"}}>
<div className='col-1' onClick={showpopupsearchbybarcode}>

<BiBarcodeReader style={{marginRight:"-20px",
    zIndex:"10000",
    marginTop:"10px",
    color: "#f56902",
    fontSize:"21px",position:"relative"}} ></BiBarcodeReader>


</div>
<div className='col-lg-11 col-md-11 col-10' style={{height:"37px"}}>


<div className='col-12' style={{display:"flex",height:"100%",backgroundColor:"white",borderRadius:"5px"}}>
<p style={{marginTop:"10px",color:"white",background:"#e26102",cursor:"pointer",height:"20px",width:"72px",fontsize:"14px",borderRadius:"3px"}}>{lengthsearchitem}</p>

<input className='w-100 h-100 fontmaaray'
style={{textAlign:"right" ,direction:"rtl",border:"none",borderRadius:"5px",fontSize:"17px"}}
        type="text"
        name="message"
        defaultValue={message2}
        onChange={changetextinpoupextra}
        id='textsearchpopupextraitem'
      />
      <RxCross2 onClick={clearsearchtextpopupextraitem} style={{marginRight: "1px",
    marginTop:"11px"}} className='text-danger'></RxCross2>
</div>




</div>
     </div>

      </div>


    </div>


    </div>

</div>
</div>

  {/* <div className='col-lg-2 mt-2' style={{"margin-right":"69px","padding":"0px"}}>
<button className='btn btn-success w-100'>
عرض
</button>
  </div> */}
</div>
<div className='row mt-2' style={{background:"rgb(213, 213, 213)"
,border:"1px solid darkgray"
,borderRadius:"4px"}}>
<div className='col-lg-6 col-md-5 col-5'style={{"margin-top":"2%"}}>
<p className='headerofpopupextraitem' >اسم </p>
</div>
<div className='col-lg-2 col-md-3 col-3' style={{"margin-top":"2%"}}>
<p className='headerofpopupextraitem'>  كود</p>
</div>

<div className='col-lg-2 col-md-2 col-2 p-0' style={{"margin-top":"2%"}}>
<p className='headerofpopupextraitem'> بعد الخصم</p>
</div>
<div className='col-lg-2 col-md-2 p-0 col-2' style={{"margin-top":"2%"}}>
<p className='lastheaderpopupextraitem'> قبل الخصم</p>
</div>
</div>
{itemlist?
<div style={{ overflow:"hidden scroll"}}>
{itemlist.map((extraitem) => (
<div className='row'>
<div className='col-lg-6 col-md-5 col-5'>
<p className='datathatinpopupextraitem'>{extraitem.itemName}</p>
</div>
<div className='col-lg-2 col-md-3 col-3'>
<p className='datathatinpopupextraitem' >{extraitem.itemID}</p>
</div>
<div className='col-lg-2 col-md-2 col-2 p-0'>
<p className='datathatinpopupextraitem' >{extraitem.finalPrice}</p>
</div>
<div className='col-lg-2 col-md-2 col-2 p-0'>
<p className='lastdatathatinpopupextraitem' >{extraitem.salePrice}</p>
</div>
</div>

))}
</div>



:""}


</div>






:""}




{searchitempopup==true?

<div className='popupsearch card p-0' >
  {itemlist?
  <>
     {loadingsearch==true?
         <Spinner animation="border" style={{marginRight:"40%"}} role="status">

         </Spinner>:
         <>
               {itemlist.map((searchitemlist) => (
    <div>
   
      <div className='row'>
      <div className='col-11' style={{cursor:"pointer"}} onClick={() => {handleOnSelect(searchitemlist,searchitemlist.itemID);setsearchitempopup(false)}}>
      <div className='row'  >
       {result.itemImage =="" || result.itemImage==null ?
        <div className='col-1 p-0' style={{    marginRight: "15px"}}>
        <img  style={{width:"25px",height:"25px"}} src={require("../image/download.png")} />

        </div>
        :  <div className='col-1 p-0' style={{marginRight: "15px"}}>

          <img style={{width:"100%"}} src={searchitemlist.itemImage}></img>
        </div>}

<div className='col-10'>
<p style={{textAlign:"right"}}> {searchitemlist.itemName}</p>
</div>
       </div>
      </div>
<div  className='col-1'  style={{padding:"0px",marginRight:"-18px",cursor:"pointer"}} onClick={() => {handleSearchdetails(searchitemlist.itemID);showinfoinitempopup()}}    >
   {/* <img src={require("../image/about.png")} style={{width:"77%"}}></img> */}
   <BsInfoSquareFill  style={{fontWeight:"bold",fontSize:"18px",color:"#198553"}}></BsInfoSquareFill>
    </div>

  </div>
      
   <div>



  </div>
    </div>

  ))}
         
         
         </>
         
         
         
         }

     {lengthsearchitem>9 ?
<div className='row justify-content-center'>
<div className='col-10' style={{marginBottom:"7px",backgroundColor:"rgb(239, 237, 237)",cursor:"pointer",height:"32px"}}>
   <p   data-bs-toggle="tooltip" data-bs-placement="top" title="عرض كامل لنتيجه البحث" className=' w-100 text-danger'  style={{textDecoration:"none",fontsize:"14px",fontWeight:"bold",marginTop:"4px"}} onClick={showpopupextraitem}
  >   عرض المزيد
  </p>
 </div>
</div>
  :""}
   {lengthsearchitem==0 ?
<p>No Result</p>
  :""}

  </>

  :""}


</div>

:""}
{searchbybarcode==true?
<div className='col-lg-3 col-md-4 col-12 card searchbarcodepopup' style={{}}>
<div className='row card-header'>
<div className='col-10'>
<h3 style={{    textShadow: "rgb(191, 191, 191) 2px 4px 4px",
    color: "rgb(25 135 84)",fontWeight:"bold"}}>بحث باركود صنف </h3>
</div>
<div className='col-1' >
  <button className='btn' onClick={hidepopupsearchbybarcode}>
  <RxCross2 className='fs-4 text-danger'></RxCross2>

  </button>
</div>
</div>
<div className='row' style={{height:"250px"}}>
<Webcam
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      videoConstraints={videoConstraints}
      height={"200px"}
    />

{/* <QrReader
          delay={500}
          style={{height:"150px"}}
          onError={handleError}
          onScan={handleScan}
          /> */}
</div>
<div className='row justify-content-center'>
        <div className='col-11' style={{border:"1px solid",height:"30px"}}>
     <button onClick={scanBarcode}>Scan Barcode</button>
     <div>barcode {barcode}</div>
        </div>
      </div>
{/* <div className='row'>
<div className='col-2'>
<p>رقم:</p>
</div>
<div className='col-8'>
<p></p>
</div>
</div> */}
</div>
:""}




{scanpopupcust==true?
  <div className='col-lg-3 col-md-3 col-10 popupofscancode card' >
<div className='row card-header'>
<div className='col-10'>
<h3 style={{    textShadow: "rgb(191, 191, 191) 2px 4px 4px",
    color: "rgb(25 135 84)",fontWeight:"bold",marginTop:"3%"}}>مسح باركود صنف </h3>
</div>
<div className='col-1' >

  <button className='btn' onClick={hidescanpopupcust}>
  <RxCross2 style={{marginTop:"37%"}} className='fs-4 text-danger'></RxCross2>

  </button>
</div>
</div>
<div className='row' style={{height:"250px"}}>
<>
      {/* <video ref={ref} /> */}


      {/* <p>
        <span>Last result:</span>
        <span>{result}</span>
      </p> */}
    </>
    <div className='col-11'>
    <BsQrCodeScan style={{width:"100%",height:"160px",marginTop:"40px",color:"rgb(245, 105, 2)",marginRight:"11px"}} className='fs-5'></BsQrCodeScan>

    </div>
</div>
<div className='row justify-content-center'>
        <div className='col-11' style={{border:"1px solid",height:"30px"}}>

        </div>
      </div>
{/* <div className='row'>
<div className='col-2'>
<p>رقم:</p>
</div>
<div className='col-8'>
<p></p>
</div>
</div> */}
</div>
:""}

    </div> :  ""


    }
    {scanpopupitem==true?
  <div className='col-lg-3 col-md-4 col-12 popupscanpopupitem card' >
<div className='row card-header'>
<div className='col-10'>
<h3 style={{    textShadow: "rgb(191, 191, 191) 2px 4px 4px",
    color: "rgb(25 135 84)",fontWeight:"bold"}}>مسح باركود عميل </h3>
</div>
<div className='col-1' >
  <button className='btn' onClick={hidescanpopupitem}>
  <RxCross2 className='fs-4 text-danger'></RxCross2>

  </button>
</div>
</div>
<div className='row' style={{height:"250px"}}>
<>
      {/* <video ref={ref} /> */}


      {/* <p>
        <span>Last result:</span>
        <span>{result}</span>
      </p> */}
    </>
    <div className='col-11'>
    <BsQrCodeScan style={{width:"100%",height:"160px",marginTop:"40px",color:"rgb(245, 105, 2)",marginRight:"11px"}} className='fs-5'></BsQrCodeScan>

    </div>
</div>
<div className='row justify-content-center'>
        <div className='col-11' style={{border:"1px solid",height:"30px"}}>

        </div>
      </div>
{/* <div className='row'>
<div className='col-2'>
<p>رقم:</p>
</div>
<div className='col-8'>
<p></p>
</div>
</div> */}
</div>
:""}
      {showpopupneworder==true?
    <div className='row '>
    <div className='col-lg-4 col-md-7 col-10 card popupofreqneworder' >
    <div className='row' style={{"justify-content":"end"}}>

<div className='col-lg-1 col-md-1 col-2'  style={{padding: "0px","margin-left":"4%"}}>
<button onClick={cancelneworder}  className='fs-4 close  btn ' style={{color:"#fa4437"}}><RxCross2></RxCross2> </button>

</div>

</div>
    <div className='row justify-content-center'>

    <div className='col-lg-9 col-md-8 col-11 '>
    <h3 className='fontmaaray' style={{"font-weight":"bold",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>سيتم حذف الطلب الحالي وانشاء طلب جديد</h3>
    </div>
    <div className='col-12' >
    <div className='row justify-content-center mt-4'>
    <div className='col-lg-2 col-md-3 col-4' style={{padding:"0px"}}>
    <button className='btn btn-success w-100 fontmaaray' onClick={neworder}>تاكيد</button>

    </div>

    <div className='col-lg-2  col-md-3 col-4 mx-3' style={{padding:"0px"}}>
    <button className='btn  w-100 fontmaaray' style={{background:"#c00000",color:"white"}} onClick={cancelneworder} >الغاء</button>



    </div>

    </div>
    </div>
    </div>
    </div>



    </div>:""}

{xredpoup ==true ?
// ,"overflow-y":"scroll"
 <div className='col-lg-8 col-md-9 card shadow Xredpopuop'style={{backgroundColor:"rgb(239, 237, 237)","overflow-x": "hidden"}}>


 <div className='col-12 card-header p-0'>
 <div className='justify-content-center' style={{display:"flex"}}>
 <div className='col-lg-11 col-md-11 col-10 mt-2' style={{padding: "0px"}}>
<div className='d-flex justify-content-center'>
<div className='col-5'>
  <div className='row justify-content-center' style={{"font-size":"18px","margin-top":"7px"}}>
 <div className='col-lg-2 col-md-3 col-5' style={{padding:"0px"}}>
 <p className='fontmaaray dateinxredpopup' >التاريخ:</p>
 </div>
 <div className='col-lg-4 col-md-9 col-7' style={{padding:"0px"}}>
 <p className='fontmaaray dateinxredpopup'  style={{textAlign:"right"}}>{toDay}</p>
 </div>
 </div>
  </div>
  <div className='col-4'>
 <p className='text-center fs-5 fontmaaray'  style={{color:"#198754","font-weight":"bold"}}>تقرير x</p>

  </div>

  <div className='col-lg-3 col-md-3 col-3' style={{marginTop:"7px"}} >
  <p style={{textAlign:"center"}} className='dateinxredpopup'>  ({timetoday}) </p>
 </div>
</div>
 </div>
 <div className='col-lg-1 col-md-1 col-1' style={{padding: "0px"}}>
 <button onClick={hidexredpopup} data-bs-toggle="tooltip" data-bs-placement="left" title="خروج"  className='fs-4 close text-danger btn'><RxCross2 data-bs-toggle="tooltip" data-bs-placement="left" title="خروج"></RxCross2> </button>

 </div>
 </div>


 </div>
 {/* <div className='col-12'>
 <div className='row justify-content-center'>
 <div className='col-lg-1 col-md-1 col-2' style={{padding:"0px"}}>
 <p className='fontmaaray'>التاريخ:</p>
 </div>
 <div className='col-lg-2 col-md-3 col-3' style={{padding:"0px"}}>
 <p className='fontmaaray'>{toDay}</p>
 </div>
 </div>

 </div> */}

 <div className='container-fluid'>
 {Xred  ?
 <div className='row xredcontain' style={{"margin-right": "2%"}}>

 <div className='col-12'>
 <div className='row'>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8'>
 <p className='fontmaaray'>عدد الاوردارات الملغاه
 </p>
 </div>
 {Xred.cancelledOrderCount == null
 ? <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>0</p>

 </div>:
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>
 {Xred.cancelledOrderCount}
 </p>

 </div>}

 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد طلبات الطاولات الملغاه
 </p>
 </div>
 {Xred.cancelledTableOrderCount==null?
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>0</p>
 </div>
 :<div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.cancelledTableOrderCount}</p>
 </div>}

 </div>
 </div>

 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>اجمالي الكاش علي الطاولات
 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.cashTableTotal}</p>
 </div>
 </div>
 </div>

 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>اجمالي الكاش علي الطاولات
 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.cashTotal}</p>
 </div>
 </div>
 </div>

 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد الطلبات المسدده

 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4' >
 <p className='fontmaaray'>{Xred.collectedOrderCount}</p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد الطلبات المسدده علي الطاولات


 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.collectedTableOrderCount}</p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد الطلبات

 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.ordersCount}</p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>اجمالي الطلبات

 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.ordersTotal}</p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد الطلبات علي الطاولات


 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.tableOrdersCount}</p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>اجمالي الطلبات علي الطاولات

 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.tableOrdersTotal}
 </p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد الطلبات المفتوحه


 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4 '>
 <p className='fontmaaray'>{Xred.underProgressOrderCount}
 </p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>عدد الطلبات المفتوحه علي الطاولات


 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'> {Xred.underProgressTableOrderCount}
 </p>

 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>اجمالي الفيزا علي الطاولات



 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.visaTableTotal}
 </p>
 </div>
 </div>
 </div>
 <div className='col-lg-6 col-md-6 col-12'>
 <div className='row'>
 <div className='col-lg-8 col-md-7 col-8 p-0'>
 <p className='fontmaaray'>اجمالي الفيزا



 </p>
 </div>
 <div className='col-lg-4 col-md-5 col-4'>
 <p className='fontmaaray'>{Xred.visaTotal}
 </p>
 </div>
 </div>
 </div>
 </div>

 <div className="row justify-content-center">
 <div className="col-lg-4 cl-md-4 col-6 btnofprintxred" style={{"margin-left":"4%"}}>
 <button className="btn bg-success text-white w-100 fontmaaray"  onClick={print} > <AiFillPrinter className='fs-4'></AiFillPrinter> طباعة</button>
 </div>
 </div>




 </div>
 </div>


 :""}
 </div>
 </div>


:""}

{newuserpopup ==true ?
 <div className='col-lg-4 col-md-6 card shadow Popupaddnewcustomer' onKeyDown={handlePresssubnewuser} style={{backgroundColor:"rgb(239, 237, 237)"}}>
 <div className='row card-title' style={{"background":"rgb(239, 237, 237)"}} >
 <div className='col-12 card-header'>
 <div className='row justify-content-end'>

 <div className='col-9'>
  <div className='row'>
<div className='col-3'>
<img src={require("../image/Customers.png")} style={{width:"85%"}}></img>
</div>
<div className='col-9 mt-2'>
<h3 className='text-center fontmaaray headofaddnewcustomer' style={{ "text-shadow":"2px 4px 4px #595959",color:"#595959"}}>اضافه عميل جديد  </h3>

</div>
  </div>

 </div>

 <div className='col-2'>
 <button onClick={hidenewuserpopup} data-toggle="tooltip" data-placement="right" title="خروج " style={{padding:"0px"}} className='mt-2 fs-4 close btn text-danger'><RxCross2></RxCross2> </button>

 </div>
 </div>

 </div>

 <div className='col-12' >
 <div className='row justify-content-center'   >
 <div className='col-10 '>

 <div className='row justify-content-center mt-3'>
 <div className='col-lg-9 col-md-8 p-0'>
 <input onKeyDown={handlePresssubnewuser} onChange={handlePhoneNumber} className='w-100 fontmaaray' type={"number"} placeholder='رقم التليفون ' style={{"height":"35px","font-size":"17px"}} ></input>
 </div>
 </div>
 </div>


 </div>
 <div className='col-8'>
 <div className='row'>
 <p className='text-center fontmaaray'>{errorlsname}</p>
 </div>
 </div>
 <div className='row justify-content-center' >
 <div className='col-10'>

 <div className='row justify-content-center '>
 <div className='col-lg-9 col-md-8 p-0' >
 <input id='nameofaddcustomer'  autoFocus={true} style={{"height":"35px","font-size":"17px"}} onKeyDown={handlePresssubnewuser} onChange={handleSetfirstname} className='w-100 fontmaaray' type={"text"} placeholder='الاسم بالكامل' ></input>
 </div>
 </div>
 </div>


 </div>
 {/* <div className='col-8'>
 <div className='row'>
 <p className='text-center'>{errorfsname}</p>
 </div>
 </div> */}
 {/* <div className='row'>
 <div className='col-lg-2 col-md-3'>
 <p>الاسم الاخير</p>
 </div>
 <div className='col-lg-8 col-md-7'>
 <input onKeyDown={pressenter4} onChange={handleSetLastname} className='w-50' type={"text"} ></input>
 </div>
 </div> */}


 <div className='col-8'>
 <div className='row'>
 <p className='text-center fontmaaray'>{errorPhonenumber}</p>
 </div>
 </div>
 <div className='row justify-content-center'  >
 <div className='col-10'>

 <div className='row justify-content-center'>
 <div className='col-lg-9 col-md-8 p-0'>
 <textarea maxlength="500" style={{outline:"none","height":"100px","font-size":"17px"}} onKeyDown={handlePresssubnewuser} onChange={handleAddress} className='w-100 fontmaaray' placeholder='العنوان'></textarea>
 </div>
 </div>
 </div>


 </div>
 <div className='col-8'>
 <div className='row'>
 <p className='text-center fontmaaray'>{errorAdderess}</p>
 </div>
 </div>

 </div>

 <div className='col-12' >
 <div className='row justify-content-center'>
 <div className='col-lg-4 col-md-6 col-10  p-0 shadow' style={{height:"50px"}}>

 <button onClick={subnewuser}className='btn h-100 w-100  fs-5 text-white   fontmaaray' style={{backgroundColor:"rgb(25, 135, 84)"}} > اضافه</button>
 </div>
 </div>
 </div>
 </div>

 </div>:""}

    {customerrecord == true ?
      <div className='col-lg-8 col-md-9 col-12 card shadow Popupcustomerstyle'  style={{backgroundColor:"rgb(239, 237, 237)"}}>
      <div className='row card-title Popupcustomerstyledesc' style={{"background":"rgb(239, 237, 237)"}} >
      <div className='col-12'>
      <div className='row justify-content-end card-header' style={{height:"70px"}}>
        <div className='col-lg-1 col-md-1 col-3 divimgcustomer' >
        <img src={require("../image/Customers.png")} style={{"height":"60px","width":"60px","margin-top":"5%"}}/>
        </div>
      <div className='col-lg-4 col-md-9 col-7 imgrecordcustomer' >
      <h2 className='text-center fontmaaray' style={{"text-shadow":"2px 4px 4px #bfbfbf",color:"#595959"}}>قائمه العملاء</h2>

      </div>

      <div className='col-lg-1 col-md-1 col-2' style={{padding:"0px"}} >
      <button onClick={hidecustomerpopup}  data-bs-toggle="tooltip" data-bs-placement="left" title="خروج" style={{padding:"0px","margin-top":"22%"}} className=' btn  close text-danger' ><RxCross2 className='fs-4' data-bs-toggle="tooltip" data-bs-placement="left" title="خروج  "></RxCross2> </button>

      </div>
      </div>

      </div>
      <div className='col-11' style={{marginRight:"4%"}} onKeyDown={handlePresscustomrecord}>
      <div className='row '>
      <div className='col-12'>
      </div>
      <div className='col-12 mt-3'>
      <div className='row'>
      <div className='col-lg-6 col-md-6 col-7' style={{padding:"0px",background:"white","border-radius":"5px",height:"40px"}}>
     <div className='row'>
<div className=' text-danger' style={{width:"3px"}}>
<RxCross2   onClick={cleartextordercust} style={{position:"sticky",marginRight:"6px",marginTop:"12px"}}></RxCross2>

</div>
<div className='col-11' >

<input  autoFocus={true} onKeyDown={handlePresscustomrecord} id='inputcustomerrecord' className='fontmaaray' style={{"height": "40px",fontSize:"17px",border:"none","width":"100%",borderRadius:"5px"}} placeholder=' بحث عن عميل' onChange={handleChangeinputsearch} type={'text'}></input>

</div>
     </div>


      </div>
      <div onClick={showscanpopupitem} className='col-1 shadow scanofPopupcustomerstyle' >
<button className='btn ' >
  <BiBarcodeReader className='iconscanofPopupcustomerstyle' ></BiBarcodeReader>
</button>
      </div>
      <div className='col-lg-2 col-md-2 col-4 shadow dropdownofPopupcustomerstyle' >

      <Dropdown className='dropdownofcustseacrsh  shadow'  style={{width:"100%","background":"rgb(245, 105, 2)","border":" 1px solid rgb(245, 105, 2)","border-radius":"6px"}}>
      <Dropdown.Toggle  className='fontmaaray text-white'   style={{width:"100%",fontSize:"17px"}} variant="" id="dropdown-basic" >
الكل
      </Dropdown.Toggle>

      <Dropdown.Menu >
      <Dropdown.Item onClick={searchallcustomer}   style={{width:"100%",textAlign:"right"}}className='fontmaaray'>الكل</Dropdown.Item>

      <Dropdown.Item onClick={searchnamecustomer}   style={{width:"100%",textAlign:"right"}} className='fontmaaray' >اسم</Dropdown.Item>

      <Dropdown.Item onClick={searchcodecustomer}  style={{width:"100%",textAlign:"right"}} className='fontmaaray' >كود</Dropdown.Item>
      <Dropdown.Item onClick={searchphonecustomer}  style={{width:"100%",textAlign:"right"}} className='fontmaaray'> تليفون</Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>
      </div>


      <div className='col-lg-2 col-md-2 col-6 shadow btnshowofPopupcustomerstyle' >
      <button  className='btn btn-success fontmaaray w-100 shadow ' style={{"height":"41px"}}  onClick={() => handleSearchcustomer(inputofsearch,searchcustomer)} >عرض</button>
      {/* <div className='row searchofclient'>
      <div className='col-lg-10 col-md-12 col-12 btnofcustmenushow shadow p-0'>

      </div>
      </div> */}

      </div>

      </div>
      </div>



      </div>

      </div>
  {loadingsearchcust==true?
  <div className='col-11' style={{position: "absolute",top: "50%",left:"22%"}}>
 <Spinner animation="border" style={{marginRight:"40%"}} role="status">

</Spinner>
  </div>
 
  
  :
  
  
  <>
      <div className='col-11' style={{"margin-right":"4%"}}>
      {/* justify-content-center */}
      <div className='row mt-2 ' style={{background:"rgb(213, 213, 213","border":"1px solid darkgray","border-radius":"4px"}}>
      <div className='col-lg-5 col-md-5 col-4 ' style={{"margin-top":"2%"}}>
      <p className='fontmaaray ' style={{"text-align":"right","border-left":"1px solid white","text-shadow":"2px 4px 4px #bfbfbf",fontWeight:"bold",color:"rgb(25, 135, 84)","font-size":"19px"}} >اسم العميل </p>

      </div>
      <div className='col-lg-3 col-md-3 col-3' style={{"margin-top":"2%"}}>
      <p className='fontmaaray' style={{"text-align":"right","border-left":"1px solid white","text-shadow":"2px 4px 4px #bfbfbf",fontWeight:"bold",color:"rgb(25, 135, 84)","font-size":"19px"}}>الكود</p>
      </div>
      <div className='col-lg-4 col-md-4  col-4' style={{"margin-top":"2%"}}>
      <p className='fontmaaray' style={{"text-align":"right","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(25, 135, 84)","font-size":"19px",fontWeight:"bold"}}>رقم التليفون</p>
      </div>
      </div>
      </div>

      {customersearch &&customerdata==true
 ?
      // justify-content-center
     <div className='col-11' style={{"margin-right":"4%"}}>
 <div className='row mt-2' style={{"overflow-x": "hidden",
      "overflow-y": "scroll",
      "max-height": "322px",
      height:"320px",
      "border":"1px solid darkgray",
      borderRadius:"4px"}}>
            {spanner==true ?
              <div className='container-fluid ' style={{marginTop:"3%"}}>
  <div className='row justify-content-center' id='imgload' style={{display:"none"}}>
    <div className='col-6'style={{ height:"50px"}} >
   <img src={require("../image/Loading_icon.gif")} className='w-100' style={{zIndex:"9000000"}} />
    </div>

  </div>

  </div>
  :customersearch.map((customer) => (
      <div className='col-12' style={{"border-bottom":"1px solid darkgray"}}  onClick={() => passthename(customer)}>
      <div className='row' style={{cursor:"pointer"}} >
      <div className='col-lg-5 col-md-5 col-4' style={{"margin-top":"2%"}}>
      <p className='fontmaaray'  style={{"text-align":"right" ,"border-left":"1px solid #d7d7d7","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(89, 89, 89)",fontWeight:"bold"}}>{customer.firstName + customer.lastName }</p>
      </div>
      <div className='col-lg-3 col-md-3 col-3' style={{"margin-top":"2%"}}>
      <p className='fontmaaray'  style={{"text-align":"right","border-left":"1px solid #d7d7d7","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(89, 89, 89)",fontWeight:"bold"}}>{customer.id}</p>
      </div>
      <div className='col-lg-4 col-md-4 col-4' style={{"margin-top":"2%"}}>
      <p className='fontmaaray'  style={{"text-align":"right","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(89, 89, 89)",fontWeight:"bold"}}>{customer.phoneNumber }</p>
      </div>
      </div>



      </div>

      ) )}

      </div>
     </div>
      :""}
  
  </>
  }
      </div>

      <div className='row card-title Popupcustomerstylemop' style={{"background":"rgb(239, 237, 237)"}} >
      <div className='col-12'>
      <div className='row justify-content-end card-header' style={{height:"70px"}}>
        <div className='col-lg-1 col-md-1 col-1 divimgcustomer' >
        <img className="imgcustomerrecored" src={require("../image/Customers.png")} />
        </div>
      <div className='col-lg-4 col-md-9 col-7 imgrecordcustomer' >
      <h2 className='text-center fontmaaray textheadercusrecord' style={{"text-shadow":"2px 4px 4px #bfbfbf",color:"#595959"}}>قائمه العملاء</h2>

      </div>

      <div className='col-lg-1 col-md-1 col-2' style={{padding:"0px"}} >
      <button onClick={hidecustomerpopup}  data-bs-toggle="tooltip" data-bs-placement="left" title="خروج" style={{padding:"0px","margin-top":"22%"}} className=' btn  close text-danger' ><RxCross2 className='fs-4' data-bs-toggle="tooltip" data-bs-placement="left" title="خروج  "></RxCross2> </button>

      </div>
      </div>

      </div>
      <div className='col-11' style={{marginRight:"4%"}} onKeyDown={handlePresscustomrecord}>
      <div className='row '>

      <div className='col-12 mt-3'>
      <div className='row'>
      <div className='col-lg-6 col-md-6 col-9' style={{padding:"0px",background:"white","border-radius":"5px",height:"40px"}}>
     <div className='row'>
<div className=' text-danger' style={{width:"3px"}}>
<RxCross2   onClick={clear} style={{position:"sticky",marginRight:"6px",marginTop:"12px"}}></RxCross2>

</div>
<div className='col-10' >

<input  autoFocus={true} onKeyDown={handlePresscustomrecord} id='inputcustomerrecord' className='fontmaaray' style={{"height": "37px",fontSize:"17px",border:"none","width":"100%",borderRadius:"5px"}} placeholder=' بحث عن عميل' onChange={handleChangeinputsearch} type={'text'}></input>

</div>
     </div>


      </div>
      <div onClick={showscanpopupitem} className='col-3 shadow scanofPopupcustomerstyle' style={{paddingLeft:"0px"}} >
<button className='btn w-100' style={{backgroundColor:"#f56902",color:"white"}} >
  <BiBarcodeReader className='iconscanofPopupcustomerstyle' ></BiBarcodeReader>
</button>
      </div>


      </div>

      </div>



      </div>

      </div>
      <div className="col-11" style={{marginRight:"4%"}}>
      <div className='row mt-2'>
      <div className='col-lg-2 col-md-2 col-6 shadow dropdownofPopupcustomerstyle p-0 ' >

<Dropdown className='dropdownofcustseacrsh  shadow'  style={{width:"100%","background":"rgb(245, 105, 2)","border":" 1px solid rgb(245, 105, 2)","border-radius":"6px"}}>
<Dropdown.Toggle  className='fontmaaray text-white'   style={{width:"100%",fontSize:"17px"}} variant="" id="dropdown-basicmop" >
الكل
</Dropdown.Toggle>

<Dropdown.Menu >
<Dropdown.Item onClick={searchallcustomermop}   style={{width:"100%",textAlign:"right"}}className='fontmaaray'>الكل</Dropdown.Item>

<Dropdown.Item onClick={searchnamecustomermop}   style={{width:"100%",textAlign:"right"}} className='fontmaaray' >اسم</Dropdown.Item>

<Dropdown.Item onClick={searchcodecustomermop}  style={{width:"100%",textAlign:"right"}} className='fontmaaray' >كود</Dropdown.Item>
<Dropdown.Item onClick={searchphonecustomermop}  style={{width:"100%",textAlign:"right"}} className='fontmaaray'> تليفون</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
</div>


<div className='col-lg-2 col-md-2 col-6 shadow btnshowofPopupcustomerstyle' style={{paddingLeft:"0px"}} >
<button  className='btn btn-success fontmaaray w-100 shadow ' style={{"height":"41px"}}  onClick={() => handleSearchcustomer(inputofsearch,searchcustomer)} >عرض</button>
{/* <div className='row searchofclient'>
<div className='col-lg-10 col-md-12 col-12 btnofcustmenushow shadow p-0'>

</div>
</div> */}

</div>
      </div>
      </div>
      <div className='col-11' style={{"margin-right":"4%"}}>
      {/* justify-content-center */}
      <div className='row mt-2 ' style={{background:"rgb(213, 213, 213","border":"1px solid darkgray","border-radius":"4px"}}>
      <div className='col-lg-5 col-md-5 col-4 ' style={{"margin-top":"2%"}}>
      <p className='fontmaaray ' style={{"text-align":"right","border-left":"1px solid white","text-shadow":"2px 4px 4px #bfbfbf",fontWeight:"bold",color:"rgb(25, 135, 84)","font-size":"14px"}} >اسم العميل </p>

      </div>
      <div className='col-lg-3 col-md-3 col-3' style={{"margin-top":"2%"}}>
      <p className='fontmaaray' style={{"text-align":"right","border-left":"1px solid white","text-shadow":"2px 4px 4px #bfbfbf",fontWeight:"bold",color:"rgb(25, 135, 84)","font-size":"14px"}}>الكود</p>
      </div>
      <div className='col-lg-4 col-md-4  col-4' style={{"margin-top":"2%"}}>
      <p className='fontmaaray' style={{"text-align":"right","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(25, 135, 84)","font-size":"14px",fontWeight:"bold"}}>رقم التليفون</p>
      </div>
      </div>
      </div>
      {customersearch ?
      // justify-content-center
     <div className='col-11' style={{"margin-right":"4%"}}>
 <div className='row mt-2' style={{"overflow-x": "hidden",
      "overflow-y": "scroll",
      "max-height": "322px",
      "border":"1px solid darkgray",
      borderRadius:"4px"}}>
            {spanner==true ?
              <div className='container-fluid ' style={{marginTop:"3%"}}>
  <div className='row justify-content-center'>
    <div className='col-6'style={{ height:"50px"}} >
    <Spinner animation="border" style={{marginRight:"40%"}} role="status">

      </Spinner>
    </div>

  </div>

  </div>
  :customersearch.map((customer) => (
      <div className='col-12' style={{"border-bottom":"1px solid darkgray"}}  onClick={() => passthename(customer)}>
      <div className='row' style={{cursor:"pointer"}} >
      <div className='col-lg-5 col-md-5 col-4' style={{"margin-top":"2%"}}>
      <p className='fontmaaray'  style={{"text-align":"right" ,"border-left":"1px solid #d7d7d7","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(89, 89, 89)",fontWeight:"bold",fontSize:"12px"}}>{customer.firstName + customer.lastName }</p>
      </div>
      <div className='col-lg-3 col-md-3 col-3' style={{"margin-top":"2%"}}>
      <p className='fontmaaray'  style={{"text-align":"right","border-left":"1px solid #d7d7d7","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(89, 89, 89)",fontWeight:"bold",fontSize:"12px"}}>{customer.id}</p>
      </div>
      <div className='col-lg-4 col-md-4 col-4' style={{"margin-top":"2%"}}>
      <p className='fontmaaray'  style={{"text-align":"right","text-shadow":"2px 4px 4px #bfbfbf",color:"rgb(89, 89, 89)",fontWeight:"bold",fontSize:"12px"}}>{customer.phoneNumber }</p>
      </div>
      </div>



      </div>

      ) )}

      </div>
     </div>
      :""}
      </div>

      </div>:""}

  {Orderrecord ==true?
  <div onKeyDown={enterPressorderrecord} className='col-lg-8 col-md-8 col-12 card shadow Popupstylemobile' style={{backgroundColor:"rgb(239, 237, 237)" }}>
  <div className='container-fluid ' style={{"background":"rgb(239, 237, 237)"}} >
  <div className='row card-header justify-content-center'>

  <div className='col-10'>
  <h1 className='text-center fontmaaray'>سجل الاوردارات</h1>
  </div>

  <div className='col-1'>
  <button  onClick={hideorderecordpopup}  className=' fs-4 close text-danger btn'><RxCross2></RxCross2> </button>

  </div>


  </div>
  <div className='col-12' >

  </div>

  </div>
 <div className="container-fluid">
 <div className='row mt-3' style={{paddingRight:"10px"}}>
  <div className='col-5 p-0'>
  <div className='row'>
  <div className='col-lg-2 col-md-2 col-2 p-0' style={{fontSize:"13px"}} >
  <p className='fontmaaray' style={{    marginTop: "5px"}}>من</p>
  </div>
  <div className='col-lg-8 col-md-8 col-8' style={{padding:"0px"}}>
  <input className="w-100" style={{height:"36px"}} onKeyDown={enterPressorderrecord} id="todayto" defaultValue={toDay} onChange={handleChangedateto}  type={'date'} name="from"></input>

  </div>
  </div>

  </div>

  <div className='col-5 p-0'>
  <div className='row'>
  {/* style={{padding:"0px",marginRight:"8%"}} */}
  <div className='col-lg-2 col-md-2 col-2 p-0' style={{fontSize:"13px"}}>
  <p className='fontmaaray'  style={{marginTop: "5px"}}>الي</p>
  </div>
  <div className='col-lg-9 col-md-9 col-8' style={{padding:"0px"}}>
  <input className="w-100" style={{height:"36px"}} onKeyDown={enterPressorderrecord} id="todayfrom" defaultValue={toDay}  onChange={handleChangedatefrom} type={'date'} name="to"></input>

  </div>

  </div>

  </div>
  <div className=' col-2 p-0'>



  {/* mx-5 */}
  <Dropdown className=''>
  <Dropdown.Toggle className='fontmaaray'   variant="primary" id="dropdown-basic2" style={{height:"36px",width:"100%",marginRight:"-20%"}}>
  الحاله
  </Dropdown.Toggle>

  <Dropdown.Menu >
    <Dropdown.Item style={{textAlign:"start"}} onClick={settoday2} className='fontmaaray' id >اليوم</Dropdown.Item>

  <Dropdown.Item style={{textAlign:"start"}} onClick={setyesterday2} className='fontmaaray'  >امس</Dropdown.Item>

  <Dropdown.Item style={{textAlign:"start"}} onClick={setlast7day2} className='fontmaaray'>اسبوع</Dropdown.Item>
  <Dropdown.Item style={{textAlign:"start"}} onClick={setlastmonth2} className='fontmaaray' >شهر </Dropdown.Item>
  </Dropdown.Menu>
  </Dropdown>
  </div>
  </div>
 </div>

  <div className="container-fluid">
  <div className='row mt-2'  style={{paddingRight:"10px"}}>
  {/* onClick={fetchdetailsorder(User.UserId,datefrom,dateto)} */}

  <button style={{width:"99%"}}  onClick={() => fetchdetailsorder(User.id,datetto,dateefrom) } className=' btn btn-success fontmaaray'>
  عرض
  </button>



  </div>
  </div>
  <div className='row' style={{
  "overflow-y": "scroll",
  overflowX: "hidden",
  paddingRight:"33px",
height:"274px",

  "max-width": "871px !important"
  }}>
  {/* {    max-width: 871px;} */}



  {orderdetails && spanner==true ?
    <div className='container-fluid ' style={{marginTop:"3%"}}>
  <div className='row justify-content-center'>
    <div className='col-6'style={{ height:"50px"}} >
    <Spinner animation="border" style={{marginRight:"40%"}} role="status">

      </Spinner>
    </div>

  </div>

  </div>



  :


  orderdetails.map(orderdetails => (


    <div className='row justify-content-center card mt-2' style={{padding:"0px",height:"125px",backgroundColor:"rgb(245 242 223)"}} onClick={() => {fetchorderitemdetail(orderdetails.id);hideorderecordpopup()}}>
     <div className="col-12" style={{padding:"0px",marginRight:"4%"}}>
      <p className='text-center fontmaaray' style={{padding:"0px"}}> {orderdetails.customer.firstName}</p>
      </div>
     <div className='col-12' style={{"margin-right":"4%"}}>
     <div className='row'>
      <div className="col-4" style={{padding:"0px"}}>
      <p className='text-center fontmaaray' style={{padding:"0px"}}>{orderdetails.id}</p>
      </div>

      <div className="col-4" style={{padding:"0px"}}>
        <p className='text-center fontmaaray' style={{padding:"0px"}}>{moment(orderdetails.createdTime).format('DD/MM/YYYY HH:mm') }</p>
        </div>
      <div className="col-4" style={{padding:"0px"}}>
        <p className='text-center fontmaaray'  style={{padding:"0px"}}>
      {orderdetails.itemCount}</p>
      </div>

      </div>
     </div>
    <div className='col-12'  style={{"margin-right":"4%"}}>
    <div className='row'>
   <div className="col-4" style={{padding:"0px"}}>
        <p className='text-center fontmaaray' style={{padding:"0px"}}>{orderdetails.sumQuantity}</p>
      </div>
      <div className="col-4" style={{padding:"0px"}}>
      <p className='text-center fontmaaray' style={{padding:"0px"}}>{orderdetails.status}</p>
      </div>
      <div className="col-4" style={{padding:"0px"}}>
        <p className='text-center fontmaaray' style={{padding:"0px"}}>{orderdetails.netTotal}</p>
        </div>
   </div>
    </div>

    </div>
    ) )}






  </div>
  </div>:""}

{/* //////////////////////// */}
  {Orderrecord ==true?
  <div onKeyDown={enterPressorderrecord} className='col-lg-8 col-md-8 col-12 card shadow Popupstyle' style={{backgroundColor:"rgb(239, 237, 237)"}}>
  <div className='row ' style={{"background":"rgb(239, 237, 237)"}} >
  <div className='col-12'>
  <div className='row justify-content-center'>

  <div className='col-lg-8 col-md-10 col-10' style={{marginRight:"144px"}}>
    <div className='row justify-content-center'>
    <div className='col-1' style={{"margin-top":"2%"}}>
      <img style={{"height":"43px","width":"43px"}} src={require("../image/document.png")}></img>
    </div>
    <div className='col-6' style={{"margin-top":"2%"}}>
    <h2 className=' fontmaaray'>سجل الاوردارات</h2>
    </div>

    </div>

  </div>
  <div className='col-lg-1 col-md-1 col-2' style={{    "margin-right":"8%"}}>
  <button  onClick={hideorderecordpopup} data-bs-toggle="tooltip" data-bs-placement="left" title="خروج"  className=' fs-4 close text-danger btn'><RxCross2 data-bs-toggle="tooltip" data-bs-placement="left" title="خروج"></RxCross2> </button>

  </div>


  </div>

  </div>
  <div className='col-12'>
<hr></hr>
  </div>
  <div className='col-12' >

  </div>

  </div>
  <div className='col-12' style={{marginTop:"-3%"}}>
  <div className='row mt-3'>
  <div className='col-lg-2 col-md-3 col-12' style={{"margin-right":"1%"}}>
  <div className='row' >
  <div className='col-lg-3 col-md-2 col-2' style={{padding:"0px"}}>
  <p className='fontmaaray'  style={{"margin-top":"9%"}}>من</p>
  </div>
  <div className='col-lg-9 col-md-8 col-8' style={{padding:"0px"}}>
  <input style={{height:"38px",width:"135px"}} onKeyDown={enterPressorderrecord} id="todayto2" defaultValue={toDay} onChange={handleChangedateto}  type={'date'} name="from"></input>

  </div>
  </div>

  </div>
  <div className='col-lg-2 col-md-3 col-12'>
  <div className='row' style={{"margin-right":"23%"}}>
  <div className='col-lg-3 col-md-2 col-2' style={{padding:"0px"}}>
  <p className='fontmaaray' style={{    "margin-top":"9%"}}>الي</p>
  </div>
  <div className='col-lg-9 col-md-9 col-8' style={{padding:"0px"}}>
  <input style={{width:"135px",height:"38px"}} onKeyDown={enterPressorderrecord} id="todayfrom2" defaultValue={datetto}  onChange={handleChangedatefrom} type={'date'} name="to"></input>

  </div>

  </div>

  </div>
  <div className='col-lg-3 col-md-1'></div>
  <div className='col-lg-2 col-md-2 col-4'  style={{"margin-right":"6.3%",background:"rgb(245, 105, 2)","border-radius":"6px","height":"38px"}}>




  <Dropdown className=' '>
  <Dropdown.Toggle className='fontmaaray'   variant="" id="dropdown-basic" style={{color:"white","padding":"7px"}}>
  الحاله
  </Dropdown.Toggle>

  <Dropdown.Menu >
    <Dropdown.Item onClick={settoday} className='fontmaaray' style={{"text-align":"right"}} >اليوم</Dropdown.Item>

  <Dropdown.Item onClick={setyesterday} className='fontmaaray' style={{"text-align":"right"}} >امس</Dropdown.Item>

  <Dropdown.Item onClick={setlast7day} className='fontmaaray' style={{"text-align":"right"}}>اسبوع</Dropdown.Item>
  <Dropdown.Item onClick={setlastmonth} className='fontmaaray' style={{"text-align":"right"}} >شهر </Dropdown.Item>
  </Dropdown.Menu>
  </Dropdown>
  </div>

  <div className='col-lg-2 col-md-2 col-4'>
  {/* onClick={fetchdetailsorder(User.UserId,datefrom,dateto)} */}
  <button  onClick={() => fetchdetailsorder(User.id,datetto,dateefrom) } className='w-100 btn btn-success fontmaaray' style={{"margin-right":"5%"}}>
  عرض
  </button>


  </div>
  </div>

</div>
  <div className='col-12' style={{
  "overflow-y": "scroll",
  " overflow-x": "hidden",

  marginRight:".5%",
  "background-color":"rgb(239, 237, 237)",
  "max-width": "871px !important"
  }}>
  {/* {    max-width: 871px;} */}
  <div className='container-fluid' >
  <div className='row justify-content-center fontmaaray' style={{backgroundColor:"rgb(213 213 213)","border":"1px solid #bfbfbf","border-radius":"5px",color:"rgb(25 135 84)",fontWeight:"bold"}}>

  <div className="col-2 p-0 " >
  <p className='text-center mt-2 fontmaaray '>رقم الاوردر</p>
  </div>
  <div className="col-2 p-0" style={{"border-right": "1px solid white"}}>
  <p className='text-center mt-2 fontmaaray'>العميل </p>
  </div>
  <div className="col-2 p-0" style={{"border-right": "1px solid white"}}>
  <p className='text-center mt-2 fontmaaray'> التاريخ</p>
  </div>
  <div className="col-2 p-0" style={{"border-right": "1px solid white"}}>
  <p className='text-center mt-2 fontmaaray'> عدد الاصناف</p>
  </div>
  <div className="col-1 p-0" style={{"border-right": "1px solid white"}}>
  <p className='text-center mt-2 fontmaaray'> الكميه</p>
  </div>
  <div className="col-1 p-0" style={{"border-right": "1px solid white"}}>
  <p className='text-center mt-2 fontmaaray'> الحاله</p>
  </div>
  <div className="col-2 p-0" style={{"border-right": "1px solid white"}}>
  <p className='text-center mt-2 fontmaaray'> اجمالي</p>
  </div>




  </div>
  </div>


  {orderdetails && spanner==true ?
    <div className='container-fluid ' style={{marginTop:"3%"}}>
  <div className='row justify-content-center'>
    <div className='col-6'style={{ height:"50px"}} >
    <Spinner animation="border" style={{marginRight:"40%"}} role="status">

      </Spinner>
    </div>

  </div>

  </div>



  :
  <>
  {orderdetails.length==0?"":<div className='container-fluid mt-2'style={{background:"#eaeaea",border:"1px solid #bfbfbf","border-radius":"5px"}} >
{orderdetails.map(orderdetails => (


  <div className='row justify-content-center ' style={{padding:"0px","margin-top":"-1px",borderBottom:"1px solid #bfbfbf",color:"rgb(25 135 84)",fontWeight:"bold"}} onClick={() => {fetchorderitemdetail(orderdetails.id);hideorderecordpopup();}}>
  <div className="col-2" style={{padding:"0px"}}>
    <p className='text-center fontmaaray' style={{padding:"0px",    "margin-top":"12%"}}>{orderdetails.id}</p>
    </div>
    <div className="col-2" style={{padding:"0px","border-right": "1px solid #bfbfbf"}}>
    <p className='text-center fontmaaray' style={{padding:"0px", "margin-top":"12%"}}> {orderdetails.customer.firstName}</p>
    </div>
    <div className="col-2" style={{padding:"0px","border-right": "1px solid #bfbfbf"}}>
      <p className='text-center fontmaaray' style={{padding:"0px", "margin-top":"12%"}}>{moment(orderdetails.createdTime).format('DD/MM/YYYY HH:mm') }</p>
      </div>
    <div className="col-2" style={{padding:"0px","border-right": "1px solid #bfbfbf"}}>
      <p className='text-center fontmaaray'  style={{padding:"0px", "margin-top":"12%"}}>
    {orderdetails.itemCount}</p>
    </div>
    <div className="col-1" style={{padding:"0px","border-right": "1px solid #bfbfbf"}}>
      <p className='text-center fontmaaray' style={{padding:"0px", "margin-top":"24%"}}>{orderdetails.sumQuantity}</p>
    </div>
    <div className="col-1" style={{padding:"0px","border-right": "1px solid #bfbfbf"}}>
    <p className='text-center fontmaaray' style={{padding:"0px", "margin-top":"24%"}}>{orderdetails.status}</p>
    </div>
    <div className="col-2" style={{padding:"0px","border-right": "1px solid #bfbfbf"}}>
      <p className='text-center fontmaaray' style={{padding:"0px", "margin-top":"12%"}}>{orderdetails.netTotal}</p>
      </div>
  </div>
  ) )}
  </div>}

  </>

  }






  </div>
  </div>:""}



{tablepopup==true ?
   <div className='col-lg-8 col-md-9 col-12 card shadow popupoftablemain' style={{backgroundColor:"rgb(239, 237, 237)","overflow-x": "hidden","overflow-y":"scroll"}}>
   <div className='row'>

   <div className='col-12 card-header p-0' >
<div className='row justify-content-end'>
  <div className='col-1'>
    <img src={require("../image/chair.png")} className='imgoftablepopup' style={{}}></img>
  </div>
<div className='col-lg-2 col-md-3 col-6'  style={{padding: "0px"}}>
<p className=' fs-4 fontmaaray'style={{textAlign:"left",color:"rgb(24 135 84)","text-shadow":"2px 4px 4px #bfbfbf",marginTop:"10px",fontWeight:"bold",marginLeft:"19px"}}>الطاولات</p>
</div>
<div className='col-lg-3 col-md-3 col-1' style={{"margin-right":"2%"}}></div>
<div className='col-lg-1 col-md-2 col-3'  style={{padding: "0px"}}>
<button onClick={hidetablepopup}  data-bs-toggle="tooltip" data-bs-placement="left" title="خروج" className='fs-4 close text-danger btn'><RxCross2 data-bs-toggle="tooltip" data-bs-placement="left" title="خروج"></RxCross2> </button>
</div>
</div>


</div>
{sections  ?
<div className='container-fluid thistablepopupheigh' >
{showsection==true ?
<div className='row fontmaaray' style={{"margin-right":"1%","margin-top":"1%"}}>
{sections.map((sections) => (
<div className='col-lg-2 col-md-3 col-4 mx-2 'style={{padding:"0px",height:"40px"}}>

<button   style={{backgroundColor:sectionid == sections.sectionId ? "rgb(25, 135, 84)":"rgb(153, 148, 148)",color:"white"}} onClick={() => fetchtables(sections.sectionId)}  className='btn fontmaaray w-100 h-100  shadow'  id='sectionoftable'>
<div className='col-12 p-0'>
<div className='row p-0'>
   <div className='col-6 p-0'>
   <p style={{fontWeight:"bold"}}> {sections.sectionsName} </p>
   </div>
   <div className='col-4 p-0' style={{"margin-right":"16%"}}>
   <p>({sections.numberOfTables})</p>

   </div>
  </div>
</div>

   </button>
</div>
))}





</div> : ""}

{showtable==true?
<div className='row divofTable'id='divofTable'style={{overflow:"scroll"}} >

{tables.map((tables) => (
<>
{tables.underProcessingTableOrderCount >= 1 && tables.usedrId == User.id ?
<div className='changecolor'  style={{backgroundColor:"#188754"}}  onClick={(e) => { fetchorderoftable(tables.tableNo);hidetablepopup();setidoforder(tables.tableNo)}}>

<div className='row'>
<div className='col-7'>
<img src={require("../image/White-Table.png")} style={{width:"100%"}}></img>
</div>
<div className='col-5 p-0 shadow' style={{"height":"30px"}} >
<p className='text-white fs-5 rounded-1 fontmaaray' style={{"text-align":"center",background:"#ea8f1a"}}> {tables.underProcessingTableOrderCount} </p>
</div>

</div>

<div className='row justify-content-end'>
<div className='col-8 p-0'>

<p  className='text-center  text-white fontmaaray'style={{"font-weight": "bold","font-size":"40px"}} >{tables.tableNo}</p>
</div>
</div>
{/* {tables.tableCapicty==1 ?

<img style={{"margin-right":"23%",height:"34%", "margin-top":"-26%"}} src={require("../image/Table1.png")}></img>

:""}
{tables.tableCapicty==2 ?
<img style={{"margin-right":"26%",height:"34%","margin-top":"-26%"}} src={require("../image/Table2.png")}></img>:""}
{tables.tableCapicty==3 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table3.png")}></img>  :""}
{tables.tableCapicty==4 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}}   src={require("../image/table4.png")}></img>  :""}
{tables.tableCapicty==5 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table5.png")}></img>  :""}
{tables.tableCapicty==6 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table6.png")}></img>  :""}
{tables.tableCapicty==7 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table7.png")} ></img>  :""}
{tables.tableCapicty==8 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table8.png")}></img>  :""}
{tables.tableCapicty==9 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table9.png")}></img>  :""}
{tables.tableCapicty==10 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}}src={require("../image/table10.png")}></img>  :""}
{tables.tableCapicty==11 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table11.png")}></img>  :""}
{tables.tableCapicty==12 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table12.png")}></img>  :""}
 */}



</div>:""
}
{tables.underProcessingTableOrderCount > 1 && tables.usedrId!=User.id?
// onClick={(e) => { fetchorderoftable(tables.tableNo);hidetablepopup();setidoforder(tables.tableNo)}}
<div className='changecolor' style={{backgroundColor:"#c30705"}} onClick={tablenotavalabile} >

<div className='row'  >
<div className='col-7'>
<img src={require("../image/White-Table.png")} style={{width:"100%"}}></img>
</div>

<div className='col-5 p-0 shadow' style={{"height":"30px"}} >
<p className='text-white fs-5 rounded-1 fontmaaray ' style={{"text-align":"center",background:"rgb(89 89 89)"}}> {tables.underProcessingTableOrderCount} </p>
</div>

</div>
<div className='row justify-content-end'>
<div className='col-8 p-0'>

<p  className='text-center  text-white fontmaaray'style={{"font-weight": "bold","font-size":"40px"}} >{tables.tableNo}</p>
</div>
</div>

{/* {tables.tableCapicty==1 ?

<img style={{"margin-right":"23%",height:"34%", "margin-top":"-26%"}} src={require("../image/Table1.png")}></img>

:""}
{tables.tableCapicty==2 ?
<img style={{"margin-right":"26%",height:"34%","margin-top":"-26%"}} src={require("../image/Table2.png")}></img>:""}
{tables.tableCapicty==3 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table3.png")}></img>  :""}
{tables.tableCapicty==4 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}}   src={require("../image/table4.png")}></img>  :""}
{tables.tableCapicty==5 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table5.png")}></img>  :""}
{tables.tableCapicty==6 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table6.png")}></img>  :""}
{tables.tableCapicty==7 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table7.png")} ></img>  :""}
{tables.tableCapicty==8 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table8.png")}></img>  :""}
{tables.tableCapicty==9 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table9.png")}></img>  :""}
{tables.tableCapicty==10 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}}src={require("../image/table10.png")}></img>  :""}
{tables.tableCapicty==11 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table11.png")}></img>  :""}
{tables.tableCapicty==12 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table12.png")}></img>  :""}
 */}



</div>:""
}
{tables.underProcessingTableOrderCount == 0 ?
<div className='changecolor' style={{backgroundColor:"#595959"}} onClick={(e) => { fetchorderoftable(tables.tableNo);hidetablepopup();setidoforder(tables.tableNo)}}>

<div className='row'>
<div className='col-7'>
<img src={require("../image/White-Table.png")} style={{width:"100%"}}></img>
</div>


</div>
<div className='row justify-content-end'>
<div className='col-8 p-0'>

<p  className='text-center  text-white fontmaaray'style={{"font-weight": "bold","font-size":"40px"}} >{tables.tableNo}</p>
</div>
</div>

{/* {tables.tableCapicty==1 ?

<img style={{"margin-right":"23%",height:"34%", "margin-top":"-26%"}} src={require("../image/Table1.png")}></img>

:""}
{tables.tableCapicty==2 ?
<img style={{"margin-right":"26%",height:"34%","margin-top":"-26%"}} src={require("../image/Table2.png")}></img>:""}
{tables.tableCapicty==3 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table3.png")}></img>  :""}
{tables.tableCapicty==4 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}}   src={require("../image/table4.png")}></img>  :""}
{tables.tableCapicty==5 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table5.png")}></img>  :""}
{tables.tableCapicty==6 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table6.png")}></img>  :""}
{tables.tableCapicty==7 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table7.png")} ></img>  :""}
{tables.tableCapicty==8 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table8.png")}></img>  :""}
{tables.tableCapicty==9 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table9.png")}></img>  :""}
{tables.tableCapicty==10 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}}src={require("../image/table10.png")}></img>  :""}
{tables.tableCapicty==11 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table11.png")}></img>  :""}
{tables.tableCapicty==12 ?
<img style={{"margin-right":"26%",height:"34%", "margin-top":"-26%"}} src={require("../image/table12.png")}></img>  :""} */}




</div>:""
}


</>





))}





</div>:""}
</div>:""}
{/*
{tables  ?
<div className='container-fluid'>
{showtable==true?
<div className='row divofTable' >
{tables.map((tables) => (


<div onClick={() => tablesclick(tables.tableNo)} style={{borderRadius:"5px",background:"#157347",margin:"5px",width:"150px" ,height:"100px"
,justifyContent:"center",alignItems:"start"}}>
{tables.tableCapicty==1 ?

<img style={{"margin-right":"28%"}} src={require("../image/Table1.png")}></img>

:""}
{tables.tableCapicty==2 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/Table2.png")}></img>:""}
{tables.tableCapicty==3 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table3.png")}></img>  :""}
{tables.tableCapicty==4 ?
<img style={{"margin-right":"28%",height:"50%"}}   src={require("../image/table4.png")}></img>  :""}
{tables.tableCapicty==5 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table5.png")}></img>  :""}
{tables.tableCapicty==6 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table6.png")}></img>  :""}
{tables.tableCapicty==7 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table7.png")} ></img>  :""}
{tables.tableCapicty==8 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table8.png")}></img>  :""}
{tables.tableCapicty==9 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table9.png")}></img>  :""}
{tables.tableCapicty==10 ?
<img style={{"margin-right":"28%",height:"50%"}}src={require("../image/table10.png")}></img>  :""}
{tables.tableCapicty==11 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table11.png")}></img>  :""}
{tables.tableCapicty==12 ?
<img style={{"margin-right":"28%",height:"50%"}} src={require("../image/table12.png")}></img>  :""}
<div className='row'>
<div className='col-7' style={{padding: "0px"}}>
<p className='text-center text-white ' style={{"margin-top":"3%"}}> رقم الطاوله:</p>
</div>
<div className='col-5 p-0'>
<p  className='text-right fs-5 text-white' >{tables.tableNo}</p>
</div>
</div>
</div>



))}





</div>:""}


</div>:""} */}
   </div>

     </div>
:""}
{infoinitem==true ?
<div className='col-lg-8 col-md-8 col-12 card shadow Popupofdeailsinorder' style={{backgroundColor:"rgb(239, 237, 237)"}}>
    <div className='row card-title' style={{"background":"rgb(239, 237, 237)"}} >
    <div className='col-12'>
    <div className='row justify-content-end'>
    <div className='col-9'>
    <h1 className='text-center fontmaaray'> استعلام عن صنف  </h1>

    </div>
    <div className='col-2'>
    <button onClick={hideinfoinitempopup}  className='fs-4 close text-danger btn'><RxCross2></RxCross2> </button>

    </div>
    </div>

    </div>

    {detailsitemsearch ?
    // justify-content-center
    <div className='container-fluid' style={{"height": "335px",overflowX:"hidden",overflowY:"scroll"}}>
    <div className='row justify-content-center mt-2' >
      {spanner==true ?

      <div className='container-fluid ' style={{marginTop:"3%"}}>
      <div className='row justify-content-center'>
        <div className='col-6'style={{ height:"50px"}} >
        <Spinner animation="border" style={{marginRight:"40%"}} role="status">

          </Spinner>
        </div>

      </div>

      </div>

      :
        detailsitemsearch.map((detailitem) => (
    <div className='col-11 '>


    <div className='row'>
    <div className='col-7'>
    <div className='row'>
    <div className='col-12'>
    <p className='mt-2 fs-3' style={{"text-align":"right",color:"#5d5d5d","text-shadow":"2px 4px 4px #bfbfbf"}}> {detailitem.itemName} </p>
    </div>

    <div className='row'>
    {/* <div className='col-3'>
    <del style={{"font-color":"red"}}>{detailitem.salePrice}</del>
    </div> */}
    <div className='col-4'>
<div className='row'>
<div className='col-12'>
<p  style={{"text-align":"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px"}}>الاجمالي</p>
</div>
<div className='col-12'>
<p style={{"text-align":"right","margin-top":"-20%","font-size":"45px",fontWeight:"bold","text-shadow":"2px 4px 4px #bfbfbf"}} className=' fontmaaray text-danger'>{detailitem.finalPrice} <span style={{fontSize:"25px"}}>جم </span></p>
</div>
</div>


    </div>
      <div className='col-4' style={{marginTop:"2%"}}>
<div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>السعر قبل الخصم</p>
</div>
<div className='col-12'>
<div className='row'>
<div className='col-4' style={{"padding":"0px",
"margin-right": "18%",
"font-size":"26px",
"margin-top":"-10%"}}>
<del className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} >
    {detailitem.salePrice }
    </del>
</div>
<div className='col-2' style={{"padding":"0px","margin-top":"-7%","font-size":"21px"}}>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>جم</p>
</div>
</div>
</div>
</div>
    </div>
<div className='col-3' style={{marginTop:"2%"}}>
<div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>وفر</p>
</div>
{console.log("asd",detailitem)}
<div className='col-12'>
<p style={{"font-size":"26px","margin-top":"-14%",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{((detailitem.itemDiscountAmount / detailitem.salePrice)*100).toFixed(2)} %</p>

</div>
</div>
</div>
    </div>
    {/* <div className='row'>
    <div className='col-5'>
    <del className='fontmaaray' style={{"float":"left"}}>
    {detailitem.itemDiscountAmount }
    </del>
    </div>
    <div className='col-5' >
    <p className='fontmaaray'>{detailitem.finalPrice}</p>
    </div>

    </div> */}
    <div className='row mt-3'>
    <div className='col-4'>
      <div className='row'>
        <div className='col-12'>
        <p style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} className='fontmaaray'>{detailitem.unitName} :</p>
        </div>
        <div className='col-12'>
        <p style={{textAlign:"right",marginTop:"-12%",fontSize:"26px","text-shadow":"2px 4px 4px #bfbfbf"}} className='fontmaaray text-success'>{detailitem.balance}</p>
        </div>

      </div>
    </div>
    <div className='col-4'>
        <div className='row'>
        <div className='col-12 ' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}> بيع جمله :</p>
        </div>
        <div className='col-12' style={{padding:"0px"}}>
        <p className='fontmaaray text-success' style={{fontSize:"26px","margin-left":"75%",marginTop:"-12%","text-shadow":"2px 4px 4px #bfbfbf"}}>{detailitem.wholeSale}</p>
        </div>
        </div>
    </div>
    <div className='col-4' style={{padding:"0px"}}>
      <div className='row'>
      <div className='col-12'>
      <p className='fontmaaray ' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>بيع ن جمله :</p>
      </div>
      <div className='col-12'>
      <p className='fontmaaray text-success' style={{fontSize:"26px","margin-left":"75%",marginTop:"-12%","text-shadow":"2px 4px 4px #bfbfbf"}}>  {detailitem.wholeSale_Half} </p>

      </div>
      </div>
    </div>
    </div>


    </div>
    </div>
    <div className='col-5' style={{background:"rgb(189 189 189)","height":"260px"}}>
      {detailitem.itemImage != null && detailitem.itemImage.length > 0 ? 
       <img style={{marginTop:"10%",marginRight:"-10%","height":"220px"}} src={detailitem.itemImage}></img> :
       <img style={{marginTop:"10%",marginRight:"-10%","height":"220px"}} src={require("../image/image (1).png")}></img>
      }
   

    </div>

  <div className='row rounded-1 bg-white mt-3' style={{"margin-right":"0%",padding: "0px"}}>
      <div className='col-lg-5 col-md-6'>
    <div className='row'>
    <div className='col-lg-2 col-md-5' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"19px","margin-top":"5px"}}>القسم:</p>
    </div>
    <div className='col-lg-8 col-md-7' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{"text-align":"right","margin-right":"7px","margin-top":"5px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{detailitem.departmentName}</p>
    </div>
    </div>
    </div>
    <div className='col-lg-5 col-md-6'>
    <div className='row'>
    <div className='col-lg-3 col-md-8' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"19px","margin-top":"3px"}}> المجموعه :</p>
    </div>
    <div className='col-lg-9 col-md-4'style={{padding:"0px"}}>
    <p className='fontmaaray' style={{"text-align":"right","margin-right":"6px","margin-top":"3px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}> {detailitem.categoryName}</p>

    </div>
    </div>
    </div>
    </div>




    </div>
    {/* <div className='row'>
    <div className='row justify-content-center'>

    </div>
    <div className='col-12'>
    <div className='row justify-content-center'>

    <p className='col-6'>

    </p>

    </div>
    <div className='row justify-content-center'>
    <div className='col-6'>
    <p> الباركود:{detailitem.itemID}</p>
    </div>
    </div>

    <div className='row'>
    <div className='col-12'>
    <div className='row'>
    <div className='col-6'>
    <p> السعر قبل الخصم:{detailitem.salePrice}</p>

    </div>
    <div className='col-6'>
    <p> السعر بعد الخصم:{detailitem.itemDiscountedPrice}</p>

    </div>
    </div>
    </div>


    <div className='col-6'>
    <p className='w-100'>الوحده:{detailitem.unitName}</p>

    </div>
    <div className='col-6'>
    <p className='w-100'>الخصم:{detailitem.itemDiscountAmount}</p>

    </div>


    </div>
    <div className='row'>

    </div>

    </div>
    </div> */}



    </div>

    ) )}

    </div>
    </div>

    :""}
    </div>
    {/* <div>

    <button
    onClick={() => {
    setStartScan(!startScan);
    }}
    >
    {startScan ? "Stop Scan" : "Start Scan"}
    </button>
    {startScan && (
    <>
    <select onChange={(e) => setSelected(e.target.value)}>
    <option value={"environment"}>Back Camera</option>
    <option value={"user"}>Front Camera</option>
    </select>
    <QrReader
    facingMode={selected}
    delay={1000}
    onError={handleError}
    onScan={() => handleSearchdetails(inputofdetails)}

    chooseDeviceId={()=>selected}
    style={{ width: "300px" }}
    />
    </>
    )}
    {loadingScan && <p>Loading</p>}
    {data !== "" && <p>{data}</p>}

    </div> */}

    </div>:""}
    {infoinitem==true ?
<div className='col-lg-8 col-md-8 col-11 card shadow Popupofdeailsinordermop' style={{backgroundColor:"rgb(239, 237, 237)",height: "500px",
    overflow: "hidden !important",marginTop:"-440px"}}>
    <div className='container-fluid ' style={{"background":"rgb(239, 237, 237)"}} >
    <div className='row card-header p-0'>
    <div className='col-12 justify-content-end'>
    <div className="row">
    <div className='col-11 p-0'>
    <h1 className='text-center fontmaaray'> <BsInfoSquareFill style={{color:"rgb(25, 135, 84)"}}></BsInfoSquareFill> استعلام عن صنف  </h1>

    </div>
    <div className='col-1 p-0'>
    <button onClick={hideinfoinitempopup}  className='fs-4 close text-danger btn p-0'><RxCross2></RxCross2> </button>

    </div>
    </div>
    </div>

    </div>

    {detailsitemsearch ?
    // justify-content-center

    <div className='row justify-content-center mt-2' style={{"max-height": "400px",overflow:"hidden scroll"}}>
      {spanner==true ?

      <div className='container-fluid ' style={{marginTop:"3%"}}>
      <div className='row justify-content-center'>
        <div className='col-6'style={{ height:"50px"}} >
        <Spinner animation="border" style={{marginRight:"40%"}} role="status">

          </Spinner>
        </div>

      </div>

      </div>

      :
        detailsitemsearch.map((detailitem) => (
    <div className='col-11 '>


    <div className='row'>
    <div className='col-12'>
    <div className='row'>
    <div className='col-12' style={{background:"rgb(189 189 189)","height":"260px"}}>
    <img style={{marginTop:"10%",marginRight:"-10%","height":"220px"}} src={require("../image/image (1).png")}></img>

    </div>
    <div className='col-12'>

    <p className='mt-2 fs-3' style={{"text-align":"center",color:"#5d5d5d","text-shadow":"2px 4px 4px #bfbfbf"}}> {detailitem.itemName} </p>
    </div>

    <div className='row'>
    {/* <div className='col-3'>
    <del style={{"font-color":"red"}}>{detailitem.salePrice}</del>
    </div> */}
    <div className='col-4'>
<div className='row'>
<div className='col-12'>
<p  style={{    textAlign: "right",
    color:"rgb(89, 89, 89)",
    textShadow:" rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"12px"}}>الاجمالي</p>
</div>
<div className='col-12'>
<p style={{textAlign: "right",
    marginTop:"-20%",
    fontSize: "16px",
    fontWeight: "bold",
    textShadow:"2px 4px 4px #bfbfbf"}} className=' fontmaaray text-danger'>{detailitem.finalPrice} <span style={{fontSize:"14px"}}>جم </span></p>
</div>
</div>


    </div>
      <div className='col-5' style={{marginTop:"2%"}}>
<div className='row'>
<div className='col-12'>
<p style={{color:"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px",fontSize:"12px"}}>السعر قبل الخصم</p>
</div>
<div className='col-12'>
<div className='row'>
<div className='col-5' style={{"padding":"0px",
"margin-right": "18%",
"font-size":"16px",
"margin-top":"-10%"}}>
<del className='fontmaaray' style={{color:"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}} >
    {detailitem.salePrice }
    </del>
</div>
<div className='col-2' style={{"padding":"0px","margin-top":"-7%","font-size":"14px"}}>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>جم</p>
</div>
</div>
</div>
</div>
    </div>
<div className='col-3' style={{marginTop:"2%"}}>
<div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}}>وفر</p>
</div>
<div className='col-12 p-0'>
<p style={{"font-size":"16px","margin-top":"-14%",color:"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}}>{(detailitem.salePrice * detailitem.finalPrice /100).toFixed(2)} %</p>

</div>
</div>
</div>
    </div>
    {/* <div className='row'>
    <div className='col-5'>
    <del className='fontmaaray' style={{"float":"left"}}>
    {detailitem.itemDiscountAmount }
    </del>
    </div>
    <div className='col-5' >
    <p className='fontmaaray'>{detailitem.finalPrice}</p>
    </div>

    </div> */}
    <div className='row mt-3'>
    <div className='col-4'>
      <div className='row'>
        <div className='col-12'>
        <p style={{textAlign:"right",color:"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px",fontSize:"12px"}} className='fontmaaray'>{detailitem.unitName} :</p>
        </div>
        <div className='col-12'>
        <p style={{textAlign:"right",marginTop:"-12%",fontSize:"16px","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}} className='fontmaaray text-success'>{detailitem.balance}</p>
        </div>

      </div>
    </div>
    <div className='col-4'>
        <div className='row'>
        <div className='col-12 ' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{textAlign:"right",color:"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px",fontSize:"12px"}}> بيع جمله :</p>
        </div>
        <div className='col-12' style={{padding:"0px"}}>
        <p className='fontmaaray text-success' style={{fontSize:"16px","margin-left":"75%",marginTop:"-12%","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}}>{detailitem.wholeSale}</p>
        </div>
        </div>
    </div>
    <div className='col-4' style={{padding:"0px"}}>
      <div className='row'>
      <div className='col-12'>
      <p className='fontmaaray ' style={{textAlign:"right",color:"rgb(89, 89, 89)","text-shadow":"rgb(191, 191, 191) 2px 4px 4px",fontSize:"12px"}}>بيع ن جمله :</p>
      </div>
      <div className='col-12'>
      <p className='fontmaaray text-success' style={{fontSize:"16px","margin-left":"75%",marginTop:"-12%","text-shadow":"rgb(191, 191, 191) 2px 4px 4px"}}>  {detailitem.wholeSale_Half} </p>

      </div>
      </div>
    </div>
    </div>


    </div>
    </div>


  <div className='row rounded-1 bg-white mt-3' style={{"margin-right":"0%"}}>
      <div className='col-lg-5 col-md-6'>
    <div className='row'>
    <div className='col-lg-2 col-md-5 col-4' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px",marginTop:"5px"}}>القسم:</p>
    </div>
    <div className='col-lg-8 col-md-7 col-8' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{"text-align":"right","margin-right":"7px","margin-top":"5px",fontSize:"16px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{detailitem.departmentName}</p>
    </div>
    </div>
    </div>
    <div className='col-lg-5 col-md-6'>
    <div className='row'>
    <div className='col-lg-3 col-md-8 col-4' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px","margin-top":"3px"}}> المجموعه :</p>
    </div>
    <div className='col-lg-9 col-md-4 col-8'style={{padding:"0px"}}>
    <p className='fontmaaray' style={{"text-align":"right","margin-right":"6px","margin-top":"3px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}> {detailitem.categoryName}</p>

    </div>
    </div>
    </div>
    </div>
    {detailitem.grpItemLst.length==0?"":   <div className='row rounded-1 bg-white mt-3' style={{"margin-right":"0%"}}>

    <div className='col-lg-5 col-md-6 col-12'>
    <div className='row'>
    <div className='col-lg-3 col-md-8 col-2' style={{padding:"0px"}}>
    <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px","margin-top":"3px"}}> شجره :</p>
    </div>

    <div className='col-lg-11 p-0 col-md-6 col-9'>
    <div style={{display:"flex"}}>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"18px"}}>
{detailitem.grpItemLst[0].name}
      </p>

<p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"18px"}}>
{detailitem.grpItemLst[1].name}
      </p>
      <p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>


<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"18px"}}>
{detailitem.grpItemLst[2].name}
      </p>
      <p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"18px"}}>
{detailitem.grpItemLst[3].name}
      </p>

    </div>


        </div>
    </div>
    </div>
    </div>}



    </div>
    {/* <div className='row'>
    <div className='row justify-content-center'>

    </div>
    <div className='col-12'>
    <div className='row justify-content-center'>

    <p className='col-6'>

    </p>

    </div>
    <div className='row justify-content-center'>
    <div className='col-6'>
    <p> الباركود:{detailitem.itemID}</p>
    </div>
    </div>

    <div className='row'>
    <div className='col-12'>
    <div className='row'>
    <div className='col-6'>
    <p> السعر قبل الخصم:{detailitem.salePrice}</p>

    </div>
    <div className='col-6'>
    <p> السعر بعد الخصم:{detailitem.itemDiscountedPrice}</p>

    </div>
    </div>
    </div>


    <div className='col-6'>
    <p className='w-100'>الوحده:{detailitem.unitName}</p>

    </div>
    <div className='col-6'>
    <p className='w-100'>الخصم:{detailitem.itemDiscountAmount}</p>

    </div>


    </div>
    <div className='row'>

    </div>

    </div>
    </div> */}



    </div>

    ) )}

    </div>


    :""}
    </div>
    {/* <div>

    <button
    onClick={() => {
    setStartScan(!startScan);
    }}
    >
    {startScan ? "Stop Scan" : "Start Scan"}
    </button>
    {startScan && (
    <>
    <select onChange={(e) => setSelected(e.target.value)}>
    <option value={"environment"}>Back Camera</option>
    <option value={"user"}>Front Camera</option>
    </select>
    <QrReader
    facingMode={selected}
    delay={1000}
    onError={handleError}
    onScan={() => handleSearchdetails(inputofdetails)}

    chooseDeviceId={()=>selected}
    style={{ width: "300px" }}
    />
    </>
    )}
    {loadingScan && <p>Loading</p>}
    {data !== "" && <p>{data}</p>}

    </div> */}

    </div>:""}



              {info ==true ?
                 <div className='col-lg-8 col-md-10 col-11 card shadow Popupinfoitem' style={{backgroundColor:"rgb(239, 237, 237)"}}>
        <div className='row card-title' style={{"background":"rgb(239, 237, 237)"}} >
        <div className='col-12'>
        <div className='row justify-content-end card-header'>
          <div className='col-lg-1 col-md-1 col-2 imgofinfoitem' >
              <img className='w-100 h-100'  src={require("../image/Search-on-item.png")} />
          </div>
          <div className='col-lg-5 col-md-9 col-7 textofinfoitem' >
          <h1 className=' fontmaaray headerofPopupinfoitem' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}> استعلام عن صنف  </h1>

          </div>
        <div className='col-lg-2 col-md-1 col-2'>
        <button  style={{padding:"0px","margin-right":"75%"}} data-bs-toggle="tooltip" data-bs-placement="left" title="خروج" onClick={hideinfopopup}  className='fs-4 close text-danger btn'><RxCross2 data-bs-toggle="tooltip" data-bs-placement="left" title="خروج"></RxCross2> </button>

        </div>
        </div>

        </div>
        <div className='col-12'>
        <div className='row justify-content-end mt-2'>
        <div className='col-12'>
        </div>
        <div className='col-lg-12 col-md-12 col-12'>
        <div className='row'>
        <div className='col-lg-8 col-md-7 col-7' style={{"margin-right":"6px"}}>
<div style={{display:"flex",backgroundColor:"white",borderRadius:"5px","height":"37px"}}>
<div className='col-1'>
<RxCross2 onClick={clearinfotext} className='text-danger ' style={{marginTop:"6px"}}></RxCross2>
</div>
<div className='col-11'>
<input id='infotextsearch' style={{border:"none"}} className='w-100  fontsearchofPopupinfoitem' placeholder=' البحث بباركود الصنف'  onKeyDown={handlePress}  onChange={handleChangeinputofdetails} type={'text'}></input>

</div>
</div>

                </div>

                <div className='col-lg-2 col-md-2 col-3 btnshowpopupofinfo' data-toggle="tooltip" data-placement="bottom" title="Tooltip on top" >
                <button  className='btn btn-success fontmaaray w-100 btnshpwofPopupinfoitem shadow ' data-toggle="tooltip" data-placement="right" title=" خروج "  onClick={() => handleSearchdetails(inputofdetails)} >عرض</button>

          {/* <div className='row '>

        <div className='col-lg-7 col-md-8'>

        </div>
            </div> */}
                </div>
                <div className='col-1 divofscanbtnofpopupinfo' >
<button className='btn scanbtnpopupinfo' onClick={showscanpopupcust} style={{backgroundColor:"#f56902",color:"white"}}>
  <BiBarcodeReader className='scanbtniconpopupinfo' ></BiBarcodeReader>
</button>
                </div>
        </div>
        </div>



          </div>

        </div>

        {detailsitemsearch   ?
        // justify-content-center
        <div className='container-fluid'>
        <div className='row justify-content-center infodesctop mt-2' style={{"max-height": "400px"}}>
          {spanner==true ?

          <div className='container-fluid ' style={{marginTop:"3%"}}>
          <div className='row justify-content-center'>
            <div className='col-6'style={{ height:"50px"}} >
            <Spinner animation="border" style={{marginRight:"40%"}} role="status">

              </Spinner>
            </div>

          </div>

          </div>

          :
            detailsitemsearch.map((detailitem) => (
        <div className='col-11 '>


        <div className='row'>
        <div className='col-lg-7 col-md-7 card'>
        <div className='row'>
        <div className='col-12'>
        <p className='mt-2 fs-3' style={{"text-align":"right",color:"#5d5d5d","text-shadow":"2px 4px 4px #bfbfbf"}}> {detailitem.itemName} </p>
        </div>

        <div className='row'>
        {/* <div className='col-3'>
        <del style={{"font-color":"red"}}>{detailitem.salePrice}</del>
        </div> */}
        <div className='col-4'>
   <div className='row'>
<div className='col-12'>
<p  style={{"text-align":"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"18px"}}>الاجمالي</p>
</div>
<div className='col-12'>
<p style={{}} className=' fontmaaray text-danger priceinpopupinfo'>{detailitem.finalPrice} <span className='spanofpoundpopupinfo' style={{}}>جم </span></p>
</div>
   </div>


        </div>
          <div className='col-4' style={{marginTop:"2%"}}>
    <div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>السعر قبل الخصم</p>
</div>
<div className='col-12'>
<div className='row'>
<div className='col-lg-5 col-md-6 col-7' style={{"padding":"0px",
    "margin-right": "18%",
   "font-size":"26px",
    "margin-top":"-10%"}}>
<del className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} >
        {detailitem.salePrice }
        </del>
</div>
<div className='col-lg-2 col-md-3 col-4' style={{"padding":"0px","margin-top":"-7%","font-size":"21px"}}>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>جم</p>
</div>
</div>
</div>
    </div>
        </div>
<div className='col-3' style={{marginTop:"2%"}}>
<div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>وفر</p>
</div>
<div className='col-12'>
<p style={{"font-size":"26px","margin-top":"-14%",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{(100-(detailitem.finalPrice /detailitem.salePrice  * 100)).toFixed(2)} %</p>

</div>
</div>
</div>
        </div>
        {/* <div className='row'>
        <div className='col-5'>
        <del className='fontmaaray' style={{"float":"left"}}>
        {detailitem.itemDiscountAmount }
        </del>
        </div>
        <div className='col-5' >
        <p className='fontmaaray'>{detailitem.finalPrice}</p>
        </div>

        </div> */}
        <div className='row mt-3'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-12'>
            <p style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} className='fontmaaray'>{detailitem.unitName} :</p>
            </div>
            <div className='col-12'>
            <p style={{textAlign:"right",marginTop:"-12%",fontSize:"26px","text-shadow":"2px 4px 4px #bfbfbf"}} className='fontmaaray text-success'>{detailitem.balance}</p>
            </div>

          </div>
        </div>
        <div className='col-4'>
            <div className='row'>
            <div className='col-12 ' style={{padding:"0px"}}>
            <p className='fontmaaray' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}> بيع جمله :</p>
            </div>
            <div className='col-12' style={{padding:"0px"}}>
            <p className='fontmaaray text-success' style={{fontSize:"26px","margin-left":"75%",marginTop:"-12%","text-shadow":"2px 4px 4px #bfbfbf"}}>{detailitem.wholeSale}</p>
            </div>
            </div>
        </div>
        <div className='col-4' style={{padding:"0px"}}>
          <div className='row'>
          <div className='col-12'>
          <p className='fontmaaray ' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>بيع ن جمله :</p>
          </div>
          <div className='col-12'>
          <p className='fontmaaray text-success' style={{fontSize:"26px","margin-left":"75%",marginTop:"-12%","text-shadow":"2px 4px 4px #bfbfbf"}}>  {detailitem.wholeSale_Half} </p>

          </div>
          </div>
        </div>
        </div>


        </div>
        </div>
        <div className='col-lg-3 col-md-4 imgofinfopopup' >
        <img className='imgofdivofpopupinfoitem' style={{}} src={require("../image/image (1).png")}></img>

        </div>

      <div className='row rounded-1 bg-white mt-2' style={{"margin-right":"0%"}}>
          <div className='col-lg-5 col-md-6'>
        <div className='row'>
        <div className='col-lg-2 col-md-5' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf","margin-top":"5px",fontSize:"19px"}}>القسم:</p>
        </div>
        <div className='col-lg-8 col-md-7' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{"text-align":"right","margin-right":"7px","margin-top":"5px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{detailitem.departmentName}</p>
        </div>
        </div>
        </div>
        <div className='col-lg-5 col-md-6'>
        <div className='row'>
        <div className='col-lg-3 col-md-8' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf","margin-top":"5px",fontSize:"19px"}}> المجموعه :</p>
        </div>
        <div className='col-lg-9 col-md-4'style={{padding:"0px"}}>
        <p className='fontmaaray' style={{"text-align":"right","margin-right":"6px","margin-top":"5px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}> {detailitem.categoryName}</p>

        </div>
        </div>
        </div>


        </div>
 <div className='row rounded-1 bg-white mt-2' style={{"margin-right":"0%"}}>
          <div className='col-lg-1 col-md-6 p-0'>
      <p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"19px"}}>شجره :</p>
        </div>

        <div className='col-lg-11 p-0 col-md-6'>
    <div style={{display:"flex"}}>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"19px"}}>
{detailitem.grpItemLst[0].name}
      </p>

<p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"19px"}}>
{detailitem.grpItemLst[1].name}
      </p>
      <p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>


<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"19px"}}>
{detailitem.grpItemLst[2].name}
      </p>
      <p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"19px"}}>
{detailitem.grpItemLst[3].name}
      </p>

    </div>


        </div>


        </div>



        </div>
        {/* <div className='row'>
        <div className='row justify-content-center'>

        </div>
        <div className='col-12'>
        <div className='row justify-content-center'>

        <p className='col-6'>

        </p>

        </div>
        <div className='row justify-content-center'>
        <div className='col-6'>
        <p> الباركود:{detailitem.itemID}</p>
        </div>
        </div>

        <div className='row'>
        <div className='col-12'>
        <div className='row'>
        <div className='col-6'>
        <p> السعر قبل الخصم:{detailitem.salePrice}</p>

        </div>
        <div className='col-6'>
        <p> السعر بعد الخصم:{detailitem.itemDiscountedPrice}</p>

        </div>
        </div>
        </div>


        <div className='col-6'>
        <p className='w-100'>الوحده:{detailitem.unitName}</p>

        </div>
        <div className='col-6'>
        <p className='w-100'>الخصم:{detailitem.itemDiscountAmount}</p>

        </div>


        </div>
        <div className='row'>

        </div>

        </div>
        </div> */}



        </div>

        ) )}

        </div>
        <div className='row justify-content-center mt-2 infomop' style={{"max-height": "400px",overflowX:"hidden",overflowY:"scroll"}}>
          {spanner==true ?

          <div className='container-fluid ' style={{marginTop:"3%"}}>
          <div className='row justify-content-center'>
            <div className='col-6'style={{ height:"50px"}} >
            <Spinner animation="border" style={{marginRight:"40%"}} role="status">

              </Spinner>
            </div>

          </div>

          </div>

          :
            detailsitemsearch.map((detailitem) => (
        <div className='col-11 '>


        <div className='row'>
        <div className='col-lg-7 col-md-7 col-12 card'>
        <div className='row'>
        <div className='col-12'>
<div className='row' style={{height:"150px"}}>
<img className='imgofdivofpopupinfoitem' style={{height: "100%",width:"100%"}} src={require("../image/image (1).png")}></img>

</div>
<div className='row'>
<p className='mt-2 fs-3' style={{"text-align":"right",color:"#5d5d5d","text-shadow":"2px 4px 4px #bfbfbf"}}> {detailitem.itemName} </p>

</div>
        </div>

        <div className='row'>
        {/* <div className='col-3'>
        <del style={{"font-color":"red"}}>{detailitem.salePrice}</del>
        </div> */}
        <div className='col-4'>
   <div className='row'>
<div className='col-12'>
<p  style={{"text-align":"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}}>الاجمالي</p>
</div>
<div className='col-12'>
<p style={{}} className=' fontmaaray text-danger priceinpopupinfo'>{detailitem.finalPrice} <span className='spanofpoundpopupinfo' style={{}}>جم </span></p>
</div>
   </div>


        </div>
          <div className='col-5' style={{marginTop:"2%"}}>
    <div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}}>السعر قبل الخصم</p>
</div>
<div className='col-12'>
<div className='row'>
<div className='col-5' style={{"padding":"0px",
    "margin-right": "18%",
   "font-size":"16px",
    "margin-top":"-10%"}}>
<del className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}} >
        {detailitem.salePrice }
        </del>
</div>
<div className='col-2' style={{"padding":"0px","margin-top":"-7%","font-size":"14px"}}>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>جم</p>
</div>
</div>
</div>
    </div>
        </div>
<div className='col-3' style={{marginTop:"2%"}}>
<div className='row'>
<div className='col-12'>
<p style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}}>وفر</p>
</div>
<div className='col-12'>
<p style={{"font-size":"16px","margin-top":"-14%",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf"}}>{(100-(detailitem.finalPrice /detailitem.salePrice  * 100)).toFixed(2)} %</p>

</div>
</div>
</div>
        </div>
        {/* <div className='row'>
        <div className='col-5'>
        <del className='fontmaaray' style={{"float":"left"}}>
        {detailitem.itemDiscountAmount }
        </del>
        </div>
        <div className='col-5' >
        <p className='fontmaaray'>{detailitem.finalPrice}</p>
        </div>

        </div> */}
        <div className='row mt-3'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-12'>
            <p style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}} className='fontmaaray'>{detailitem.unitName} :</p>
            </div>
            <div className='col-12'>
            <p style={{textAlign:"right",marginTop:"-12%",fontSize:"26px","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}} className='fontmaaray text-success'>{detailitem.balance}</p>
            </div>

          </div>
        </div>
        <div className='col-4'>
            <div className='row'>
            <div className='col-12 ' style={{padding:"0px"}}>
            <p className='fontmaaray' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}}> بيع جمله :</p>
            </div>
            <div className='col-12' style={{padding:"0px"}}>
            <p className='fontmaaray text-success' style={{fontSize:"26px","margin-left":"75%",marginTop:"-12%","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}>{detailitem.wholeSale}</p>
            </div>
            </div>
        </div>
        <div className='col-4' style={{padding:"0px"}}>
          <div className='row'>
          <div className='col-12'>
          <p className='fontmaaray ' style={{textAlign:"right",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"12px"}}>بيع ن جمله :</p>
          </div>
          <div className='col-12'>
          <p className='fontmaaray text-success' style={{fontSize:"26px","margin-left":"75%",marginTop:"-12%","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}>  {detailitem.wholeSale_Half} </p>

          </div>
          </div>
        </div>
        </div>


        </div>
        </div>


      <div className='row rounded-1 bg-white mt-2' style={{"margin-right":"0%"}}>
          <div className='col-lg-5 col-md-6 col-12'>
        <div className='row'>
        <div className='col-lg-2 col-md-5 col-3' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}>القسم:</p>
        </div>
        <div className='col-lg-8 col-md-7 col-9' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{"text-align":"right","margin-right":"7px","margin-top":"5px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}>{detailitem.departmentName}</p>
        </div>
        </div>
        </div>
        <div className='col-lg-5 col-md-6 col-12'>
        <div className='row'>
        <div className='col-lg-3 col-md-8 col-4' style={{padding:"0px"}}>
        <p className='fontmaaray' style={{color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}> المجموعه :</p>
        </div>
        <div className='col-lg-9 col-md-4 col-8'style={{padding:"0px"}}>
        <p className='fontmaaray' style={{"text-align":"right","margin-right":"6px","margin-top":"3px",color:"#595959","text-shadow":"2px 4px 4px #bfbfbf",fontSize:"16px"}}> {detailitem.categoryName}</p>

        </div>
        </div>
        </div>


        </div>
 <div className='row rounded-1 bg-white mt-2' style={{"margin-right":"0%"}}>
          <div className='col-lg-1 col-md-6 p-0'>
      <p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"14px"}}>شجره :</p>
        </div>

        <div className='col-lg-11 p-0 col-md-6'>
    <div style={{display:"flex"}}>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"14px"}}>
{detailitem.grpItemLst[0].name}
      </p>

<p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"14px"}}> &nbsp; {">"} &nbsp;</p>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"14px"}}>
{detailitem.grpItemLst[1].name}
      </p>
      <p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"14px"}}> &nbsp; {">"} &nbsp;</p>


<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"14px"}}>
{detailitem.grpItemLst[2].name}
      </p>
      <p style={{ color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",fontSize:"19px"}}> &nbsp; {">"} &nbsp;</p>

<p style={{textAlign:"right",color: "rgb(89, 89, 89)",
    textShadow:"rgb(191, 191, 191) 2px 4px 4px",
    fontSize:"14px"}}>
{detailitem.grpItemLst[3].name}
      </p>

    </div>


        </div>


        </div>



        </div>
        {/* <div className='row'>
        <div className='row justify-content-center'>

        </div>
        <div className='col-12'>
        <div className='row justify-content-center'>

        <p className='col-6'>

        </p>

        </div>
        <div className='row justify-content-center'>
        <div className='col-6'>
        <p> الباركود:{detailitem.itemID}</p>
        </div>
        </div>

        <div className='row'>
        <div className='col-12'>
        <div className='row'>
        <div className='col-6'>
        <p> السعر قبل الخصم:{detailitem.salePrice}</p>

        </div>
        <div className='col-6'>
        <p> السعر بعد الخصم:{detailitem.itemDiscountedPrice}</p>

        </div>
        </div>
        </div>


        <div className='col-6'>
        <p className='w-100'>الوحده:{detailitem.unitName}</p>

        </div>
        <div className='col-6'>
        <p className='w-100'>الخصم:{detailitem.itemDiscountAmount}</p>

        </div>


        </div>
        <div className='row'>

        </div>

        </div>
        </div> */}



        </div>

        ) )}

        </div>
        </div>

        :""}
        </div>
        {/* <div>

            <button
        onClick={() => {
          setStartScan(!startScan);
        }}
        >
        {startScan ? "Stop Scan" : "Start Scan"}
        </button>
        {startScan && (
        <>
          <select onChange={(e) => setSelected(e.target.value)}>
            <option value={"environment"}>Back Camera</option>
            <option value={"user"}>Front Camera</option>
          </select>
          <QrReader
            facingMode={selected}
            delay={1000}
            onError={handleError}
            onScan={() => handleSearchdetails(inputofdetails)}

            chooseDeviceId={()=>selected}
            style={{ width: "300px" }}
          />
        </>
        )}
        {loadingScan && <p>Loading</p>}
        {data !== "" && <p>{data}</p>}

        </div> */}

        </div>:""}


    {showorderprint==true ?
     <div className='row  justify-content-center printsuborder' id="printsuborder" style={{height: "460px" }}>
     <div className='col-12'>
      <div className='row justify-content-center'>
      <div className='col-3 mt-2' style={{padding:"0px"}}>
<h1 className='fontmaaray'>Tijary</h1>
          </div>

      </div>
      {/* <div className='row justify-content-center mt-3'>
        <div className='col-3 '>
              <h3>الفاتوره</h3>
        </div>
      </div> */}
      <div className="row justify-content-center" >
<div className="col-4">
<Barcode value={Numoforder}></Barcode>
</div>
      </div>

     </div>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
                <div className='col-5' style={{padding:"0px"}}>
                <p className='fontmaaray'>التاريخ :</p>
                </div>
                <div className='col-7' style={{padding:"0px"}}>
                  <p className='fontmaaray' style={{textAlign:"right" ,"font-size":" 15px"}}>{toDay}</p>
                   </div>
            </div>
             </div>
             <div className='col-6'>
                <div className='row'>
                  <div className='col-6' style={{padding:"0px"}}>
                      <p className='fontmaaray'>التوقيت :</p>
                  </div>
                  <div className='col-6' style={{padding:"0px"}}>
                    <p className='fontmaaray' style={{textAlign:"right","white-space": "nowrap"}}>{hoursAndMinutes}</p>
                  </div>
                </div>
             </div>
        </div>
<div className='col-12'>
<div className='row justify-content-center'>
<div className='col-2' style={{padding:"0px"}}>
<p className='fontmaaray'>   عميل :</p>
</div>
<div className='col-8'>
<p className='fontmaaray' style={{textAlign:"right"}}>{customer.firstName}</p>
</div>
</div>

</div>
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-6" style={{padding:"0px"}}>
                <p className='fontmaaray'>ويتر :</p>
            </div>
            <div className="col-6" style={{padding:"0px"}}>
              <p className='fontmaaray' style={{textAlign:"right"}}>{User.userName}</p>
            </div>


          </div>
        </div>
        <div className="col-6">
          <div className="row" >
          <div className="col-8" style={{padding:"0px"}}>
                <p className='fontmaaray'> رقم الطاوله :</p>
            </div>
            <div className="col-4">
              <p className='fontmaaray' style={{textAlign:"right"}}>{tableNo}</p>
            </div>
          </div>
        </div>
      </div>

     <div className='row justify-content-center mt-3 ' style={{borderBottom:"1px solid black",borderTop:"1px solid black" }}>
     <div className='col-2 ' style={{padding:"0px"}}>
          <p className='fontmaaray'> كميه</p>
        </div>

        <div className='col-4' style={{padding:"0px"}}>
          <p className='fontmaaray'> صنف</p>
        </div>

        <div className='col-3' style={{padding:"0px"}}>
          <p className='fontmaaray'>سعر </p>
        </div>
        <div className='col-3' style={{padding:"0px"}}>
          <p className='fontmaaray'>اجمالي</p>
        </div>

      </div>
     {orderlist.map(order => (

      <div className='row justify-content-center'  >
         <div className='col-2 ' style={{padding:"0px"}}>
          <p className='fontmaaray'>{parseFloat(order.quantity)}</p>
        </div>
        <div className='col-4 ' style={{padding:"0px"}}>
          <p className='fontmaaray'>{order.itemName}</p>
        </div>

        <div className='col-3' style={{padding:"0px"}}>
          <p className='fontmaaray'>{order.finalPrice}</p>
        </div>
        <div className='col-3' style={{padding:"0px"}}>
          <p className='fontmaaray'>{(order.finalPrice * order.quantity).toFixed(2)}</p>
        </div>

      </div>
     ))}


  <div className='row  mt-3'>

      <div className='col-6' >
        <div className='row'>
          <div className='col-6' style={{padding:"0px"}}>
            <p className='fontmaaray'> خصم :</p>
          </div>
          <div className='col-6'  style={{padding:"0px"}}>
            <p className='fontmaaray' style={{"text-align": "right"}}>{Number(total.totaldiscount).toFixed(2)}</p>
          </div>
        </div>
      </div>

      <div className='col-6' >
        <div className='row'>
          <div className='col-7'  style={{padding:"0px","white-space":"nowrap"}}>
            <p className='fontmaaray'> قبل الخصم :</p>
          </div>
          <div className='col-5'>
            <p className='fontmaaray' style={{"text-align": "right"}}>{Number(total.totalpricedel).toFixed(2)}</p>
          </div>
        </div>
      </div>
  </div>

  <div className='row justify-content-center mt-3'>
      <div className='col-8' style={{border: "2px solid"}} >
        <div className='row'>
          <div className='col-6' style={{    borderLeft: "2px solid"}}>
            <p className='fontmaaray'> اجمالي :</p>
          </div>
          <div className='col-6'>
            <p className='fontmaaray' style={{"text-align": "right"}}>{Number(total.Nettotal).toFixed(2)}</p>
          </div>
        </div>
      </div>
  </div>
  <div className='row justify-content-center mt-3'>
  <div className='col-8' style={{border: "2px solid"}} >
        <div className='row'>
          <div className='col-6' style={{padding:"0px",borderLeft: "2px solid","white-space":"nowrap"}}>
          <p className='fontmaaray'>  اجمالي كميه :</p>
          </div>
          <div className='col-6'>
          <p className='fontmaaray' style={{"text-align": "right"}}>{Number(total.totalqut).toFixed(2)}</p>
          </div>
        </div>
      </div>

  </div>
  <div className='row'>
    <div className="col-2">
<p>الملاحظات والتعليقات</p>
    </div>
    <div className="col-10">
    <textarea value={message} placeholder="ملاحظات و تعليقات" className="w-100 mt-2 fontmaaray"  style={{"outline": "none","height": "80px"}}></textarea>

    </div>
    </div>
        </div> :""}




{popuptijary==true?
<div className='row'>
<div className='col-lg-4 col-md-6 col-11 card popuptijary' >
<div className='row card-header justify-content-center' >
    <div className='col-lg-11 col-md-11 col-10'>
   <div className='row justify-content-center'>
<div className='col-1 p-0'>
<img style={{width:"45px","margin-left": "60%",marginTop:"3px"}} src={require("../image/tijary.png")}>
   </img>
</div>
<div className='col-lg-4 col-md-4 col-6'>
<p style={{marginTop:"10px",fontSize:"18px",color:"rgb(25, 135, 84)",fontWeight:"bold",textShadow:"rgb(191, 191, 191) 2px 4px 4px"}}>عن tijary</p>
</div>
   </div>
 {/* <div className='row justify-content-center'>

 </div> */}

    </div>

  <div className='col-1' style={{textAlign:"left"}}>
  <button onClick={closepopuptijary}  className='fs-4 close text-danger btn' ><RxCross2></RxCross2> </button>

  </div>

  </div>
  <div className='row justify-content-center mt-3'>
    <div className='col-10'>
    <p className='text-center fontmaaray'>نظام متكامل للأداره الشاملة لمحلات البيع و متطلبات عملائهم من خلال مجموعه من التطبيقات وتكنولوجيا اداره المعلومات</p>

    </div>

  </div>
  <div className='row justify-content-center'>
    <div className='col-10'>
    <p className='text-center fontmaaray'>يقوم النظام باداره محلات البيع بما يتطلب من زياده الأرباح والتوفير وتقليص الخسائر </p>

    </div>

  </div>
  <div className='row justify-content-center'>
    <div className='col-10'>
    <p className='text-center fontmaaray'>يتركز علي ارسال التفوق الاداري و انجاح العمليه التجاريه سواء اونلاين او اوف لاين للانترنت المحل</p>

    </div>

  </div>
  <div className='row  justify-content-center ' style={{"margin-top":"6%"}}>
<a className='col-lg-6 col-md-6 col-7 '  href="mailto:Support@tijary.store"  target="_blank">
<button className='btn text-white fontmaaray sendyourquestion w-100' style={{background:"rgb(245, 105, 2)"}} >
<div className='row' >
<div className='col-2 p-0'>
<AiOutlineMail></AiOutlineMail>
</div>
<div className='col-10'>
<p> ارسل استفسارك
</p>
</div>
</div>
</button>
</a>
<a className='col-2 fs-2' href="https://wa.me/201118118343"  target="_blank">
<AiOutlineWhatsApp style={{color:"rgb(25, 135, 84)","font-size": "31px", "margin-top":"-25%"}}></AiOutlineWhatsApp>
</a>
  </div>
  <div className='row' style={{marginTop:"6%"}}>
  <div className='col-12'>
  <a href='http://bedab.com/' target='_blank' className='fontmaaray' style={{fontSize:"11px","margin-top":"16px",textDecoration:"none",color:"gray"}}> develop by </a>

  </div>
  <div className='col-12'>
  <a href='http://bedab.com/' target='_blank' className='fontmaaray' style={{fontSize:"11px","margin-top":"16px",textDecoration:"none",color:"gray"}}>    bedab software
 </a>

  </div>
  </div>
</div>
</div>
:""}
{orderoftable ?
 <div className='container-fluid '>


  {popupdiv==false?

  <div className='col-lg-8 col-md-8 col-12 card popupoforderintable' >
  <div className='container-fluid'>
<div className='row card-header p-0'>
<div className='col-2'></div>

  <div className='col-8' >
    <div className='row justify-content-center'>
<div className='col-lg-3 col-md-5 col-5 fontsizeofmenuoforder p-0  numoftableorder' style={{color:"#198754"}}>
طاوله رقم
</div>
<div className='col-1  p-0 numoftableorder' style={{color:"#198754"}}>
{numberid}
</div>
<div className='col-lg-4 col-md-4 col-5 p-0 fontsizeofmenuoforder numoftableorder' style={{color:"#595959"}}>

(عدد الاوردر:
{orderoftable.length})

</div>
    </div>
  </div>
<div className='col-2'>
<button onClick={closepopoupofordertable}  className='col-1 fs-4 close text-danger btn'><RxCross2></RxCross2> </button>

</div>
</div>

  </div>
  <div className='container-fluid' style={{marginTop:"9px",marginBottom:"5px"}}>
  <div className='row' style={{    backgroundColor: "rgb(213, 213, 213)",
    border: "1px solid rgb(191, 191, 191)",
    borderRadius:" 5px",
    color: "rgb(25, 135, 84)",
    fontWeight: "bold",height:"37px"}}>
 <div className='col-2 p-0'>
 <p className='fontmaaray fontsizeofmenuoforder' style={{marginTop:"5px"}}>رقم الاوردر</p>
 </div>
 <div className='col-3 p-0'>
 <p className='fontmaaray fontsizeofmenuoforder'  style={{marginTop:"5px"}}>  اسم الويتر  </p>
 </div>
 <div className='col-3 p-0' >
 <p className='fontmaaray fontsizeofmenuoforder' style={{marginTop:"5px"}}>  عدد الاصناف  </p>

 </div>
 <div className='col-2 p-0'>
 <p className='fontmaaray fontsizeofmenuoforder' style={{marginTop:"5px"}}>  الكميه </p>

 </div>
 <div className='col-2 p-0'>
 <p className='fontmaaray fontsizeofmenuoforder' style={{marginTop:"5px"}}> اجمالي  </p>

 </div>
   </div>
  </div>
 {orderoftable.map(orderoftable => (

      <div className='container-fluid '  onClick={() => {fetchorderitemdetail(orderoftable.id);tablesclick(orderoftable.tableNo)}}>
      {orderoftable.id % 2 ==0 ?
      <div className='row ' style={{cursor:"pointer",background:"rgb(245, 105, 2)","border-bottom": "1px solid white"}}>
      <div className='col-2 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.id} </p>
      </div>
      <div className='col-3 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'> {orderoftable.userName} </p>

      </div>
      <div className='col-3 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.orderItemsCountPos} </p>
      </div>
      <div className='col-2 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.quantity} </p>
      </div>
      <div className='col-2 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.dueAmount} </p>

      </div>

      </div> :
        <div className='row ' style={{cursor:"pointer",background:"rgb(213, 213, 213)"}}>
      <div className='col-2 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.id} </p>
      </div>
      <div className='col-3 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.userName} </p>

      </div>
      <div className='col-3 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.orderItemsCountPos} </p>
      </div>
      <div className='col-2 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.quantity} </p>
      </div>
      <div className='col-2 p-0'>
      <p className='fontmaaray fontsizeofmenuoforder'>{orderoftable.dueAmount} </p>

      </div>

      </div>}
        </div>


 ))}

 <div className='row justify-content-center mt-3'>
   {console.log}
 <div className='col-6'  onClick={() => tablesclick(orderintable)} >
   <button className='btn btn-success w-100 fontmaaray'>اضافه اوردر</button>
 </div>
 </div>
 </div>
  :""}

</div>
:""}
{exit == true?
<div className='container-fluid'style={{"margin-top": "-34%"}} >
<div className='col-lg-4 col-md-5 popupcloseprogram card' style={{zIndex:"10000000"}}>
  <div className='row'>
<div className='col-12'>
سيتم الخروج من البرنامج هل انت متاكد ؟
</div>
<div className='row justify-content-center' style={{marginTop:"8%"}} >
<div className='col-4'>
<button className='btn btn-success w-100' onClick={logout}> تاكيد</button>
</div>
<div className='col-4'>
<button className='btn btn-danger w-100' onClick={nologout}> لا</button>
</div>
</div>
  </div>
  </div>

</div>

:""}
{exitopenbal == true?
<div className='container-fluid'style={{"margin-top": "-34%"}} >
<div className='col-lg-4 col-md-5 col-12 closeopeningbalancepopup card' >
  <div className='row'>
<div className='col-12'>
سيتم غلق الورديه  هل انت متاكد ؟
</div>
<div className='row justify-content-center' style={{marginTop:"8%"}} >
<div className='col-4'>
<button className='btn btn-success w-100' onClick={() => fetchcloseopening(User.id)}> تاكيد</button>
</div>
<div className='col-4'>
<button className='btn btn-danger w-100' onClick={cancelclosopenbal}> لا</button>
</div>
</div>
  </div>
  </div>

</div>

:""}


        </div>
    )
}
export default Product;